//--------LIBRARIES--------
import React, { Component } from "react";
import { connect } from "react-redux";
import { Switch, Route, Link } from "react-router-dom";

import {
  DownOutlined,
  FolderOpenOutlined,
  GlobalOutlined,
  LogoutOutlined,
  MailOutlined,
  QuestionCircleFilled,
  QuestionCircleOutlined,
  SearchOutlined,
  SettingOutlined,
  SmileOutlined,
  PlaySquareOutlined,
  UserOutlined,
  UsergroupAddOutlined,
} from "@ant-design/icons";
// import LoadingOutlined from "@ant-design/icons/LoadingOutlined";

import {
  Layout,
  Menu,
  Avatar,
  Tooltip,
  Dropdown,
  Button,
  Input,
  notification,
  InputNumber,
  Badge,
  Drawer,
  Radio,
  Checkbox,
  Alert,
  Spin,
  Upload,
} from "antd";
import { debounce, each } from "lodash";
// import { FixedSizeList } from "react-window";
import deepFreeze from "deep-freeze";
import Axios from "axios";
import introJs from "intro.js";

//--------IP-SUITE GENERAL FUNCTIONS--------
import {
  changeStateValue_L1,
  changeStateValue_L2,
} from "../../functions/commonFunctions/generalFunctions";

//--------IP-SUITE CONSTANTS--------
import {
  SUCCESS,
  LOADING,
} from "../../constants/commonConstants/generalConstants";

//--------IP-SUITE ACTIONS--------
import {
  logOut,
  getUserSubscriptions,
  getProfile,
  TPAction,
  getAlertMessage,
  SetFileAction,
} from "../../redux/actions/commonActions/actions";

//--------IP-SUITE COMPONENTS--------
import NoMatch from "../commonComponents/NoMatch";

//--------Routes--------
import SearchTips from "./Routes/SearchTips";
import TrademarksSearch, {
  resetTrademarksSearchState,
} from "./Routes/TrademarksSearch";
import ProprietorsSearch, {
  resetProprietorsSearchState,
} from "./Routes/ProprietorsSearch";
import TrademarkProfile from "./Routes/TrademarkProfile";
// import Profile from "./Routes/Profile";
import ReportManagement from "./Routes/ReportManagement";
import ReportDownload from "./Routes/ReportDownload";
import ProprietorProfile from "./Routes/ProprietorProfile";
import WhatsNew from "../commonComponents/Drawers/Whats-New";
import EmailTemplate from "../commonComponents/EmailTemplates";
import ProfileDashboard from "../commonComponents/ProfileDetails/ProfileDashboard";

// import ImageSearchDashboard from "../Watch/Pages/ImageSearchDashboard/ImageSearch";

//--------IP-SUITE PROFILE-COMPONENTS--------
import UploadProfileLogo from "../commonComponents/ProfileDetails/UploadProfileLogo";

//--------IP-SUITE COMMON COMPONENTS--------
// import SurveyModal from "../commonComponents/Modals/SurveyModal";
// import ImageRecognition from "../commonComponents/Modals/ImageRecognition";

//--------IP-SUITE STYLE CONSTANTS--------
import {
  // ipSuiteMenuStyle,
  searchMenuStyle,
} from "../../constants/commonConstants/styleConstants";

//--------SEARCH SUB-COMPONENTS--------
import TrademarksAlgosInfo from "./subComponents/TrademarksAlgosInfo";

//--------SEARCH ACTIONS--------
import {
  getUserTrademarksInSearch,
  getSearchResults,
  handleSearchInfoDispatch,
  postUserTrademark,
  getTrademarkProfileData,
  getImageSearchResults,
  injectSampleData,
} from "../../redux/actions/searchActions/commonActions";

//--------SEARCH ACTION TYPES--------
import {
  SET_FILE,
  SET_SEARCH_PATH,
  SRCH_GET_REPORT_AVAILABILITY,
  TS_SELECT_VIEW,
  TS_SET_DEFAULT_STATE,
} from "../../redux/actions/searchActions/types";

//--------SEARCH CONSTANTS--------
import {
  allClasses,
  refreshPeriod,
} from "../../constants/searchConstants/generalConstants";

//--------SEARCH STYLESHEETS--------
import "../../stylesheets/searchStyleSheets/search.css";

//--------PROPRIETOR SEARCH ACTIONS--------
import { getProprietorsSearch } from "../../redux/actions/searchActions/proprietorsSearchAction";
import { searchPageTours } from "../../constants/searchConstants/searchConstants";
import { getTrademarkReportStatus } from "../../redux/actions/searchActions/trademarksSearchActions";
import { GET_SEARCH_ALERT } from "../../redux/actions/commonActions/types";
import { checkValue } from "../../functions/commonFunctions/checkValueFunctions";
import Faqs from "../managerComponents/user-maual/Faqs";
// import Data from "../managerComponents/user-maual/SearchData";
import * as Sentry from "@sentry/browser";
import EmailPreferences from "../managerComponents/profile/EmailPreferences";
import "../../stylesheets/searchStyleSheets/sidebar.css";
// import mixpanel from "mixpanel-browser";
import ImgSearchInput from "./subComponents/ImgSearchInput";

import search_anim from "../../assets/lottie/search_anim.json";
import Lottie from "react-lottie";

import MikeTyped from "./subComponents/MikeTyped";
import { ClientMaster } from "../commonComponents/MasterComponents/ClientMaster/ClientMaster";
import ImageSearch from "./Routes/ImageSearch";
// import SelectContainer from "./filterContainer/SelectContainer";
import {
  TS_multiwordSearch,
  TS_singlewordSearch,
} from "../../constants/searchConstants/trademarksSearchConstants";
import { resetAllSearchFilter } from "../../redux/actions/searchActions/searchFiltersActions";
import HighlightPrefix from "./subComponents/PrefixHighlight";
import Tour from "reactour";
import CheckableTagComponent from "../Watch/watchFilter/newCommonComponent/CheckableTagComponent";

const { Sider } = Layout;
const { SubMenu } = Menu;

class Search extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isTourOpen: false,
      reportExplicitTrigger2: false,
      reportExplicitTrigger1: false,
      visibleStep: {
        searchType: false,
        searchClasses: false,
        searchAlgos: false,
        tmSearch: false,
      },
      open: null,
      selectedKeys: [window.location.pathname],
      collapsed: false,
      searchTypeOptions: [],
      modalsVisibility: {
        uploadLogo: false,
        trademarksAlgosInfo: false,
        CSSurvey: !(
          document.cookie &&
          document.cookie
            .split("; ")
            .find((row) => row.startsWith("Search_CSSurvey"))
        ),
        suggestions: false,
        complaints: false,
        queriesSurvey: false,
        imageRecognition: true,
      },
      selectedFilters: {
        proprietorsSearchType: "Standard",
        searchType: "",
        trademarksSearchAlgo: "Flash",
        classes: [],
        queries: {
          Boolean: "",
          Wildcard: "",
          Prefix: 3,
          Suffix: 3,
        },
        application: null,
        proprietor: "",
        imageSearch: null,
      },
      filtersVisibility: {
        trademarksSearchAlgos: false,
        classes: false,
        userTrademarks: false,
      },
      filtersSearchTerms: {
        classes: "",
        userTrademarks: "",
      },
      filtersDescriptionSearch: {
        classes: "",
        descriptionSearch: "",
      },
      drawersVisibility: {
        whatsNew: false,
      },
      imageSearch: {
        fileList: [],
      },
      oldFilterConfig: {
        "Trademarks": {
          classes: [],
          search: "",
          searchAlgo:"",
          searchQuery: "",
          prefixLength: 0,
          suffixLength: 0
        },
        "Proprietors": {
          searchType: "Standard",
          query: ""
        },
        "Application": "",
        "Image_Search": "",
        "G&S": {
          classes: [],
          search: "",
        }
      }
    };
    this.handleMenuClick = this.handleMenuClick.bind(this);
    this.onFilterPaginate = this.onFilterPaginate.bind(this);
    this.changeStateValue_L1 = changeStateValue_L1.bind(this);
    this.setSearchFile = this.setSearchFile.bind(this);
    this.changeStateValue_L1_Debounced = debounce(
      this.changeStateValue_L1,
      400,
    );
    this.changeStateValue_L2 = changeStateValue_L2.bind(this);
  }

  async componentDidMount() {
    if (localStorage.getItem("token")) {
      if (this.props.userSubscriptions === null) {
        await this.props.getUserSubscriptions();

        if (this.props.userSubscriptionStatus === SUCCESS) {
          this.handleSubscriptionActions(this.props.userSubscriptions);
        }
      } else {
        this.handleSubscriptionActions(this.props.userSubscriptions);
      }
    } else {
      this.props.logOut();
    }
  }

  static getDerivedStateFromProps(props, state) {
    if (props?.userDetails?.name !== null && props?.userDetails?.email) {
      localStorage.setItem("userName", props?.userDetails?.name);
      localStorage.setItem("userEmail", props?.userDetails?.email);
      Sentry.setTag("userName", props?.userDetails?.name || "NA");
      Sentry.setTag("userEmail", props?.userDetails?.email || "NA");
    }
    return null;
  }

  handleSubscriptionActions(subscriptions) {
    let searchTypeOptions = [];
    if (subscriptions.search.length > 0) {
      if (subscriptions.search.includes("MARK")) {
        this.props.getUserTrademarksInSearch({
          page: 1,
          search_subscription: "TRADEMARK",
        });
        searchTypeOptions.push("Trademarks");
        searchTypeOptions.push("Application");
        searchTypeOptions.push("G&S");
      }
      if (subscriptions.search.includes("PROPRIETOR")) {
        searchTypeOptions.push("Proprietors");
      }
      if (subscriptions.search.includes("IMAGE_SEARCH")) {
        searchTypeOptions.push("Image_Search");
      }

      let currentType = "Trademarks";
      if (window.location.pathname === "/search/image_search") {
        currentType = "Image_Search";
      }
      this.setState({
        searchTypeOptions,
        selectedFilters: {
          ...this.state.selectedFilters,
          searchType: currentType,
        },
      });
      this.props.getProfile();
      this.props.getAlertMessage(GET_SEARCH_ALERT, "search");
    } else if (subscriptions.manager.length > 0 || subscriptions.watch) {
      this.props.history.push("/");
    } else {
      this.props.logOut();
    }
  }

  onCollapse = (collapsed) => {
    this.setState({ collapsed });
  };

  handleTourOpen = (e) => {
    this.setState({ isTourOpen: e });
  };

  searchAlgos = async () => {
    Object.keys(this.props.axiosCancelSearches).map(function () {
      return "Request is cancelled!!!";
    });

    if (this.props.axiosCancelFilters) {
      this.props.axiosCancelFilters("Filters Request Cancelled");
    }

    const axiosSources = generateAxiosSources();

    // Generate the cancellation object
    const cancellationTokens = TS_multiwordSearch.reduce((acc, key) => {
      acc[key] = axiosSources[`${key.toLowerCase()}AxiosSource`]?.cancel;
      return acc;
    }, {});
    this.props.TPAction(TS_SET_SEARCHES_CANCEL, cancellationTokens);

    let flashAlgos = [];

    if (this.props.multiwordTrademark) {
      flashAlgos = TS_multiwordSearch.filter((key) => this.props[key]).map(
        (key) => ({
          key,
          token: axiosSources[`${key.toLowerCase()}AxiosSource`]?.token,
        }),
      );
    } else {
      flashAlgos = TS_singlewordSearch.filter((key) => this.props[key]).map(
        (key) => ({
          key,
          token: axiosSources[`${key.toLowerCase()}AxiosSource`]?.token,
        }),
      );
    }

    switch (this.props.trademarksSearchAlgo) {
      case "Flash":
        flashAlgos.forEach(({ key, token }) => {
          this.props.getSearchResults(
            {
              ...this.props[key].searchParameters,
              search_log_id: this.props[key].searchLogID,
              operation: "search",
              flash_flag: true,
              page: 1,
              filter_flag,
              ...filters,
            },
            key,
            token,
          );
        });

        break;

      case "Boolean":
      case "Initials":
      case "Fuzzy":
      case "Wildcard":
      case "Contains":
      case "Phonetic":
      case "Prefix":
      case "Suffix":
        // Find the matching algorithm from the flashAlgos array
        const algo = flashAlgos.find(({ key }) => key === algoKey);
        if (algo) {
          this.props.getSearchResults(
            {
              ...this.props[algo.key].searchParameters,
              search_log_id: this.props[algo.key].searchLogID,
              operation: "search",
              page: 1,
              filter_flag,
              ...filters,
            },
            algo.key,
            algo.token,
          );
        }
        break;

      case "GOODS_SERVICE":
        if (refresh) {
          this.props.handleSearchInfoDispatch({
            trademarksSearchAlgo,
            activeTab,
            userTrademark,
            multiwordTrademark,
            axiosCancelSearches: {},
            searchCounter: 1,
            reminderStamp: new Date().getTime() + refreshPeriod,
            searchStamp: new Date().getTime() + refreshPeriod,
          });
          this.props.getSearchResults(
            {
              ...this.props["GOODS_SERVICE"].searchParameters,
              operation: "search",
              flash_flag: false,
              page: 1,
              search_log_id: null,
            },
            "GOODS_SERVICE",
            null,
            true,
          );
        } else {
          this.props.getSearchResults(
            {
              ...this.props["GOODS_SERVICE"].searchParameters,
              search_log_id: this.props["GOODS_SERVICE"].searchLogID,
              operation: "search",
              page: 1,
              filter_flag,
              ...filters,
            },
            "GOODS_SERVICE",
            null,
          );
        }

        break;

      default:
        break;
    }
  };

  generateAxiosSources = () => {
    //generate cancellation token
    const axiosSources = {};

    TS_multiwordSearch.forEach((key) => {
      const cancelToken = Axios.CancelToken;
      axiosSources[`${key.toLowerCase()}CancelToken`] = cancelToken;
      axiosSources[`${key.toLowerCase()}AxiosSource`] = cancelToken.source();
    });
    return axiosSources;
  };

  isPreviousFiltersPresent = (newFilterConfig) => {
    switch (this.state.selectedFilters.searchType) {
      case "Trademarks": 
      case "Proprietors":
      case "Application":
      case "G&S":
       return JSON.stringify(newFilterConfig) === JSON.stringify(this.state.oldFilterConfig);
      case "Image_Search":
        return false;
    }
    return false;
  }

  async handleSearch() {

    let newFilterConfig= {
      "Trademarks": {
        classes: this.state?.selectedFilters?.classes ?? [],
        search: this.state?.filtersSearchTerms?.userTrademarks ?? "",
        searchAlgo: this.state?.selectedFilters?.trademarksSearchAlgo ?? "",
        searchQuery: "",
        prefixLength: this.state.selectedFilters.trademarksSearchAlgo === "Prefix" ? this.state?.selectedFilters?.queries?.Prefix ?? 0 : 0,
        suffixLength: this.state.selectedFilters.trademarksSearchAlgo === "Suffix" ? this.state?.selectedFilters?.queries?.Suffix ?? 0 : 0
      },
      "Proprietors": {
        searchType: this.state?.selectedFilters?.proprietorsSearchType ?? "Standard",
        query: this.state.selectedFilters.proprietorsSearchType === "Contains"
        ? this.state.selectedFilters.proprietor
            .toLowerCase()
            .replace(/ {1,}/g, " ")
            .trim()
        : this.state.selectedFilters.proprietor
            .toLowerCase()
            .replace(/[&|!'"()$%_^]/g, "")
            .replace(/ {1,}/g, " ")
            .trim(),
      },
      "Application": this.state?.selectedFilters?.application ?? "",
      "Image_Search": "",
      "G&S": {
        classes: this.state?.selectedFilters?.classes ?? [],
        search: this.state?.filtersDescriptionSearch?.descriptionSearch ?? "",
      }
    }

    if (this.isPreviousFiltersPresent(newFilterConfig)) {
      notification["warning"]({
        message: "Search Alert!",
        description:
          "The search for this mark is already done. Update the term to proceed with a fresh search.",
      });
    }else{
      switch (this.state.selectedFilters.searchType) {
        case "Trademarks":
          let userTrademarkInput = checkValue(
            this.state.filtersSearchTerms.userTrademarks,
          )
            ? this.state.filtersSearchTerms.userTrademarks.trim().toLowerCase()
            : this.props[this.props.activeTab]?.searchParameters?.search_query
              ? this.props[this.props.activeTab]?.searchParameters?.search_query
              : "NA";
          if (userTrademarkInput === "NA") {
            notification["warning"]({
              message: "Search input empty",
              description: "Please enter a search term",
            });
            break;
          }
          let multiwordTrademark = userTrademarkInput.includes(" ")
            ? true
            : false;
          if (
            this.state.selectedFilters.trademarksSearchAlgo === "Prefix" &&
            (this.state.selectedFilters.queries.Prefix < 3 ||
              this.state.selectedFilters.queries.Prefix >
                userTrademarkInput.length)
          ) {
            notification["warning"]({
              message: "Invalid Prefix length",
              description:
                "Prefix length must be greater than 2 and less than term length.",
            });
            break;
          }
  
          if (
            this.state.selectedFilters.trademarksSearchAlgo === "Suffix" &&
            (this.state.selectedFilters.queries.Suffix < 3 ||
              this.state.selectedFilters.queries.Suffix >
                userTrademarkInput.length)
          ) {
            notification["warning"]({
              message: "Invalid Suffix length",
              description:
                "Suffix length must be greater than 2 and less than term length.",
            });
            break;
          }
  
          if (
            (this.state.selectedFilters.trademarksSearchAlgo === "Boolean" ||
              this.state.selectedFilters.trademarksSearchAlgo === "Initials") &&
            !multiwordTrademark
          ) {
            notification["warning"]({
              message: "Single Contains Trademark",
              description: "Your trademark must have more than 1 word.",
            });
            break;
          }
  
          if (
            this.state.selectedFilters.trademarksSearchAlgo === "Boolean" &&
            this.state.selectedFilters.queries.Boolean.replace(/ {1,}/g, " ")
              .replace(/\[\[&\]\]/g, "")
              .replace(/\[\[\|\]\]/g, "")
              .replace(/\[\[!\]\]/g, "")
              .search(/[&|!'"()$%_^]/g) !== -1
          ) {
            notification["warning"]({
              message: "Invalid Boolean Characters",
              description:
                "Your query cannot have & ' \" ( ) $ %_^ | and ! apart from the boolean operators.",
            });
            break;
          }
  
          if (
            this.state.selectedFilters.trademarksSearchAlgo === "Boolean" &&
            !this.state.selectedFilters.queries.Boolean.replace(/ {1,}/g, " ")
              .replace(/\[\[&\]\]/g, " ")
              .replace(/\[\[\|\]\]/g, " ")
              .replace(/\[\[!\]\]/g, "")
              .trim()
              .includes(" ")
          ) {
            notification["warning"]({
              message: "Single Contains Query",
              description: "Your query must have more than 1 word.",
            });
            break;
          }
  
          let selectedTrademark =
            (this.props.userTrademarks &&
              this.props.userTrademarks.find(
                (eachTrademark) =>
                  eachTrademark.term.trim().toLowerCase() === userTrademarkInput,
              )) ||
            "";
          let responseStatus = SUCCESS;
  
          if (!selectedTrademark) {
            const response = await this.props.postUserTrademark(
              userTrademarkInput,
              "TRADEMARK",
            );
            responseStatus = response.status;
            if (response.status === SUCCESS) {
              selectedTrademark = response?.data?.data?.find(
                (eachTrademark) =>
                  eachTrademark.term.trim().toLowerCase() === userTrademarkInput,
              );
              this.setState({
                selectedFilters: {
                  ...this.state.selectedFilters,
                  queries: {
                    ...this.state.selectedFilters.queries,
                    Boolean: selectedTrademark?.term
                      ? selectedTrademark.term
                          .replace(/[&|!]/g, "")
                          .replace(/ {1,}/g, " ")
                          .trim()
                          .replace(/ /g, "[[&]]")
                      : "",
                    Wildcard: `[[*]]${
                      selectedTrademark?.term
                        ? selectedTrademark.term
                            .replace(/[%_]/g, "")
                            .replace(/ {1,}/g, " ")
                            .trim()
                            .replace(/ /g, "[[*]]")
                        : ""
                    }[[*]]`,
                  },
                },
                filtersSearchTerms: {
                  ...this.state.filtersSearchTerms,
                  userTrademarks: selectedTrademark?.term
                    ? selectedTrademark.term
                    : "",
                },
              });
            } else {
              notification["error"]({
                message: "Error! Unable To Add Trademark",
                description:
                  "The server is unable to add more trademarks. Quota is full please reach out to team@mikelegal.com",
              });
              break;
            }
          }
  
          this.props.resetAllSearchFilter();
  
          if (responseStatus === SUCCESS) {
            this.props.TPAction(SET_SEARCH_PATH, "/search/trademarks-search");
            this.props.TPAction(TS_SET_DEFAULT_STATE, {});

            this.setState((prevState) => ({
              ...prevState,
              oldFilterConfig: {
                ...prevState.oldFilterConfig,
                ...newFilterConfig
              }
            }))

            const axiosSources = this.generateAxiosSources();
  
            // Generate the cancellation object
            const cancellationTokens = TS_multiwordSearch.reduce((acc, key) => {
              acc[key] = axiosSources[`${key.toLowerCase()}AxiosSource`]?.cancel;
              return acc;
            }, {});
  
            const removeSpecialCharsRegex = /[^a-zA-Z0-9 ]/g;
            const removeMultipleSpacesRegex = / {1,}/g;
            const replaceSpacesWithSymbolRegex = / /g;
            const removeSpecificCharsRegex = /[&|!'"()$%_^]/g;
            const removeInitialsFuzzyRegex = /[&|!%_]/g;
  
            const flashAlgos = [
              {
                key: "Boolean",
                token: axiosSources.booleanAxiosSource?.token,
                query: selectedTrademark?.term
                  ? selectedTrademark.term
                      .replace(/[&|!]/g, "")
                      .replace(/ {1,}/g, " ")
                      .trim()
                      .replace(/ /g, "[[&]]")
                  : "",
              },
              {
                key: "Initials",
                token: axiosSources.initialsAxiosSource?.token,
                query: selectedTrademark?.term
                  ? selectedTrademark.term
                      .split(" ")
                      .map((a) => a[0])
                      .join("")
                      .replace(removeInitialsFuzzyRegex, "")
                  : "",
              },
              {
                key: "Fuzzy",
                token: axiosSources.fuzzyAxiosSource?.token,
                query: selectedTrademark?.term
                  ? selectedTrademark.term
                      .replace(removeMultipleSpacesRegex, " ")
                      .trim()
                  : "",
              },
              {
                key: "Wildcard",
                token: axiosSources.wildcardAxiosSource?.token,
                query: `[[*]]${
                  selectedTrademark?.term
                    ? selectedTrademark.term
                        .replace(removeSpecificCharsRegex, "")
                        .replace(removeMultipleSpacesRegex, " ")
                        .trim()
                        .replace(replaceSpacesWithSymbolRegex, "[[*]]")
                    : ""
                }[[*]]`,
              },
              {
                key: "Contains",
                token: axiosSources.containsAxiosSource?.token,
                query: selectedTrademark?.term ? selectedTrademark?.term : "",
              },
              {
                key: "Phonetic",
                token: axiosSources.phoneticAxiosSource?.token,
                query: selectedTrademark?.term
                  ? selectedTrademark.term
                      .replace(removeSpecialCharsRegex, "")
                      .replace(removeMultipleSpacesRegex, " ")
                      .trim()
                  : "",
              },
              {
                key: "Prefix",
                token: axiosSources.prefixAxiosSource?.token,
                query: selectedTrademark?.term ? selectedTrademark?.term : "",
              },
              {
                key: "Suffix",
                token: axiosSources.suffixAxiosSource?.token,
                query: selectedTrademark?.term ? selectedTrademark?.term : "",
              },
            ];
            this.props.TPAction(TS_SELECT_VIEW, "1");
  
            switch (this.state.selectedFilters.trademarksSearchAlgo) {
              case "Flash":
                this.props.handleSearchInfoDispatch({
                  trademarksSearchAlgo:
                    this.state.selectedFilters.trademarksSearchAlgo,
                  activeTab: "Fuzzy",
                  userTrademark: selectedTrademark,
                  multiwordTrademark,
                  axiosCancelSearches: cancellationTokens,
                  searchCounter: multiwordTrademark ? 8 : 6,
                  reminderStamp: new Date().getTime() + refreshPeriod,
                  searchStamp: new Date().getTime() + refreshPeriod,
                });
  
                if (multiwordTrademark) {
                  flashAlgos.slice(0, 2).forEach(({ key, token, query }) => {
                    this.props.getSearchResults(
                      {
                        operation: "search",
                        flash_flag: true,
                        mark_id: selectedTrademark?.id,
                        search_classes: `${this.state.selectedFilters.classes}`,
                        page: 1,
                        search_type: key.toLowerCase(),
                        query,
                      },
                      key,
                      token,
                      true,
                    );
                  });
                }
  
                flashAlgos.slice(2).forEach(({ key, token, query }) => {
                  this.props.getSearchResults(
                    {
                      operation: "search",
                      flash_flag: true,
                      mark_id: selectedTrademark?.id,
                      search_classes: `${this.state.selectedFilters.classes}`,
                      page: 1,
                      search_type: key.toLowerCase(),
                      query,
                      ...(key === "Prefix" || key === "Suffix"
                        ? {
                            prefix_suffix_length:
                              this.state.selectedFilters.queries[key],
                          }
                        : {}),
                    },
                    key,
                    token,
                  );
                });
                break;
              case "Boolean":
              case "Initials":
              case "Fuzzy":
              case "Wildcard":
              case "Contains":
              case "Phonetic":
              case "Prefix":
              case "Suffix":
                // Find the matching algorithm from the flashAlgos array
                const algo = flashAlgos.find(
                  ({ key, token, query }) =>
                    key === this.state.selectedFilters.trademarksSearchAlgo,
                );
                if (algo) {
                  const { key, token, query } = algo;
  
                  this.props.handleSearchInfoDispatch({
                    trademarksSearchAlgo:
                      this.state.selectedFilters.trademarksSearchAlgo,
                    activeTab: key,
                    userTrademark: selectedTrademark,
                    multiwordTrademark,
                    axiosCancelSearches: {
                      [key]: cancellationTokens[key],
                    },
                    searchCounter: 1,
                    reminderStamp: new Date().getTime() + refreshPeriod,
                    searchStamp: new Date().getTime() + refreshPeriod,
                  });
  
                  if (
                    this.state.selectedFilters.trademarksSearchAlgo ===
                      "Prefix" ||
                    this.state.selectedFilters.trademarksSearchAlgo === "Suffix"
                  ) {
                    if (
                      this.props[this.state.selectedFilters.trademarksSearchAlgo]
                        ?.searchParameters?.search_query !== userTrademarkInput
                    ) {
                      this.setState({
                        selectedFilters: {
                          ...this.state.selectedFilters,
                          queries: {
                            ...this.state.selectedFilters.queries,
                            Prefix: 3,
                            Suffix: 3,
                          },
                        },
                      });
                    }
                  }
  
                  this.props.getSearchResults(
                    {
                      operation: "search",
                      flash_flag: false,
                      mark_id: selectedTrademark?.id,
                      search_classes: `${this.state.selectedFilters.classes}`,
                      page: 1,
                      search_type: key.toLowerCase(), // Assuming the search type matches the algo key
                      query: query,
                      ...(this.state.selectedFilters.trademarksSearchAlgo ===
                        "Prefix" ||
                      this.state.selectedFilters.trademarksSearchAlgo === "Suffix"
                        ? {
                            prefix_suffix_length:
                              this.state.selectedFilters.queries[
                                this.state.selectedFilters.trademarksSearchAlgo
                              ],
                          }
                        : {}),
                    },
                    key, // Use the key for the second argument
                    token, // Use the token from the algo
                  );
                }
                break;
  
              default:
                break;
            }
  
            this.props.history.push("/search/trademarks-search");
          } else {
          }
  
          break;
  
        case "Proprietors":
          if (this.state.selectedFilters.proprietor.length > 0) {
            if (this.props.proprietorsSearchStatus === LOADING) {
              notification["warning"]({
                message: "Request Under Process",
                description:
                  "A request is under process. Kindly, wait for its completion.",
              });
            } else {
              this.props.TPAction(SET_SEARCH_PATH, "/search/proprietors-search");
              if (window.location.pathname === "/search/proprietors-search") {
                resetProprietorsSearchState();
              }
              this.props.getProprietorsSearch({
                search_type: this.state.selectedFilters.proprietorsSearchType,
                query:
                  this.state.selectedFilters.proprietorsSearchType === "Contains"
                    ? this.state.selectedFilters.proprietor
                        .toLowerCase()
                        .replace(/ {1,}/g, " ")
                        .trim()
                    : this.state.selectedFilters.proprietor
                        .toLowerCase()
                        .replace(/[&|!'"()$%_^]/g, "")
                        .replace(/ {1,}/g, " ")
                        .trim(),
                page: 1,
              });
              this.setState((prevState) => ({
                ...prevState,
                oldFilterConfig: {
                  ...prevState.oldFilterConfig,
                  ...newFilterConfig
                }
              }))
              this.props.history.push("/search/proprietors-search");
            }
          } else {
            notification["warning"]({
              message: "Search input empty",
              description: "Please enter a search term",
            });
          }
          break;
  
        case "Application":
          if (/^\d+$/.test(this.state.selectedFilters.application)) {
            this.props.TPAction(SET_SEARCH_PATH, "/search/application-search");
  
            const trademarkCheck = await this.props.getTrademarkProfileData(
              true,
              this.state.selectedFilters.application,
              false,
            );
            if (trademarkCheck.status === SUCCESS) {
              this.props.getTrademarkProfileData(
                false,
                this.state.selectedFilters.application,
                false,
              );
              this.props.getTrademarkProfileData(
                false,
                this.state.selectedFilters.application,
                true,
              );
              this.setState((prevState) => ({
                ...prevState,
                oldFilterConfig: {
                  ...prevState.oldFilterConfig,
                  ...newFilterConfig
                }
              }))
              this.props.history.push("/search/application-search");
            }
          } else if (
            this.state.selectedFilters.application === null ||
            this.state.selectedFilters.application === ""
          ) {
            notification["warning"]({
              message: "Search input empty",
              description: "Please enter a search term",
            });
          } else {
            notification["warning"]({
              message: "Not An Integer",
              description: "Application number can consist of only numbers.",
            });
          }
          break;
        case "Image_Search":
          if (this.state.imageSearch?.fileList.length) {
            this.props.TPAction(SET_SEARCH_PATH, "/search/image_search");
            let imageSearchCancelToken = Axios.CancelToken;
            let imageSearchAxiosSource = imageSearchCancelToken.source();
  
            this.props.handleSearchInfoDispatch({
              trademarksSearchAlgo: "none",
              activeTab: "ImageSearch",
              userTrademark: "none",
              multiwordTrademark: false,
              axiosCancelSearches: {
                ImageSearch: imageSearchAxiosSource.cancel,
              },
              searchCounter: 1,
              reminderStamp: new Date().getTime() + refreshPeriod,
              searchStamp: new Date().getTime() + refreshPeriod,
            });
            this.props.getImageSearchResults(
              {
                operation: "search",
                search_classes: `${this.state.selectedFilters.classes}`,
                page: 1,
                image: this.state.imageSearch?.fileList[0],
              },
              "ImageSearch",
              true,
            );
            this.props.SetFileAction(
              SET_FILE,
              JSON.stringify(this.state.imageSearch?.fileList[0]),
            );
            this.props.history.push("/search/image_search");
          } else {
            notification["warning"]({
              message: "Image Not Uploaded",
              description: "Please upload an Image to get results.",
            });
          }
          break;
        case "G&S":
          this.props.TPAction(TS_SELECT_VIEW, "2");
          let filtersDescriptionSearchInput = checkValue(
            this.state.filtersDescriptionSearch.descriptionSearch,
          )
            ? this.state.filtersDescriptionSearch.descriptionSearch
                .trim()
                .toLowerCase()
            : "NA";
          if (filtersDescriptionSearchInput === "NA") {
            notification["warning"]({
              message: "Search input empty",
              description: "Please enter a search term",
            });
            break;
          }
          let descResponseStatus = SUCCESS;
  
          let searchedDescription =
            (this.props.userTrademarks &&
              this.props.userTrademarks.find(
                (eachTrademark) =>
                  eachTrademark.term.trim().toLowerCase() ===
                  filtersDescriptionSearchInput,
              )) ||
            "";
          if (!searchedDescription) {
            const response = await this.props.postUserTrademark(
              filtersDescriptionSearchInput,
              "GOODS_SERVICE",
            );
            descResponseStatus = response.status;
            if (response.status === SUCCESS) {
              searchedDescription = response?.data?.data?.find(
                (eachTrademark) =>
                  eachTrademark.term.trim().toLowerCase() ===
                  filtersDescriptionSearchInput,
              );
              this.setState({
                filtersDescriptionSearch: {
                  ...this.state.filtersDescriptionSearch,
                  descriptionSearch: filtersDescriptionSearchInput
                    ? filtersDescriptionSearchInput
                    : "",
                },
              });
            } else {
              notification["error"]({
                message: "Error! Unable To Add G&S Description",
                description:
                  "G&S Quota is full please reach out to team@mikelegal.com",
              });
              break;
            }
          }
  
          this.props.resetAllSearchFilter();
          
          if (descResponseStatus === SUCCESS) {
            this.props.handleSearchInfoDispatch({
              trademarksSearchAlgo: "GOODS_SERVICE",
              activeTab: "GOODS_SERVICE",
              userTrademark: searchedDescription,
              multiwordTrademark: true,
              axiosCancelSearches: {
              },
              searchCounter: 1,
              reminderStamp: new Date().getTime() + refreshPeriod,
              searchStamp: new Date().getTime() + refreshPeriod,
            });
  
            this.props.getSearchResults(
              {
                operation: "search",
                flash_flag: true,
                mark_id: searchedDescription?.id,
                search_classes: `${this.state.selectedFilters.classes}`,
                page: 1,
                search_type: "GOODS_SERVICE",
                query: searchedDescription?.term
                  ? searchedDescription?.term.replace(/ {1,}/g, " ").trim()
                  : "",
              },
              "GOODS_SERVICE",
              null,
              true,
            );

            this.setState((prevState) => ({
              ...prevState,
              oldFilterConfig: {
                ...prevState.oldFilterConfig,
                ...newFilterConfig
              }
            }))
  
            this.props.TPAction(SET_SEARCH_PATH, "/search/description-search");
            this.props.TPAction(TS_SET_DEFAULT_STATE, {});
  
            Object.keys(this.props.axiosCancelSearches).forEach((key) => {
              this.props.axiosCancelSearches[key]();
            });
            Object.keys(this.props.axiosCancelAnalysis).forEach((key) => {
              this.props.axiosCancelAnalysis[key]();
            });
            if (this.props.axiosCancelFilters) {
              this.props.axiosCancelFilters("Filters Request Cancelled");
            }
            this.props.history.push("/search/description-search");
          } else {
          }
  
          break;
  
        default:
          break;
      }
    }
  }

  handleMenuClick(e) {
    this.setState({ selectedKeys: [e.key] });
  }
  setSearchFile(fileList) {
    this.setState({
      imageSearch: { fileList: fileList },
    });
    // this.props.TPAction("SET_FILE",fileList);
  }

  onFilterPaginate() {
    if (this.props.userTrademarksStatus !== "LOADING") {
      const page_size = 100;
      let total_pages = 0;
      let page = this.props.userTrademarksPage + 1;
      let count = 0;
      count = this.props.userTrademarksCount;

      if (count !== undefined && count !== null && count > 0) {
        total_pages = Math.ceil(count / page_size);
      }
      if (
        page <= total_pages &&
        this.props.userTrademarksStatus !== "LOADING"
      ) {
        this.props.getUserTrademarksInSearch({
          filter_contains_term: this.state.filtersSearchTerms?.userTrademarks,
          page: page,
          search_subscription:
            this.state.selectedFilters.searchType === "G&S"
              ? "GOODS_SERVICE"
              : "TRADEMARK",
        });
      }
    }
  }

  debouncedSearch = debounce(async (searchInput) => {
    await this.props.getUserTrademarksInSearch({
      filter_contains_term: searchInput,
      page: 1,
      search_subscription:
        this.state.selectedFilters.searchType === "G&S"
          ? "GOODS_SERVICE"
          : "TRADEMARK",
    });
  }, 300);

  handleSearchInput = (e) => {
    this.debouncedSearch(e.target.value);
  };

  handleOpenChange = (flag, key) => {
    if (flag) {
      this.setState({
        open: key,
      });
    } else {
      this.setState({
        open: null,
      });
    }
  };

  componentWillUnmount() {
    this.debouncedSearch.cancel();
  }

  render() {
    if (
      this.props.userSubscriptionStatus === "LOADING" ||
      this.props.userSubscriptionStatus === null
    ) {
      return (
        <div className="loading-spin">
          <Spin />
        </div>
      );
    }
    const headerButton = {
      margin: "0px 5px 0px 0px",
      boxShadow: "none",
      border: "none",
      padding: "0px 5px",
      fontSize: "16px",
      color: "#929292",
      height:
        this.state.selectedFilters.searchType === "Image_Search"
          ? "128px"
          : "inherit",
    };

    const headerButtonSelected = { ...headerButton, color: "#4285f4" };

    const classesOptions = allClasses?.filter((eachClass) =>
      `${eachClass}`.includes(`${this.state.filtersSearchTerms.classes}`),
    );
    const classesMenu = (
      <div className="search-classes-menu">
        <Input
          placeholder="Search classes"
          onChange={(event) =>
            this.changeStateValue_L1_Debounced(
              "filtersSearchTerms",
              "classes",
              event.target.value,
            )
          }
          style={{ width: "200px", fontSize: "16px" }}
        />
        <Menu
          selectable={true}
          multiple={true}
          selectedKeys={this.state.selectedFilters.classes}
          onSelect={(event) =>
            this.changeStateValue_L1(
              "selectedFilters",
              "classes",
              event.selectedKeys,
            )
          }
          onDeselect={(event) =>
            this.changeStateValue_L1(
              "selectedFilters",
              "classes",
              event.selectedKeys,
            )
          }
          style={{
            maxHeight: "200px",
            width: "200px",
            fontSize: "16px",
            overflow: "auto",
            boxShadow: "0px 0px 5px #c9c9c9",
          }}
        >
          {classesOptions.map((eachClass) => (
            <Menu.Item key={eachClass} style={{ fontSize: "16px" }}>
              {eachClass}
            </Menu.Item>
          ))}
        </Menu>
      </div>
    );
    const algos = [
      "Flash",
      "Wildcard",
      "Initials",
      "Fuzzy",
      "Boolean",
      "Contains",
      "Prefix",
      "Suffix",
    ];
    const algosMenu = (
      <div className="algos-dropdown-menu">
        <Menu
          selectable={true}
          selectedKeys={[this.state.selectedFilters.trademarksSearchAlgo]}
          onClick={({ key }) =>
            this.changeStateValue_L1(
              "selectedFilters",
              "trademarksSearchAlgo",
              key,
            )
          }
          style={{
            maxHeight: "200px",
            width: "200px",
            fontSize: "16px",
            overflow: "auto",
            boxShadow: "0px 0px 5px #c9c9c9",
          }}
        >
          {algos.map((e) => (
            <Menu.Item key={e} style={{ fontSize: "16px" }}>
              {e}
            </Menu.Item>
          ))}
        </Menu>
        <div style={{ textAlign: "center", marginTop: "15px" }}>
          <Tooltip
            placement="right"
            open={this.state.filtersVisibility.trademarksSearchAlgos}
            title="What are these search types?"
          >
            <span
              style={{
                padding: "10px",
                backgroundColor: "#ffffff",
                borderRadius: "50%",
                boxShadow: "0px 0px 5px #4285f4",
                cursor: "pointer",
              }}
              onClick={() =>
                this.setState({
                  filtersVisibility: {
                    ...this.state.filtersVisibility,
                    trademarksSearchAlgos: false,
                  },
                  modalsVisibility: {
                    ...this.state.modalsVisibility,
                    trademarksAlgosInfo: true,
                  },
                })
              }
            >
              <QuestionCircleFilled
                style={{
                  fontSize: "20px",
                  verticalAlign: "0px",
                  color: "#4285f4",
                }}
              />
            </span>
          </Tooltip>
        </div>
      </div>
    );

    const getSearchSubscription = (searchType) => {
      let sub = "TRADEMARK";
      if (searchType === "G&S") {
        sub = "GOODS_SERVICE";
      }
      return sub;
    };
    const quotaEnabledSearch = ["Trademarks", "G&S"];

    const searchTypesMenu = (
      <div className="search-type-dropdown-menu">
        <Menu
          selectable={true}
          selectedKeys={[this.state.selectedFilters.searchType]}
          onClick={({ key }) => {
            this.changeStateValue_L1("selectedFilters", "searchType", key);
            if (quotaEnabledSearch.includes(key)) {
              const search_subscription = getSearchSubscription(key);
              this.props.getUserTrademarksInSearch({
                page: 1,
                search_subscription,
              });
            }
          }}
          style={{
            maxHeight: "200px",
            width: "200px",
            fontSize: "16px",
            overflow: "auto",
            boxShadow: "0px 0px 5px #c9c9c9",
          }}
        >
          {this.state.searchTypeOptions.map((e) => (
            <Menu.Item key={e} style={{ fontSize: "16px" }}>
              {e === "Image_Search" ? "Image Search" : e}
            </Menu.Item>
          ))}
        </Menu>
      </div>
    );

    const proprietorsSearchTypes = [
      "Standard",
      "Name",
      "Address",
      "Trading As",
      "Type Details",
      "Contains",
    ];
    const proprietorsSearchTypesMenu = (
      <>
        <Menu
          selectable={true}
          selectedKeys={[this.state.selectedFilters.proprietorsSearchType]}
          onClick={({ key }) =>
            this.changeStateValue_L1(
              "selectedFilters",
              "proprietorsSearchType",
              key,
            )
          }
          style={{
            maxHeight: "200px",
            width: "200px",
            fontSize: "16px",
            overflow: "auto",
            boxShadow: "0px 0px 5px #c9c9c9",
          }}
        >
          {proprietorsSearchTypes.map((e) => (
            <Menu.Item key={e} style={{ fontSize: "16px" }}>
              {e}
            </Menu.Item>
          ))}
        </Menu>
      </>
    );

    let userTrademarksMenu = (
      <Menu>
        <Menu.Item>No trademarks found</Menu.Item>
      </Menu>
    );

    if (this.props.userTrademarks.length > 0) {
      userTrademarksMenu = (
        <>
          <Menu
            style={{
              position: "initial",
              height: "150px",
              overflow: "scroll",
              width: "450px",
            }}
            selectable
            onScroll={(event) => {
              const { scrollTop, scrollHeight, clientHeight } = event.target;
              if (scrollTop + clientHeight >= scrollHeight - 20) {
                this.onFilterPaginate();
              }
            }}
          >
            {this.props.userTrademarks?.map((eachCategory) => (
              <Menu.Item
                key={eachCategory.id}
                className={`ant-dropdown-menu-item`}
                onClick={() => {
                  if (this.state.selectedFilters.searchType === "Trademarks") {
                    this.setState({
                      selectedFilters: {
                        ...this.state.selectedFilters,
                        queries: {
                          ...this.state.selectedFilters.queries,
                          Boolean: eachCategory.term
                            .replace(/[&|!'"()$%_^]/g, "")
                            .replace(/ {1,}/g, " ")
                            .trim()
                            .replace(/ /g, "[[&]]"),
                          Wildcard: `[[*]]${eachCategory.term
                            .replace(/[&|!'"()$%_^]/g, "")
                            .replace(/ {1,}/g, " ")
                            .trim()
                            .replace(/ /g, "[[*]]")}[[*]]`,
                        },
                      },
                      filtersSearchTerms: {
                        ...this.state.filtersSearchTerms,
                        userTrademarks: eachCategory.term,
                      },
                    });
                  } else if (this.state.selectedFilters.searchType === "G&S") {
                    this.setState({
                      filtersDescriptionSearch: {
                        ...this.state.filtersDescriptionSearch,
                        descriptionSearch: eachCategory.term,
                      },
                    });
                  }
                  this.changeStateValue_L1_Debounced(
                    "filtersVisibility",
                    "userTrademarks",
                    false,
                  );
                }}
              >
                {eachCategory.term}
              </Menu.Item>
            ))}
          </Menu>
        </>
      );
    }

    const ImageUploader = (
      <span>
        <ImgSearchInput
          setSearchFile={this.setSearchFile}
          imgFileList={this.props.ImageSearch?.fileList}
        />
      </span>
    );

    const defaultOptions = {
      loop: true,
      autoplay: true,
      animationData: search_anim,
      // rendererSettings: {
      //   preserveAspectRatio: "xMidYMid slice"
      // }
    };

    const steps = [
      {
        content: (
          <div>
            <p style={{ color: "white" }}>
              Welcome to MikeTM Search - MikeTM Search is an advanced tool for
              comprehensive public search. By using MikeTM Search, you can
              identify similar marks to your trademark, marks filed by a
              particular proprietor and even if your mark is present in Good and
              Services description of any filed application.
            </p>
          </div>
        ),
        style: {
          backgroundColor: "#1F3EAA",
          borderRadius: "4px",
        },
      },
      {
        selector: ".search-type-dropdown",
        content: (
          <div>
            <p style={{ color: "white" }}>
              You can select the type of search to be done from here.
            </p>
          </div>
        ),
        style: {
          backgroundColor: "#1F3EAA",
          borderRadius: "4px",
        },
        action: () => {
          if (!this.state.visibleStep.searchType) {
            this.setState({
              visibleStep: {
                searchType: true,
                searchClasses: false,
                searchAlgos: false,
              },
            });
          }
        },
      },

      {
        selector: ".search-type-dropdown-menu",
        content: (
          <div>
            <p style={{ color: "white" }}>
              Currently, Mike TM Search provides multiple types of searches
              including Trademark Search, Application Search, Proprietor Search
              and Goods & Services Description Search.
            </p>
          </div>
        ),
        style: {
          backgroundColor: "#1F3EAA",
          borderRadius: "4px",
        },
      },

      {
        selector: ".search-input",
        content: (
          <div>
            <p style={{ color: "white" }}>Enter you search term here.</p>
          </div>
        ),
        style: {
          backgroundColor: "#1F3EAA",
          borderRadius: "4px",
        },

        action: () => {
          if (this.state.visibleStep.searchType) {
            this.setState({
              visibleStep: {
                searchType: false,
                searchClasses: false,
                searchAlgos: false,
              },
            });
          }
        },
      },
      {
        selector: ".search-classes",
        content: (
          <div>
            <p style={{ color: "white" }}>
              You can use class filter to narrow down your search.
            </p>
          </div>
        ),
        style: {
          backgroundColor: "#1F3EAA",
          borderRadius: "4px",
        },
        action: () => {
          if (!this.state.visibleStep.searchClasses) {
            this.setState({
              visibleStep: {
                searchType: false,
                searchClasses: true,
                searchAlgos: false,
              },
            });
          }
        },
      },
      {
        selector: ".search-classes-menu",
        content: (
          <div>
            <p style={{ color: "white" }}>
              You have an ability to select single or multiple classes. If no
              class is selected then by default all 45 class search is executed.
            </p>
          </div>
        ),
        style: {
          backgroundColor: "#1F3EAA",
          borderRadius: "4px",
        },
      },
      {
        selector: ".algos-dropdown",
        content: (
          <div>
            <p style={{ color: "white" }}>
              You can select the type of search to be executed.
            </p>
          </div>
        ),
        style: {
          backgroundColor: "#1F3EAA",
          borderRadius: "4px",
        },
        action: () => {
          if (!this.state.visibleStep.searchAlgos) {
            this.setState({
              visibleStep: {
                searchType: false,
                searchClasses: false,
                searchAlgos: true,
              },
            });
          }
        },
      },
      {
        selector: ".algos-dropdown-menu",
        content: (
          <div>
            <p style={{ color: "white" }}>
              Here, you will have different types of searches available to get
              comprehensive data. While doing Trademark search, we recommend
              that always do 'Flash' search as it is the most exhaustive and
              comprehensive search created by us.
            </p>
          </div>
        ),
        style: {
          backgroundColor: "#1F3EAA",
          borderRadius: "4px",
        },
      },

      {
        selector: ".search-btn",
        content: (
          <div>
            <p style={{ color: "white" }}>
              Click on 'Search' to execute the search.
            </p>
          </div>
        ),
        style: {
          backgroundColor: "#1F3EAA",
          borderRadius: "4px",
        },
        action: () => {
          if (this.state.visibleStep.searchAlgos) {
            this.setState({
              visibleStep: {
                searchType: false,
                searchClasses: false,
                searchAlgos: false,
                tmSearch: true,
              },
            });
          }
          if (this.props[this.props.activeTab].results.length == 0)
            this.props.injectSampleData();
        },
      },
      {
        selector: ".search-filters",
        content: (
          <div>
            <p style={{ color: "white" }}>
              You can select any filter from here to narrow down the search
              results. These filters are created by collating all the data from
              different searches executed
            </p>
          </div>
        ),
        style: {
          backgroundColor: "#1F3EAA",
          borderRadius: "4px",
        },
      },
      {
        selector: ".General-Search-Content",
        content: (
          <div>
            <p style={{ color: "white" }}>
              This shows the results on the basis of the specific type of
              result. We recommend that you always go through the results shown
              in different options to see all kinds of similar marks to your
              searched mark.
            </p>
          </div>
        ),
        style: {
          backgroundColor: "#1F3EAA",
          borderRadius: "4px",
        },
      },

      {
        selector: ".search-card0",
        content: (
          <div>
            <p style={{ color: "white" }}>
              You will be able to see important details of the marks in this
              card.
            </p>
          </div>
        ),
        style: {
          backgroundColor: "#1F3EAA",
          borderRadius: "4px",
        },
      },
      {
        selector: ".search-card0app",
        content: (
          <div>
            <p style={{ color: "white" }}>
              Click on this application number to see details and all documents
              available on IP India for this application number.
            </p>
          </div>
        ),
        style: {
          backgroundColor: "#1F3EAA",
          borderRadius: "4px",
        },
      },

      {
        selector: ".search-card0check",
        content: (
          <div>
            <p style={{ color: "white" }}>
              Click on this check box in case you wish to select this mark to
              add to report.
            </p>
          </div>
        ),
        style: {
          backgroundColor: "#1F3EAA",
          borderRadius: "4px",
        },
      },

      {
        selector: ".search-create-report",
        content: (
          <div>
            <p style={{ color: "white" }}>
              Once all marks are selected then click on 'Create Report' to
              export them in PDF or Excel.
            </p>
          </div>
        ),
        style: {
          backgroundColor: "#1F3EAA",
          borderRadius: "4px",
        },
        action: () => {
          if (!this.state.reportExplicitTrigger1)
            this.setState({ reportExplicitTrigger1: true });
        },
      },
      {
        selector: ".search-report-drawer",
        content: (
          <div>
            <p style={{ color: "white" }}>
              You can select type of report, Urgent or Real time, and format of
              report, Excel or PDF from here. We recommend that you always
              create 'Real time' report as that will update selected marks and
              share report with the details as on the time of creation of the
              report.
            </p>
          </div>
        ),
        style: {
          backgroundColor: "#1F3EAA",
          borderRadius: "4px",
        },

        action: () => {
          if (!this.state.reportExplicitTrigger2)
            this.setState({ reportExplicitTrigger2: true });
        },
      },

      {
        selector: ".search-view-table",
        content: (
          <div>
            <p style={{ color: "white" }}>
              You can also view the 'Table View' if you prefer details in form
              of a list.
            </p>
          </div>
        ),
        style: {
          backgroundColor: "#1F3EAA",
          borderRadius: "4px",
        },

        action: () => {
          if (this.state.reportExplicitTrigger1)
            this.setState({
              reportExplicitTrigger1: false,
              reportExplicitTrigger1: false,
            });
          this.props.TPAction(TS_SELECT_VIEW, "2");
        },
      },

      {
        selector: ".search-gs-btn",
        content: (
          <div>
            <p style={{ color: "white" }}>
              In Table View, you can click on this '+' sign to see Goods and
              Services description of the marks in the dashboard itself.
            </p>
          </div>
        ),
        style: {
          backgroundColor: "#1F3EAA",
          borderRadius: "4px",
        },
      },

      {
        selector: ".search-analysis-btn",
        content: (
          <div>
            <p style={{ color: "white" }}>
              You can also see the graphical representation of the data in the
              Result Analysis.
            </p>
          </div>
        ),
        style: {
          backgroundColor: "#1F3EAA",
          borderRadius: "4px",
        },
        action: () => {
          this.props.TPAction(TS_SELECT_VIEW, "1");
        },
      },
      {
        content: (
          <div>
            <p style={{ color: "white" }}>
              We hope you have a wonderful experience while using MikeTM Search.
              In case you face any problem or have any feedback then please
              reach out to us at team@mikelegal.com or call us at
              +91-9958915302.
            </p>
          </div>
        ),
        style: {
          backgroundColor: "#1F3EAA",
          borderRadius: "4px",
        },
      },
    ];

    const SearchTour = (
      <Tour
        startAt={0}
        steps={steps}
        isOpen={this.state.isTourOpen}
        onRequestClose={() => {
          this.handleTourOpen(false);
        }}
        nextButton={<Button>Next</Button>}
        prevButton={
          <Button style={{ color: "white", display: "none" }} type="link">
            Back
          </Button>
        }
        disableDotsNavigation
        showNavigation={false}
        lastStepNextButton={<Button>Done</Button>}
        closeWithMask={false}
        badgeContent={(current, total) => `${current} of ${total}`}
        disableInteraction={true}
      />
    );
    return (
      <>
        <Layout className="Search">
          <Sider
            className="Sidebar"
            collapsible
            collapsed={this.state.collapsed}
            onCollapse={this.onCollapse}
          >
            <div
              className="Mikelegal"
              onClick={() => this.props.history.push("/")}
            >
              {this.state.collapsed ? "M" : "MikeLegal"}
            </div>
            {this.props.userProfile && (
              <div
                style={{
                  display: `${this.state.collapsed ? "none" : ""}`,
                  textAlign: "center",
                  color: "#ffffff",
                }}
              >
                <Tooltip
                  placement="right"
                  title="Click here to Upload/Change logo"
                >
                  <Avatar
                    size={80}
                    shape="circle"
                    style={{ margin: "10px" }}
                    className="avatar-logo"
                    onClick={() =>
                      this.changeStateValue_L1(
                        "modalsVisibility",
                        "uploadLogo",
                        true,
                      )
                    }
                  >
                    {this.props.userProfile.picture ? (
                      <img
                        src={this.props.userProfile.picture}
                        style={{ width: "100%" }}
                        alt="User"
                      />
                    ) : (
                      <UserOutlined />
                    )}
                  </Avatar>
                </Tooltip>
                <div className="Navbar-Profile-Details">
                  {this.props.userProfile.name}
                </div>
                <div className="Navbar-Profile-Details">
                  {this.props.userProfile.email}
                </div>
              </div>
            )}
            <Menu
              theme="dark"
              mode="vertical"
              selectedKeys={this.state.selectedKeys}
              onClick={this.handleMenuClick}
              style={searchMenuStyle}
            >
              {/* <Menu.Item key="2" onClick={() => this.props.history.push("/watch/dashboard")}>
                              <Icon type="desktop" />
                              <span>Dashboard</span>
                          </Menu.Item> */}
              <Menu.Item
                icon={<SearchOutlined />}
                key="/search"
                onClick={() => {
                  this.props.history.push(this.props.searchPath);
                  this.changeStateValue_L1(
                    "drawersVisibility",
                    "whatsNew",
                    false,
                  );
                }}
              >
                <span>Search</span>
              </Menu.Item>

              <Menu.Item
                icon={<UserOutlined />}
                key="/search/profile"
                onClick={() => {
                  this.props.history.push("/search/profile");
                  this.changeStateValue_L1(
                    "drawersVisibility",
                    "whatsNew",
                    false,
                  );
                }}
              >
                <span>Profile</span>
              </Menu.Item>
              <SubMenu
                key="reports"
                title={
                  <span>
                    <FolderOpenOutlined />
                    <span>Reports</span>
                  </span>
                }
              >
                <Menu.Item
                  key="/search/reports-management"
                  onClick={() => {
                    this.props.history.push("/search/reports-management");
                    this.changeStateValue_L1(
                      "drawersVisibility",
                      "whatsNew",
                      false,
                    );
                  }}
                >
                  Report Management
                </Menu.Item>
                <Menu.Item
                  key="/search/reports-download"
                  onClick={() => {
                    this.props.history.push("/search/reports-download");
                    this.changeStateValue_L1(
                      "drawersVisibility",
                      "whatsNew",
                      false,
                    );
                  }}
                >
                  Report Download
                </Menu.Item>
              </SubMenu>
              <Menu.Item
                icon={<MailOutlined />}
                key="/search/email-template"
                onClick={() => {
                  this.props.history.push("/search/email-template");
                  this.changeStateValue_L1(
                    "drawersVisibility",
                    "whatsNew",
                    false,
                  );
                }}
                className="ipsuite-menu-item"
              >
                <span>Email Template</span>
              </Menu.Item>
              <Menu.Item
                icon={<SettingOutlined />}
                key="/search/email-preferences"
                onClick={() => {
                  this.props.history.push("/search/email-preferences");
                  this.changeStateValue_L1(
                    "drawersVisibility",
                    "whatsNew",
                    false,
                  );
                }}
                className="ipsuite-menu-item"
              >
                <span>Email Preferences</span>
              </Menu.Item>
              <Menu.Item
                icon={<UsergroupAddOutlined />}
                key="/search/client-master"
                onClick={() => {
                  this.props.history.push("/search/client-master");
                  this.changeStateValue_L1(
                    "drawersVisibility",
                    "whatsNew",
                    false,
                  );
                }}
                className="ipsuite-menu-item"
              >
                <span>Client Master</span>
              </Menu.Item>
              <Menu.Item
                icon={
                  <GlobalOutlined
                    style={{
                      color:
                        this.state.selectedKeys[0] === "/search/whats-new"
                          ? "#fff"
                          : "#bebebe",
                    }}
                  />
                }
                key="/search/whats-new"
                onClick={() => {
                  this.changeStateValue_L1(
                    "drawersVisibility",
                    "whatsNew",
                    true,
                  );
                  document.cookie = "whatsNewSearch=false";
                }}
              >
                <Badge
                  dot={this.state.collapsed}
                  count={
                    document.cookie
                      .split("; ")
                      .find((row) => row.startsWith("whatsNewSearch"))
                      ? "0"
                      : "New"
                  }
                  style={{
                    top: `${this.state.collapsed ? "10px" : "7px"}`,
                    right: `${this.state.collapsed ? "-10px" : "-30px"}`,
                  }}
                >
                  <span
                    style={{
                      color:
                        this.state.selectedKeys[0] === "/search/whats-new"
                          ? "#fff"
                          : "#bebebe",
                    }}
                  >
                    What's New
                  </span>
                </Badge>
              </Menu.Item>

              <Menu.Item
                icon={<QuestionCircleOutlined />}
                key="/search/faqs"
                className="ipsuite-menu-item"
                onClick={() => {
                  this.props.history.push("/search/faqs");
                  this.changeStateValue_L1(
                    "drawersVisibility",
                    "whatsNew",
                    false,
                  );
                }}
              >
                <span>FAQs</span>
              </Menu.Item>

              {searchPageTours &&
                searchPageTours.includes(window.location.pathname) && (
                  <Menu.Item
                    icon={<QuestionCircleOutlined />}
                    key="pagTour"
                    onClick={() => {
                      introJs().start();
                    }}
                  >
                    <span>Page Tour</span>
                  </Menu.Item>
                )}
              <SubMenu
                icon={<SmileOutlined />}
                key="feedbacks"
                title={
                  <span>
                    <span>Feedback</span>
                  </span>
                }
              >
                <Menu.Item
                  key="customer-satisfaction"
                  onClick={() =>
                    this.changeStateValue_L1(
                      "modalsVisibility",
                      "CSSurvey",
                      true,
                    )
                  }
                >
                  Customer Satisfaction
                </Menu.Item>
                <Menu.Item
                  key="suggestions"
                  onClick={() =>
                    this.changeStateValue_L1(
                      "modalsVisibility",
                      "suggestions",
                      true,
                    )
                  }
                >
                  Suggestions
                </Menu.Item>
                <Menu.Item
                  key="complaints"
                  onClick={() =>
                    this.changeStateValue_L1(
                      "modalsVisibility",
                      "complaints",
                      true,
                    )
                  }
                >
                  Complaints
                </Menu.Item>
                <Menu.Item
                  key="queries-survey"
                  onClick={() =>
                    this.changeStateValue_L1(
                      "modalsVisibility",
                      "queriesSurvey",
                      true,
                    )
                  }
                >
                  Queries
                </Menu.Item>
              </SubMenu>
              <Menu.Item key="logout" onClick={this.props.logOut}>
                <LogoutOutlined />
                <span>Log Out</span>
              </Menu.Item>
            </Menu>
          </Sider>
          <Layout>
            {this.props.searchAlert.length > 0 && (
              <Alert
                type="error"
                message={
                  <div
                    style={{ textAlign: "center" }}
                    dangerouslySetInnerHTML={{ __html: this.props.searchAlert }}
                  ></div>
                }
              />
            )}
            {window.location.pathname === "/search/profile" ||
            window.location.pathname === "/search/reports-management" ||
            window.location.pathname === "/search/reports-download" ||
            window.location.pathname === "/search/email-template" ||
            window.location.pathname === "/search/email-preferences" ||
            window.location.pathname === "/search/client-master" ||
            window.location.pathname === "/search/faqs" ? (
              <></>
            ) : (
              <>
                <div
                  style={{
                    // display: "table",
                    width: "100%",
                    padding:
                      window.location.pathname === "/search/search-tips"
                        ? "64px"
                        : "16px",
                    textAlign: "center",
                    minHeight: `${
                      window.location.pathname === "/search/search-tips"
                        ? "32vh"
                        : "0px"
                    }`,
                    backgroundColor: `${
                      window.location.pathname === "/search/search-tips"
                        ? "#4285f429"
                        : "#f5f5f5"
                    }`,
                  }}
                >
                  {window.location.pathname === "/search/search-tips" && (
                    <>
                      <div style={{ display: "flex" }}>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            textAlign: "left",
                          }}
                        >
                          <div style={{ fontSize: "2rem", fontWeight: "bold" }}>
                            <MikeTyped text={["Future of Trademarks Search"]} />

                            {/* <strong>Future Of Trademarks Search</strong> */}
                          </div>
                          <div style={{ color: "grey", fontSize: "18px" }}>
                            <p>
                              Unlock Your Brand's Potential: Advanced Search for
                              Text, Applications, Proprietors & Visual
                              Trademarks
                            </p>
                          </div>
                        </div>
                        <div style={{ marginLeft: "auto" }}>
                          <Lottie
                            options={defaultOptions}
                            height={320}
                            width={320}
                            style={{ margin: "-120px auto" }}
                          />
                        </div>
                        <div style={{ margin: "-36px auto" }}>
                          <CheckableTagComponent
                            label={<PlaySquareOutlined />}
                            value={this.state.isTourOpen}
                            setValue={(v) => {
                              this.props.TPAction(
                                SET_SEARCH_PATH,
                                "/search/trademarks-search",
                              );

                              this.props.handleSearchInfoDispatch({
                                trademarksSearchAlgo: "Flash",
                                activeTab: "Fuzzy",
                                userTrademark: { id: 1, term: "mikelegal" },
                                multiwordTrademark: false,
                                axiosCancelSearches: null,
                                searchCounter: 6,
                                reminderStamp:
                                  new Date().getTime() + refreshPeriod,
                                searchStamp:
                                  new Date().getTime() + refreshPeriod,
                              });
                              this.props.history.push(
                                "/search/trademarks-search",
                              );
                              this.setState({
                                isTourOpen: v,
                              });
                            }}
                            disableTooltip="Take Tour"
                            activeTooltip="Take Tour"
                          />
                        </div>
                      </div>
                    </>
                  )}
                </div>
                <header
                  className={
                    this.state.selectedFilters.searchType === "Image_Search"
                      ? "Search-Bar-Big"
                      : "Search-Bar"
                  }
                  style={{
                    bottom:
                      window.location.pathname === "/search/search-tips"
                        ? "42px"
                        : "20px",
                  }}
                >
                  <Dropdown
                    {...(this.state.isTourOpen
                      ? { open: this.state.visibleStep?.searchType }
                      : {})}
                    overlay={searchTypesMenu}
                    className="search-type-dropdown"
                  >
                    <Button style={headerButtonSelected}>
                      {this.state.selectedFilters.searchType === "Image_Search"
                        ? "Image Search"
                        : this.state.selectedFilters.searchType}
                      <DownOutlined style={{ fontSize: "12px" }} />
                    </Button>
                  </Dropdown>
                  {this.state.selectedFilters.searchType === "Trademarks" && (
                    <>
                      <Dropdown
                        dropdownRender={() => userTrademarksMenu}
                        open={this.state.open === "userTrademarks"}
                        autoAdjustOverflow
                        onOpenChange={(flag) => {
                          this.changeStateValue_L1_Debounced(
                            "filtersVisibility",
                            "userTrademarks",
                            flag,
                          );
                          this.handleOpenChange(flag, "userTrademarks");
                        }}
                      >
                        <Input
                          // suffix={
                          //   this.props.userTrademarksStatus === "LOADING" ? (
                          //     <Spin indicator={<LoadingOutlined spin />} />
                          //   ) : null
                          // }
                          className="search-input"
                          placeholder="Select trademark"
                          onFocus={() =>
                            this.changeStateValue_L1(
                              "filtersVisibility",
                              "userTrademarks",
                              true,
                            )
                          }
                          onBlur={() =>
                            this.changeStateValue_L1_Debounced(
                              "filtersVisibility",
                              "userTrademarks",
                              false,
                            )
                          }
                          value={
                            this.state.isTourOpen
                              ? "Mikelegal"
                              : this.state.filtersSearchTerms.userTrademarks
                          }
                          onChange={(event) => {
                            if (
                              !event.target.value.includes("  ") &&
                              !event.target.value.includes("#") &&
                              !event.target.value.includes("\\") &&
                              !event.target.value.includes("/")
                            ) {
                              this.changeStateValue_L1(
                                "filtersSearchTerms",
                                "userTrademarks",
                                event.target.value,
                              );
                              this.handleSearchInput(event);
                              this.setState({
                                selectedFilters: {
                                  ...this.state.selectedFilters,
                                  queries: {
                                    ...this.state.selectedFilters.queries,
                                    Boolean: event.target.value
                                      .replace(/[&|!'"()$%_^]/g, "")
                                      .replace(/ {1,}/g, " ")
                                      .trim()
                                      .replace(/ /g, "[[&]]"),
                                    Wildcard: `[[*]]${event.target.value
                                      .replace(/[&|!'"()$%_^]/g, "")
                                      .replace(/ {1,}/g, " ")
                                      .trim()
                                      .replace(/ /g, "[[*]]")}[[*]]`,
                                  },
                                },
                                filtersSearchTerms: {
                                  ...this.state.filtersSearchTerms,
                                  userTrademarks: event.target.value,
                                },
                              });
                            }
                          }}
                          style={{
                            width: "16vw",
                            border: "none",
                            margin: "0px 3px 0px 0px",
                            fontSize: "18px",
                            height:
                              this.state.selectedFilters.searchType ===
                              "Image_Search"
                                ? "116px"
                                : "inherit",
                          }}
                        />
                      </Dropdown>
                      <Dropdown
                        overlay={classesMenu}
                        className="search-classes"
                        open={
                          this.state.filtersVisibility.classes ||
                          this.state.visibleStep?.searchClasses
                        }
                        onVisibleChange={(event) =>
                          this.changeStateValue_L1(
                            "filtersVisibility",
                            "classes",
                            event,
                          )
                        }
                      >
                        <Button
                          style={
                            this.state.selectedFilters.classes.length > 0
                              ? headerButtonSelected
                              : headerButton
                          }
                        >
                          Classes
                          <DownOutlined />
                        </Button>
                      </Dropdown>
                      {/* <SelectContainer
                        filteredData={this.props.searchFilters.classConfig}
                        key={this.props.searchFilters.classConfig.key}
                        loading={this.props.searchFilters.classConfig.loading}
                        hideExclude={true}
                      /> */}
                      <Dropdown
                        overlay={algosMenu}
                        className="algos-dropdown"
                        open={
                          this.state.filtersVisibility.trademarksSearchAlgos ||
                          this.state.visibleStep?.searchAlgos
                        }
                        onVisibleChange={(event) =>
                          this.changeStateValue_L1(
                            "filtersVisibility",
                            "trademarksSearchAlgos",
                            event,
                          )
                        }
                      >
                        <Button style={headerButtonSelected}>
                          {this.state.selectedFilters.trademarksSearchAlgo}
                          <DownOutlined />
                        </Button>
                      </Dropdown>
                      {(this.state.selectedFilters.trademarksSearchAlgo ===
                        "Boolean" ||
                        this.state.selectedFilters.trademarksSearchAlgo ===
                          "Wildcard") && (
                        <Input
                          onChange={(event) => {
                            if (
                              this.state.selectedFilters
                                .trademarksSearchAlgo === "Wildcard" &&
                              event.target.value.search(/[%_]/g) === -1
                            ) {
                              this.changeStateValue_L2(
                                "selectedFilters",
                                "queries",
                                this.state.selectedFilters.trademarksSearchAlgo,
                                event.target.value.trim(),
                              );
                            }
                            if (
                              this.state.selectedFilters
                                .trademarksSearchAlgo === "Boolean"
                            ) {
                              this.changeStateValue_L2(
                                "selectedFilters",
                                "queries",
                                this.state.selectedFilters.trademarksSearchAlgo,
                                event.target.value.trim(),
                              );
                            }
                          }}
                          value={
                            this.state.selectedFilters.queries[
                              this.state.selectedFilters.trademarksSearchAlgo
                            ]
                          }
                          placeholder="Enter your query"
                          style={{
                            width: "200px",
                            border: "none",
                            margin: "0px 3px 0px 0px",
                            fontSize: "16px",
                            height:
                              this.state.selectedFilters.searchType ===
                              "Image_Search"
                                ? "116px"
                                : "inherit",
                          }}
                        />
                      )}
                      {this.state.selectedFilters.trademarksSearchAlgo ===
                        "Prefix" && (
                        <>
                          <InputNumber
                            onChange={(value) => {
                              if (Number.isInteger(value)) {
                                this.changeStateValue_L2(
                                  "selectedFilters",
                                  "queries",
                                  "Prefix",
                                  value,
                                );
                              }
                            }}
                            value={this.state.selectedFilters.queries.Prefix}
                            placeholder="Enter Prefix depth"
                            style={{
                              padding: "4px",
                              width: "120px",
                              border: "none",
                              margin: "0px 3px 0px 0px",
                              fontSize: "16px",
                              height:
                                this.state.selectedFilters.searchType ===
                                "Image_Search"
                                  ? "116px"
                                  : "inherit",
                            }}
                          />
                          <HighlightPrefix
                            text={this.state.filtersSearchTerms.userTrademarks}
                            highlightLength={
                              this.state.selectedFilters.queries.Prefix
                            }
                          />
                        </>
                      )}
                      {this.state.selectedFilters.trademarksSearchAlgo ===
                        "Suffix" && (
                        <>
                          <InputNumber
                            onChange={(value) => {
                              if (Number.isInteger(value)) {
                                this.changeStateValue_L2(
                                  "selectedFilters",
                                  "queries",
                                  "Suffix",
                                  value,
                                );
                              }
                            }}
                            value={this.state.selectedFilters.queries.Suffix}
                            placeholder="Enter Suffix depth"
                            style={{
                              padding: "4px",
                              width: "120px",
                              border: "none",
                              margin: "0px 3px 0px 0px",
                              fontSize: "16px",
                              height:
                                this.state.selectedFilters.searchType ===
                                "Image_Search"
                                  ? "116px"
                                  : "inherit",
                            }}
                          />
                          <HighlightPrefix
                            text={this.state.filtersSearchTerms.userTrademarks}
                            highlightLength={
                              this.state.selectedFilters.queries.Suffix
                            }
                            suffix
                          />
                        </>
                      )}
                      {this.state.selectedFilters.trademarksSearchAlgo ===
                        "Boolean" && (
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          <Tooltip title="And">
                            <Button
                              onClick={() =>
                                this.changeStateValue_L2(
                                  "selectedFilters",
                                  "queries",
                                  "Boolean",
                                  this.state.selectedFilters.queries.Boolean.concat(
                                    "[[&]]",
                                  ),
                                )
                              }
                              type="link"
                            >
                              {"[[&]]"}
                            </Button>
                          </Tooltip>
                          <Tooltip title="Or">
                            <Button
                              onClick={() =>
                                this.changeStateValue_L2(
                                  "selectedFilters",
                                  "queries",
                                  "Boolean",
                                  this.state.selectedFilters.queries.Boolean.concat(
                                    "[[|]]",
                                  ),
                                )
                              }
                              type="link"
                            >
                              {"[[|]]"}
                            </Button>
                          </Tooltip>
                          <Tooltip title="Not">
                            <Button
                              onClick={() =>
                                this.changeStateValue_L2(
                                  "selectedFilters",
                                  "queries",
                                  "Boolean",
                                  this.state.selectedFilters.queries.Boolean.concat(
                                    "[[!]]",
                                  ),
                                )
                              }
                              type="link"
                            >
                              {"[[!]]"}
                            </Button>
                          </Tooltip>
                        </div>
                      )}
                      {this.state.selectedFilters.trademarksSearchAlgo ===
                        "Wildcard" && (
                        <>
                          <Tooltip title="Multiple characters">
                            <Button
                              onClick={() =>
                                this.changeStateValue_L2(
                                  "selectedFilters",
                                  "queries",
                                  "Wildcard",
                                  this.state.selectedFilters.queries.Wildcard.concat(
                                    "[[*]]",
                                  ),
                                )
                              }
                              type="link"
                            >
                              {"[[*]]"}
                            </Button>
                          </Tooltip>
                          <Tooltip title="Single character">
                            <Button
                              onClick={() =>
                                this.changeStateValue_L2(
                                  "selectedFilters",
                                  "queries",
                                  "Wildcard",
                                  this.state.selectedFilters.queries.Wildcard.concat(
                                    "[[?]]",
                                  ),
                                )
                              }
                              type="link"
                            >
                              {"[[?]]"}
                            </Button>
                          </Tooltip>
                        </>
                      )}
                    </>
                  )}
                  {this.state.selectedFilters.searchType === "Proprietors" && (
                    <>
                      <Input
                        onChange={(event) =>
                          this.changeStateValue_L1_Debounced(
                            "selectedFilters",
                            "proprietor",
                            event.target.value,
                          )
                        }
                        style={{
                          width: "16vw",
                          border: "none",
                          margin: "0px 3px 0px 0px",
                          fontSize: "18px",
                          height:
                            this.state.selectedFilters.searchType ===
                            "Image_Search"
                              ? "116px"
                              : "inherit",
                        }}
                        placeholder="Search proprietors"
                      />
                      <Dropdown overlay={proprietorsSearchTypesMenu}>
                        <Button style={headerButtonSelected}>
                          {this.state.selectedFilters.proprietorsSearchType}
                          <DownOutlined style={{ fontSize: "12px" }} />
                        </Button>
                      </Dropdown>
                    </>
                  )}
                  {this.state.selectedFilters.searchType === "Application" && (
                    <Input
                      onChange={(event) =>
                        this.changeStateValue_L1_Debounced(
                          "selectedFilters",
                          "application",
                          event.target.value,
                        )
                      }
                      placeholder="Enter application"
                      style={{
                        width: "16vw",
                        border: "none",
                        margin: "0px 3px 0px 0px",
                        fontSize: "18px",
                        height:
                          this.state.selectedFilters.searchType ===
                          "Image_Search"
                            ? "116px"
                            : "inherit",
                      }}
                    />
                  )}
                  {this.state.selectedFilters.searchType === "G&S" && (
                    <>
                      <Dropdown
                        dropdownRender={() => userTrademarksMenu}
                        open={this.state.open === "userTrademarks"}
                        autoAdjustOverflow
                        onOpenChange={(flag) => {
                          this.changeStateValue_L1_Debounced(
                            "filtersVisibility",
                            "userTrademarks",
                            flag,
                          );
                          this.handleOpenChange(flag, "userTrademarks");
                        }}
                      >
                        <Input
                          onChange={(event) => {
                            if (
                              !event.target.value.includes("  ") &&
                              !event.target.value.includes("#") &&
                              !event.target.value.includes("\\") &&
                              !event.target.value.includes("/")
                            ) {
                              this.changeStateValue_L1(
                                "filtersDescriptionSearch",
                                "descriptionSearch",
                                event.target.value,
                              );
                              this.handleSearchInput(event);

                              this.setState({
                                filtersDescriptionSearch: {
                                  ...this.state.filtersDescriptionSearch,
                                  descriptionSearch: event.target.value,
                                },
                              });
                            }
                          }}
                          value={
                            this.state.filtersDescriptionSearch
                              ?.descriptionSearch
                              ? this.state.filtersDescriptionSearch
                                  ?.descriptionSearch
                              : this.props.GOODS_SERVICE?.searchParameters
                                    ?.search_query
                                ? this.props.GOODS_SERVICE?.searchParameters
                                    ?.search_query
                                : ""
                          }
                          placeholder="Enter G&S Description"
                          style={{
                            width: "24vw",
                            border: "none",
                            margin: "0px 3px 0px 0px",
                            fontSize: "18px",
                            height:
                              this.state.selectedFilters.searchType ===
                              "Image_Search"
                                ? "116px"
                                : "inherit",
                          }}
                        />
                      </Dropdown>
                      <Dropdown
                        overlay={classesMenu}
                        open={this.state.filtersVisibility.classes}
                        onVisibleChange={(event) =>
                          this.changeStateValue_L1(
                            "filtersVisibility",
                            "classes",
                            event,
                          )
                        }
                      >
                        <Button
                          style={
                            this.state.selectedFilters.classes.length > 0
                              ? headerButtonSelected
                              : headerButton
                          }
                        >
                          Classes
                          <DownOutlined />
                        </Button>
                      </Dropdown>
                      {/* <SelectContainer
                        filteredData={this.props.searchFilters.classConfig}
                        key={this.props.searchFilters.classConfig.key}
                        loading={this.props.searchFilters.classConfig.loading}
                        hideExclude={true}
                      /> */}
                    </>
                  )}
                  {this.state.selectedFilters.searchType === "Image_Search" && (
                    <>
                      {ImageUploader}
                      <Dropdown
                        overlay={classesMenu}
                        open={this.state.filtersVisibility.classes}
                        onVisibleChange={(event) =>
                          this.changeStateValue_L1(
                            "filtersVisibility",
                            "classes",
                            event,
                          )
                        }
                      >
                        <Button
                          style={
                            this.state.selectedFilters.classes.length > 0
                              ? headerButtonSelected
                              : headerButton
                          }
                        >
                          Classes
                          <DownOutlined />
                        </Button>
                      </Dropdown>
                      {/* <SelectContainer
                        filteredData={this.props.searchFilters.classConfig}
                        key={this.props.searchFilters.classConfig.key}
                        loading={this.props.searchFilters.classConfig.loading}
                        hideExclude={true}
                      /> */}
                    </>
                  )}
                  <Button
                    // className={
                    //   this.state.selectedFilters.searchType === "Image_Search"
                    //     ? "Search-Btn-Big"
                    //     : "Search-Btn"
                    // }
                    className="search-btn"
                    style={
                      this.state.selectedFilters.searchType === "Image_Search"
                        ? {
                            marginLeft: "auto",
                            border: "none",
                            color: "#ffffff",
                            backgroundColor: "#4285f4",
                            borderRadius: "0px",
                            height: "128px",
                            fontSize: "16px",
                            minWidth: "8vw",
                          }
                        : {
                            marginLeft: "auto",
                            border: "none",
                            color: "#ffffff",
                            backgroundColor: "#4285f4",
                            borderRadius: "0px",
                            height: "42px",
                            fontSize: "16px",
                          }
                    }
                    // disabled={
                    //   this.props.quota &&
                    //   this.props.quota.left_quota === 0 &&
                    //   this.state.selectedFilters.searchType === "Trademarks"
                    // }
                    onClick={() => this.handleSearch()}
                    loading={
                      this.props.searchStatus === LOADING ||
                      this.props.userTrademarksStatus === LOADING
                    }
                  >
                    Search
                  </Button>
                </header>
                {SearchTour}
              </>
            )}
            <Layout>
              <Switch>
                <Route
                  exact
                  path="/search/search-tips"
                  component={SearchTips}
                />
                <Route
                  exact
                  path="/search/image_search"
                  render={(props) => (
                    <ImageSearch
                      {...props}
                      searchType={this.state.selectedFilters.searchType}
                    />
                  )}
                />
                <Route
                  exact
                  path="/search/trademarks-search"
                  render={(props) => (
                    <TrademarksSearch
                      {...props}
                      searchType={this.state.selectedFilters.searchType}
                      isTourOpen={this.state.isTourOpen}
                      reportExplicitTrigger1={this.state.reportExplicitTrigger1}
                      reportExplicitTrigger2={this.state.reportExplicitTrigger2}
                    />
                  )}
                />
                <Route
                  exact
                  path="/search/description-search"
                  render={(props) => (
                    <TrademarksSearch
                      {...props}
                      searchType={this.state.selectedFilters.searchType}
                    />
                  )}
                />
                <Route
                  exact
                  path="/search/description-search"
                  render={(props) => (
                    <TrademarksSearch
                      {...props}
                      searchType={this.state.selectedFilters.searchType}
                    />
                  )}
                />
                <Route
                  exact
                  path="/search/proprietors-search"
                  component={ProprietorsSearch}
                />
                <Route
                  exact
                  path="/search/application-search"
                  component={TrademarkProfile}
                />
                <Route
                  exact
                  path="/search/trademark-profile"
                  component={TrademarkProfile}
                />
                <Route
                  exact
                  path="/search/proprietor-profile/:text"
                  component={ProprietorProfile}
                />
                <Route
                  exact
                  path="/search/profile"
                  component={ProfileDashboard}
                />
                <Route exact path="/search/faqs" component={Faqs} />
                <Route
                  exact
                  path="/search/reports-management"
                  component={ReportManagement}
                />
                <Route
                  exact
                  path="/search/reports-download"
                  component={ReportDownload}
                />
                <Route
                  exact
                  path="/search/email-template"
                  component={EmailTemplate}
                />
                <Route
                  exact
                  path="/search/email-preferences"
                  component={EmailPreferences}
                />
                <Route
                  exact
                  path="/search/client-master"
                  component={ClientMaster}
                />
                <Route component={NoMatch} />
              </Switch>
            </Layout>
          </Layout>
        </Layout>

        <UploadProfileLogo
          visible={this.state.modalsVisibility.uploadLogo}
          onCloseModal={() =>
            this.changeStateValue_L1("modalsVisibility", "uploadLogo", false)
          }
        />

        {/* This Modal will open when the question mark in trademarks algos filter is clicked */}
        <TrademarksAlgosInfo
          visible={this.state.modalsVisibility.trademarksAlgosInfo}
          onClose={() =>
            this.changeStateValue_L1(
              "modalsVisibility",
              "trademarksAlgosInfo",
              false,
            )
          }
        />

        {this.state.drawersVisibility.whatsNew && (
          <WhatsNew
            visible={this.state.drawersVisibility.whatsNew}
            onCloseDrawer={() =>
              this.changeStateValue_L1("drawersVisibility", "whatsNew", false)
            }
            collapsed={this.state.collapsed}
          />
        )}

        {/* <Drawer
          title={<h2>IP India Report Details</h2>}
          placement="right"
          width={400}
          closable={true}
          open={this.state.drawersVisibility.ipIndiaReport}
          onClose={() =>
            this.changeStateValue_L1(
              "drawersVisibility",
              "ipIndiaReport",
              false
            )
          }
        >
          <form>
            Enter the name of your report:
            <br />
            <Input
              onChange={(event) => {
                if (/^[ \w!?:_\-()@#,;<>[\]{}|]*$/g.test(event.target.value)) {
                  this.changeStateValue_L1(
                    "ipIndiaReportObject",
                    "report_file_name",
                    event.target.value
                  );
                }
              }}
              value={this.state.ipIndiaReportObject.report_file_name}
              placeholder="Report's name (Optional)"
            />
            <br />
            <br />
            {this.props.reportAvailabilityStatus === SUCCESS &&
              this.props.reportAvailability &&
              this.props.reportAvailability.id && (
                <>
                  Do you want to clear previous data:
                  <br />
                  <Radio.Group
                    value={this.state.ipIndiaReportObject.clear}
                    onChange={(event) =>
                      this.changeStateValue_L1(
                        "ipIndiaReportObject",
                        "clear",
                        event.target.value
                      )
                    }
                  >
                    <Radio value={true}>Yes</Radio>
                    <br />
                    <Radio value={false}>No</Radio>
                  </Radio.Group>
                  <br />
                  <br />
                </>
              )}
            Do you want to download the Excel file:
            <br />
            <Radio.Group
              value={this.state.ipIndiaReportObject.report_download}
              onChange={(event) =>
                this.changeStateValue_L1(
                  "ipIndiaReportObject",
                  "report_download",
                  event.target.value
                )
              }
            >
              <Radio value={true}>Yes</Radio>
              <br />
              <Radio value={false}>No</Radio>
            </Radio.Group>
            <br />
            <br />
            Choose your preferred report logic:
            <br />
            <Checkbox
              checked={this.state.ipIndiaReportObject.report_logic?.includes(
                "Word"
              )}
              onChange={(event) => {
                if (event.target.checked) {
                  this.changeStateValue_L1(
                    "ipIndiaReportObject",
                    "report_logic",
                    [...this.state.ipIndiaReportObject.report_logic, "Word"]
                  );
                } else {
                  this.changeStateValue_L1(
                    "ipIndiaReportObject",
                    "report_logic",
                    this.state.ipIndiaReportObject.report_logic?.filter(
                      (e) => e !== "Word"
                    )
                  );
                }
              }}
            >
              Word (Contains)
            </Checkbox>
            <br />
            <Checkbox
              checked={this.state.ipIndiaReportObject.report_logic.includes(
                "Phonetic"
              )}
              onChange={(event) => {
                if (event.target.checked) {
                  this.changeStateValue_L1(
                    "ipIndiaReportObject",
                    "report_logic",
                    [...this.state.ipIndiaReportObject.report_logic, "Phonetic"]
                  );
                } else {
                  this.changeStateValue_L1(
                    "ipIndiaReportObject",
                    "report_logic",
                    this.state.ipIndiaReportObject.report_logic?.filter(
                      (e) => e !== "Phonetic"
                    )
                  );
                }
              }}
            >
              Phonetic
            </Checkbox>
            <br />
            <br />
            <Alert
              type="warning"
              message="Note: The report created will be on the basis of information provided on the public search page."
            />
            <br />
            <br />
            <Button
              type="primary"
              ghost
              rootStyle={{ marginRight: "10px" }}
              onClick={() => this.handleConfirmReport_Debounced()}
              disabled={
                this.props.reportAvailabilityStatus !== SUCCESS ||
                (this.props.reportAvailability &&
                  this.props.reportAvailability.id &&
                  this.props.reportAvailability.available === false)
              }
            >
              Confirm
            </Button>
            <Button
              type="danger"
              ghost
              onClick={() =>
                this.changeStateValue_L1(
                  "drawersVisibility",
                  "ipIndiaReport",
                  false
                )
              }
            >
              Cancel
            </Button>
          </form>
        </Drawer> */}

        {/* TM-Search customer satisfaction survey. */}
        {/* <SurveyModal
                  title="customerSatisfactionSearch"
                  src="https://s.surveyplanet.com/nkBHU9nKb"
                  visible={this.state.modalsVisibility.CSSurvey}
                  maskClosable={false}
                  onCancel={() => {
                      document.cookie = `Search_CSSurvey=;max-age=${60 * 60 * 24 * 20}`;
                      this.changeStateValue_L1("modalsVisibility", "CSSurvey", false);
                  }}
              /> */}

        {/* Suggestions survey. */}
        {/* <SurveyModal
                  title="suggestions"
                  src="https://s.surveyplanet.com/1eI4bBov0"
                  visible={this.state.modalsVisibility.suggestions}
                  maskClosable={true}
                  onCancel={() => this.changeStateValue_L1("modalsVisibility", "suggestions", false)}
              /> */}

        {/* Complaints survey */}
        {/* <SurveyModal
                  title="complaints"
                  src="https://s.surveyplanet.com/7597Miojp"
                  visible={this.state.modalsVisibility.complaints}
                  maskClosable={true}
                  onCancel={() => this.changeStateValue_L1("modalsVisibility", "complaints", false)}
              /> */}

        {/* Queries survey */}
        {/* <SurveyModal
                  title="queriesSurvey"
                  src="https://s.surveyplanet.com/JlqHxcfFh"
                  visible={this.state.modalsVisibility.queriesSurvey}
                  maskClosable={true}
                  onCancel={() => this.changeStateValue_L1("modalsVisibility", "queriesSurvey", false)}
              /> */}

        {/* Image Recognition */}
        {/* <ImageRecognition
                  visible={this.state.modalsVisibility.imageRecognition}
                  onCloseModal={() => this.changeStateValue_L1("modalsVisibility", "imageRecognition", false)}
              /> */}
      </>
    );
  }
}

const mapDispatchToProps = {
  getProfile,
  logOut,
  getUserSubscriptions,
  getUserTrademarksInSearch,
  handleSearchInfoDispatch,
  getSearchResults,
  postUserTrademark,
  getTrademarkProfileData,
  getImageSearchResults,
  TPAction,
  SetFileAction,
  getTrademarkReportStatus,
  getProprietorsSearch,
  getAlertMessage,
  resetAllSearchFilter,
  injectSampleData,
  // getSearchTrademarks,
};

function mapStateToProps(state) {
  const { userSubscriptions, userSubscriptionStatus, userDetails } =
    state.userSubscriptions;
  const { userProfile, userProfileStatus } = state.profile;

  const {
    quota,
    userTrademarks,
    userTrademarksCount,
    userTrademarksPage,
    userTrademarksStatus,
    searchPath,
    reportAvailabilityStatus,
    reportAvailability,

    // searchUserTrademarks,
    // searchUserTrademarksStatus,
  } = state.searchState.searchBar;

  const {
    axiosCancelSearches,
    axiosCancelAnalysis,
    axiosCancelFilters,
    searchStatus,
    Fuzzy,
    Contains,
    Wildcard,
    Phonetic,
    Initials,
    Boolean,
    Prefix,
    Suffix,
    ImageSearch,
    activeTab,
  } = state.searchState.trademarksSearch;
  const { searchFilters } = state.searchState;
  return deepFreeze({
    userDetails,
    userSubscriptions,
    userSubscriptionStatus,
    userProfile,
    userProfileStatus,
    quota,
    userTrademarks,
    userTrademarksCount,
    userTrademarksPage,
    userTrademarksStatus,
    reportAvailabilityStatus,
    reportAvailability,
    searchPath,
    axiosCancelSearches,
    axiosCancelAnalysis,
    axiosCancelFilters,
    proprietorsSearchStatus:
      state.searchState.proprietorsSearch.proprietorsSearchStatus,
    searchAlert: state.alertMessages.search,
    searchStatus,
    searchFilters,
    resetAllSearchFilter,
    // searchUserTrademarks,
    // searchUserTrademarksStatus,

    Fuzzy,
    Contains,
    Wildcard,
    Phonetic,
    Initials,
    Boolean,
    Prefix,
    Suffix,
    ImageSearch,
    activeTab,
  });
}

export default connect(mapStateToProps, mapDispatchToProps)(Search);
