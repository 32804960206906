import React, { Component } from "react";
import { CarryOutTwoTone, MessageTwoTone } from "@ant-design/icons";
import {
  Row,
  Col,
  Card,
  Tooltip,
  Typography,
  Switch,
  Empty,
  Table,
} from "antd";

import { checkValue } from "../../../../functions/commonFunctions/checkValueFunctions";
import { getReplyToCorrespondence } from "../../../../redux/actions/managerActions/trademarkDashboard/actions";
import { connect } from "react-redux";
import deepFreeze from "deep-freeze";
import ReplyFiledModal from "../../../managerComponents/trademark/ReplyFiled";
import { REPLY_FILED_TO_CORRESPONDENCE } from "../../../../redux/actions/managerActions/trademarkDashboard/types";
import {
  changeStateValue_L0,
  changeStateValue_L1,
  changeStateValue_L2,
} from "../../../../functions/commonFunctions/generalFunctions";

import ManagerNotes from "../../subComponents/Modal/managerNotes";
import CreateTask from "../../to-do-task/CreateTask";
//Actions
import {
  POST_TM_NOTES,
  PATCH_TM_NOTES,
} from "../../../../redux/actions/managerActions/commonAction/types";
//Constants
import { TM } from "../../../../constants/managerConstants/managerConstants";
import SendEmailNew from "../../../commonComponents/Modals/SendEmailNew";

const { Text } = Typography;

class CorrespondenceTable extends Component {
	constructor(props) {
		super(props);
		this.state = {
			filterDocDOD: "doc_name",
			filterRemainingDate: "remaining_days",
			modalsVisibility: {
				sendEmail: false,
				mailContent: "",
				report_tag: {},
			},
		};
		this.changeStateValue_L2 = changeStateValue_L2.bind(this);
		this.changeStateValue_L1 = changeStateValue_L1.bind(this);
		this.changeStateValue_L0 = changeStateValue_L0.bind(this);
	}

	handlePdfClick(data) {
		let url = "";
		if (data?.file) {
			url = data?.file;
		} else {
			url = data?.url;
		}
		if (url !== null) window.open(url, "_blank");
	}

	handlePdfClickExam(data) {
		let url = "";
		if (data?.url.includes("http://")) {
			url = data?.url;
		} else {
			url = data?.url;
		}
		if (url !== null) window.open(url, "_blank");
	}

  reRender() {
		let id = sessionStorage.getItem("management_trademark_id");
    if(this.props?.correspondenceType === "OPPOSED REPLY TO CORRESPONDENCE"){
      this.props.getReplyToCorrespondence({
        management_mark_id: id,
        mmType: "opposed",
      });
    }else{
      this.props.getReplyToCorrespondence({
        management_mark_id: id,
      });
    }
	}
  
	componentDidMount() {
    this.reRender()
  }

	replyFiled(data) {
		this.setState({
			add_trademark_id: [data.id],
			replyFiledVisible: true,
			pending_reply: false,
      replyFiledTrademark: data,
		});
	}

	handleManagerNotes(data) {
		this.setState({
			managerNotes: true,
			application_number: data.application_number,
		});
	}
  
	handleEmail(modalsVisibility, modal, value, data) {
		let mailContent = "<p><strong>Trademark Manager</strong></p>";
		this.setState({
			[modalsVisibility]: {
				...this.state[modalsVisibility],
				[modal]: value,
				mailContent: mailContent,
				product: "MANAGER",
				subscription: [
					this.state.changeSwitch ? "PSEUDO_TRADEMARK" : "TRADEMARK",
				],
				report_tag: {
					journal_number: checkValue(data.journal) ? data.journal.number : "",
					journal_date: checkValue(data.journal)
						? data.journal.date_of_publication
						: "",
					status: checkValue(data.status) ? data.status : "",
					renewal_date: checkValue(data.valid_upto) ? data.valid_upto : "",
					application_number: checkValue(data.application_number)
						? data.application_number
						: "",
					mark_name: checkValue(data.applied_for) ? data.applied_for : "",
					date_of_application: checkValue(data.application_date)
						? data.application_date
						: "",
					class: checkValue(data.associated_class)
						? data.associated_class.join(", ")
						: "",
					date_of_usage: checkValue(data.date_of_usage)
						? data.date_of_usage
						: "",
					proprietor_name: checkValue(data.proprietor)
						? data.proprietor.map((prop) => prop.name).join(", ")
						: "",
					opponent_name: checkValue(data.opponent_name)
						? data.opponent_name
						: "",
					tla_hearing_date: "",
					opposition_hearing_date: "",
					report_name: "",
				},
				objectType: this.state.changeSwitch ? "pseudo_trademark" : "trademark",
				applicationNumber: data.application_number,
				markId: data.management_mark_id,
				sourceId: data.management_mark_id,
			},
		});
	}

	render() {
		const {
			correspondenceData,
			correspondenceType,
			customProfile,
			changeSwitch,
		} = this.props;

		const totalReplyToCorrespondenceColumns = [
			{
				title: "STATUS | APPLICATION DATE | CLASS",
				className: "reply-to-correspondence-column-2",
				render: (text, record) => {
					return (
						<div>
							<p className="text-camelCase">
								{checkValue(record.status)
									? record.status.toLowerCase()
									: "N.A."}
							</p>
							<p className="text-camelCase">
								{checkValue(record.application_date)
									? record.application_date
									: "N.A."}
							</p>
							<p className="text-camelCase">
								{checkValue(record.associated_class)
									? record.associated_class.join(", ")
									: "N.A."}
							</p>
						</div>
					);
				},
			},

			{
				title: "OPPONENT NAME | OPPONENT NUMBER",
				className: "reply-to-correspondence-column-2",
				render: (text, record) => {
					return (
						<div>
							<span className="text-camelCase">
								{checkValue(record.opponent_name)
									? record.opponent_name.toLowerCase()
									: "N.A."}
							</span>
							{record.opponent_number && (
								<p>
									(
									{checkValue(record.opponent_number)
										? record.opponent_number
										: "N.A."}
									)
								</p>
							)}
						</div>
					);
				},
			},

			{
				title: "DOCUMENT | TIME REMAINING",
				className: "reply-to-correspondence-column-2",
				render: (text, record) => {
					return (
						<div key={record.id}>
							{record.documents &&
								record.documents?.length > 0 &&
								record.documents?.map((doc) => (
									<p className="h_80px vertical_scroll">
										<span
											onClick={() => this.handlePdfClick(doc)}
											className="application-text">
											{doc?.description}
										</span>
										<p>({checkValue(doc?.date) ? doc?.date : "N.A."})</p>
										<div>
											<span>{doc?.remaining_days} Days</span>
											<span className={doc?.alert ? "visible" : "not-visible"}>
												<Tooltip
													title="Deadline to file reply for this mark has passed"
													className="toolTip-custom">
													<i
														className="fa fa-exclamation-circle"
														aria-hidden="true"
														style={{
															color: "#e1001b",
															paddingLeft: "5px",
															cursor: "pointer",
														}}></i>
												</Tooltip>
											</span>
										</div>
									</p>
								))}
							{record?.status === "Formalities Chk Fail" && 
								record?.correspondence_notices &&
								record?.correspondence_notices?.length > 0 &&
								record?.correspondence_notices?.map((doc) => (
									<p className="h_80px vertical_scroll">
										<div>
											<span
												onClick={() => this.handlePdfClick(doc)}
												className="application-text">
												{doc.subject}
											</span>
											<p>
												(
												{checkValue(doc.date_of_correspondence)
													? doc.date_of_correspondence
													: "N.A."}
												)
											</p>
											<div>
												<span>{doc.remaining_days} Days</span>
												<span className={doc.alert ? "visible" : "not-visible"}>
													<Tooltip
														title="Deadline to file reply for this mark has passed"
														className="toolTip-custom">
														<i
															className="fa fa-exclamation-circle"
															aria-hidden="true"
															style={{
																color: "#e1001b",
																paddingLeft: "5px",
																cursor: "pointer",
															}}></i>
													</Tooltip>
												</span>
											</div>
										</div>
									</p>
								))}
							{record?.examination_report_documents &&
								record?.examination_report_documents?.length > 0 &&
								record?.examination_report_documents?.map((doc) => (
									<p className="h_80px vertical_scroll">
										<span
											onClick={
												checkValue(doc.url)
													? () => this.handlePdfClick(doc)
													: ""
											}
											style={
												checkValue(doc.url)
													? { cursor: "pointer", color: "#4285f4" }
													: {}
											}>
											EXAMINATION REPORT
										</span>
										<p>({checkValue(doc.date) ? doc.date : "N.A."})</p>
										<div>
											<span>{doc.remaining_days} Days</span>
											<span className={doc.alert ? "visible" : "not-visible"}>
												<Tooltip
													title="Deadline to file reply for this mark has passed"
													className="toolTip-custom">
													<i
														className="fa fa-exclamation-circle"
														aria-hidden="true"
														style={{
															color: "#e1001b",
															paddingLeft: "5px",
															cursor: "pointer",
														}}></i>
												</Tooltip>
											</span>
										</div>
									</p>
								))}
							{record?.documents?.length === 0 &&
								record?.correspondence_notices?.length === 0 &&
								record?.examination_report_documents?.length === 0 && (
									<p className="h_80px vertical_scroll">N.A.</p>
								)}
						</div>
					);
				},
			},

			{
				title: "DISPATCH DOCUMENT | TIME REMAINING",
				className: "reply-to-correspondence-column-2",
				render: (text, record) => {
					return (
						<div>
							{record?.correspondence_notices &&
								record?.correspondence_notices?.length > 0 &&
								record?.correspondence_notices?.map((doc) => (
									<p className="h_80px vertical_scroll">
										<div>
											<span
												onClick={() => this.handlePdfClick(doc)}
												className="application-text">
												{doc.subject}
											</span>
											<p>
												(
												{checkValue(doc.date_of_dispatch)
													? doc.date_of_dispatch
													: "N.A."}
												)
											</p>
										</div>
										<div>
											<span>{doc.remaining_days_wrt_dod} Days</span>
											<span
												className={
													doc.alert_wrt_dod ? "visible" : "not-visible"
												}>
												<Tooltip
													title="Deadline to file reply for this mark has passed"
													className="toolTip-custom">
													<i
														className="fa fa-exclamation-circle"
														aria-hidden="true"
														style={{
															color: "#e1001b",
															paddingLeft: "5px",
															cursor: "pointer",
														}}></i>
												</Tooltip>
											</span>
										</div>
									</p>
								))}
							{record?.correspondence_notices?.length === 0 && (
								<p className="h_80px vertical_scroll">N.A.</p>
							)}
						</div>
					);
				},
			},

			{
				title: "TAGS",
				dataIndex: "tags",
				className: "reply-to-correspondence-column-1",
				render: (tags) => {
					return (
						<div>
							{tags.length > 0
								? tags.map((tag) => (
										<span
											key={tag.id}
											className="Tag"
											style={{
												color: `${tag.color_code}`,
												border: `1px solid ${tag.color_code}`,
											}}>
											{tag.name.toUpperCase()}
										</span>
								  ))
								: "N.A."}
						</div>
					);
				},
			},

			{
				title: "ACTION",
				className: "reply-to-correspondence-column-1",
				render: (action) => {
					return (
						<div
							className="iconTable"
							style={{
								display: "flex",
								justifyContent: "space-around",
								flexDirection: "column",
							}}>
							<p
								className="application-text alignC"
								onClick={() => this.replyFiled(action)}>
								Reply Filed
							</p>
							<Tooltip placement="top" title={"Create To-do Task"}>
								<p className="alignC">
									<CarryOutTwoTone
										onClick={() =>
											this.changeStateValue_L0("addTaskVisible", true)
										}
										style={{ cursor: "pointer", fontSize: "30px" }}
									/>
								</p>
							</Tooltip>
							<Tooltip placement="top" title={"Notes"}>
								<p className="alignC">
									<MessageTwoTone
										onClick={() => this.handleManagerNotes(action)}
										style={{ cursor: "pointer", fontSize: "30px" }}
									/>
								</p>
							</Tooltip>
							<Tooltip placement="top" title={"Send email"}>
								<p
									className="alignC"
									onClick={() =>
										this.handleEmail(
											"modalsVisibility",
											"sendEmail",
											true,
											action
										)
									}>
									<i
										className="fa fa-paper-plane"
										style={{
											padding: "0px 5px",
											color: "#1890ff",
											fontSize: 20,
											cursor: "pointer",
										}}
										aria-hidden="true"></i>
								</p>
							</Tooltip>
						</div>
					);
				},
			},
		];

		const totalOpposedReplyToCorrespondenceColumns = [
			{
				title: "STATUS | APPLICATION DATE | CLASS",
				className: "reply-to-correspondence-column-2",
				render: (text, record) => {
					return (
						<div>
							<p className="text-camelCase">
								{checkValue(record.status)
									? record.status.toLowerCase()
									: "N.A."}
							</p>
							<p className="text-camelCase">
								{checkValue(record.application_date)
									? record.application_date
									: "N.A."}
							</p>
							<p className="text-camelCase">
								{checkValue(record.associated_class)
									? record.associated_class.join(", ")
									: "N.A."}
							</p>
						</div>
					);
				},
			},

			{
				title: "OPPONENT NAME | OPPONENT NUMBER",
				className: "reply-to-correspondence-column-2",
				render: (text, record) => {
					return (
						<div>
							<span className="text-camelCase">
								{checkValue(record.opponent_name)
									? record.opponent_name.toLowerCase()
									: "N.A."}
							</span>
							{record.opponent_number && (
								<p>
									(
									{checkValue(record.opponent_number)
										? record.opponent_number
										: "N.A."}
									)
								</p>
							)}
						</div>
					);
				},
			},

			{
				title: "DOCUMENT | TIME REMAINING",
				className: "reply-to-correspondence-column-2",
				render: (text, record) => {
					return (
						<div>
							{record.documents &&
								record.documents.length > 0 &&
								record.documents.map((doc) => (
									<p className="h_80px vertical_scroll">
										<span
											onClick={() => this.handlePdfClick(doc)}
											className="application-text">
											{doc.description}
										</span>
										<p>({checkValue(doc.date) ? doc.date : "N.A."})</p>
										<div>
											<span>{doc.remaining_days} Days</span>
											<span className={doc.alert ? "visible" : "not-visible"}>
												<Tooltip
													title="Deadline to file reply for this mark has passed"
													className="toolTip-custom">
													<i
														className="fa fa-exclamation-circle"
														aria-hidden="true"
														style={{
															color: "#e1001b",
															paddingLeft: "5px",
															cursor: "pointer",
														}}></i>
												</Tooltip>
											</span>
										</div>
									</p>
								))}
							{record.correspondence_notices &&
								record.correspondence_notices.length > 0 &&
								record.correspondence_notices.map((doc) => (
									<p className="h_80px vertical_scroll">
										<div>
											<span
												onClick={() => this.handlePdfClick(doc)}
												className="application-text">
												{doc.subject}
											</span>
											<p>
												(
												{checkValue(doc.date_of_correspondence)
													? doc.date_of_correspondence
													: "N.A."}
												)
											</p>
											<div>
												<span>{doc.remaining_days} Days</span>
												<span className={doc.alert ? "visible" : "not-visible"}>
													<Tooltip
														title="Deadline to file reply for this mark has passed"
														className="toolTip-custom">
														<i
															className="fa fa-exclamation-circle"
															aria-hidden="true"
															style={{
																color: "#e1001b",
																paddingLeft: "5px",
																cursor: "pointer",
															}}></i>
													</Tooltip>
												</span>
											</div>
										</div>
									</p>
								))}
							{record.examination_report_documents &&
								record.examination_report_documents.length > 0 &&
								record.examination_report_documents.map((doc) => (
									<p className="h_80px vertical_scroll">
										<span
											onClick={
												checkValue(doc.url)
													? () => this.handlePdfClick(doc)
													: ""
											}
											style={
												checkValue(doc.url)
													? { cursor: "pointer", color: "#4285f4" }
													: {}
											}>
											EXAMINATION REPORT
										</span>
										<p>({checkValue(doc.date) ? doc.date : "N.A."})</p>
										<div>
											<span>{doc.remaining_days} Days</span>
											<span className={doc.alert ? "visible" : "not-visible"}>
												<Tooltip
													title="Deadline to file reply for this mark has passed"
													className="toolTip-custom">
													<i
														className="fa fa-exclamation-circle"
														aria-hidden="true"
														style={{
															color: "#e1001b",
															paddingLeft: "5px",
															cursor: "pointer",
														}}></i>
												</Tooltip>
											</span>
										</div>
									</p>
								))}
							{record.documents.length === 0 &&
								record.correspondence_notices.length === 0 &&
								record.examination_report_documents.length === 0 && (
									<p className="h_80px vertical_scroll">N.A.</p>
								)}
						</div>
					);
				},
			},

			{
				title: "DISPATCH DOCUMENT | TIME REMAINING",
				className: "reply-to-correspondence-column-2",
				render: (text, record) => {
					return (
						<div>
							{record.correspondence_notices &&
								record.correspondence_notices.length > 0 &&
								record.correspondence_notices.map((doc) => (
									<p className="h_80px vertical_scroll">
										<div>
											<span
												onClick={() => this.handlePdfClick(doc)}
												className="application-text">
												{doc.subject}
											</span>
											<p>
												(
												{checkValue(doc.date_of_dispatch)
													? doc.date_of_dispatch
													: "N.A."}
												)
											</p>
										</div>
										<div>
											<span>{doc.remaining_days_wrt_dod} Days</span>
											<span
												className={
													doc.alert_wrt_dod ? "visible" : "not-visible"
												}>
												<Tooltip
													title="Deadline to file reply for this mark has passed"
													className="toolTip-custom">
													<i
														className="fa fa-exclamation-circle"
														aria-hidden="true"
														style={{
															color: "#e1001b",
															paddingLeft: "5px",
															cursor: "pointer",
														}}></i>
												</Tooltip>
											</span>
										</div>
									</p>
								))}
							{record.correspondence_notices.length === 0 && (
								<p className="h_80px vertical_scroll">N.A.</p>
							)}
						</div>
					);
				},
			},

			{
				title: "TAGS",
				dataIndex: "tags",
				className: "reply-to-correspondence-column-1",
				render: (tags) => {
					return (
						<div>
							{tags.length > 0
								? tags.map((tag) => (
										<span
											key={tag.id}
											className="Tag"
											style={{
												color: `${tag.color_code}`,
												border: `1px solid ${tag.color_code}`,
											}}>
											{tag.name.toUpperCase()}
										</span>
								  ))
								: "N.A."}
						</div>
					);
				},
			},

			{
				title: "ACTION",
				className: "reply-to-correspondence-column-1",
				render: (action) => {
					return (
						<div
							className="iconTable"
							style={{
								display: "flex",
								justifyContent: "space-around",
								flexDirection: "column",
							}}>
							<p
								className="application-text alignC"
								onClick={() => this.replyFiled(action)}>
								Reply Filed
							</p>
							<Tooltip placement="top" title={"Create To-do Task"}>
								<p className="alignC">
									<CarryOutTwoTone
										onClick={() =>
											this.changeStateValue_L0("addTaskVisible", true)
										}
										style={{ cursor: "pointer", fontSize: "30px" }}
									/>
								</p>
							</Tooltip>
							<Tooltip placement="top" title={"Send email"}>
								<p
									className="alignC"
									onClick={() =>
										this.handleEmail(
											"modalsVisibility",
											"sendEmail",
											true,
											action
										)
									}>
									<i
										className="fa fa-paper-plane"
										style={{
											padding: "0px 5px",
											color: "#1890ff",
											fontSize: 20,
											cursor: "pointer",
										}}
										aria-hidden="true"></i>
								</p>
							</Tooltip>
						</div>
					);
				},
			},
		];

		return (
			<div>
				<Row gutter={16} className="m-top-20">
					<Col span={24}>
						<Card
							title={correspondenceType}
							headStyle={{ background: "#e8e8e8" }}
							extra={
								customProfile && (
									<div>
										<Text strong>IP INDIA</Text>
										<Switch
											className="change-manager-type"
											onChange={() => this.props.handleChangeSwitch()}
											checked={changeSwitch}
										/>
										<Text strong>CUSTOM</Text>
									</div>
								)
							}>
							{correspondenceType === "REPLY TO CORRESPONDENCE" ? (
								<Table
									rowKey={(record) => record.id}
									columns={totalReplyToCorrespondenceColumns}
									dataSource={
                    this.props.replyToCorrespondence
                      ? this.props.replyToCorrespondence.ip_india.trademarks
                      : null
									}
								/>
							) : correspondenceType === "OPPOSED REPLY TO CORRESPONDENCE" ? (
								<Table
									rowKey={(record) => record.id}
									columns={totalOpposedReplyToCorrespondenceColumns}
									dataSource={
                    this.props.opposedReplyToCorrespondence
                      ? this.props.opposedReplyToCorrespondence.ip_india.trademarks
                      : null
									}
								/>
							) : (
								<Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
							)}
						</Card>
					</Col>
				</Row>
				<ReplyFiledModal
					visible={this.state.replyFiledVisible}
					onCloseModal={() =>
						this.changeStateValue_L0("replyFiledVisible", false)
					}
					mm_type={"reply_to_correspondance"}
					application_number={this.state.add_trademark_id}
					pseudo={false}
					mark_type={correspondenceType === "OPPOSED REPLY TO CORRESPONDENCE"?  "Third party Opposed trademark": "Portfolio trademark"}
					actionType={REPLY_FILED_TO_CORRESPONDENCE}
					pending_reply={false}
					reRender={() => this.reRender()}
          count={1}
          applied_for={this.state.replyFiledTrademark?.applied_for ?? ""}
				/>
				<CreateTask
					visible={this.state.addTaskVisible}
					onCloseModal={() => this.changeStateValue_L0("addTaskVisible", false)}
					type={"trademark"}
				/>

				<ManagerNotes
					visible={this.state.managerNotes}
					onCloseModal={() => this.changeStateValue_L0("managerNotes", false)}
					actionType={[POST_TM_NOTES, PATCH_TM_NOTES]}
					email={
						checkValue(this.props.userDetails)
							? this.props.userDetails.email
							: ""
					}
					note_for={TM}
					application_number={this.state.application_number}
					type={"ADD"}
				/>

				<SendEmailNew
					visible={this.state.modalsVisibility.sendEmail}
					onCloseModal={() =>
						this.handleEmail("modalsVisibility", "sendEmail", false, {})
					}
					sendFrom={
						this.props.userDetails && this.props.userDetails.communication_email
					}
					sendCC={this.props.userTeam && this.props.userTeam.manager}
					mailContent={this.state.modalsVisibility.mailContent}
					product={this.state.modalsVisibility.product}
					subscription={this.state.modalsVisibility.subscription}
					report_tag={this.state.modalsVisibility.report_tag}
					objectType={this.state.modalsVisibility.objectType}
					applicationNumber={this.state.modalsVisibility.applicationNumber}
					markId={this.state.modalsVisibility.markId}
					doc_link={true}
					sourceId={this.state.modalsVisibility.sourceId}
					source={`TRADEMARK`}
					sub_section="DASHBOARD"
					managerEmail={true}
          mm_type={correspondenceType === "OPPOSED REPLY TO CORRESPONDENCE"?  "Third party Opposed trademark": "Portfolio trademark"}
				/>
			</div>
		);
	}
}
const mapDispatchToProps = {
  getReplyToCorrespondence,
};
function mapStateToProps(state) {
  const { replyToCorrespondence,opposedReplyToCorrespondence } = state.managerState.trademarkDashboardState;

  return deepFreeze({
    replyToCorrespondence,
    opposedReplyToCorrespondence
  });
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(CorrespondenceTable);
