//--------LIBRARIES--------
import React, { Component } from "react";
import { connect } from "react-redux";
import deepFreeze from "deep-freeze";
import {
  AppstoreTwoTone,
  DashboardTwoTone,
  DownloadOutlined,
  ReconciliationTwoTone,
} from "@ant-design/icons";
import {
  Row,
  Col,
  Table,
  Typography,
  Modal,
  Button,
  Card,
  Input,
  Alert,
  Drawer,
  message,
  Switch,
  Collapse,
} from "antd";

//--------COMMON CONSTANTS--------
import {
  LOADING,
  SUCCESS,
} from "../../../constants/commonConstants/generalConstants";

//--------ACTIONS--------
import {
  getRefinedTrademark,
  getTrademarkChangelog,
  putUploadedFile,
  postDownloadFile,
  deleteUploadedFile,
  postUploadFile,
} from "../../../redux/actions/managerActions/trademarkDashboard/actions";
import {
  getTeamMember,
  logOut,
} from "../../../redux/actions/commonActions/actions";

import { getReplyToCorrespondence } from "../../../redux/actions/managerActions/trademarkDashboard/actions";
import {
  TM_DefaultSelectedFilters,
  TM_DefaultSearchFilters,
} from "../../../constants/managerConstants/trademarkConstants";

//--------HISTORY--------
import { history } from "../../../history";

import { checkValue } from "../../../functions/commonFunctions/checkValueFunctions";
import ChangeLogTrademark from "./ChangeLog";

//--------DATE FUNCTIONS--------
import { standardizeDateTime } from "../../../functions/commonFunctions/dateFunctions";
import { TM } from "../../../constants/managerConstants/managerConstants";
import UploadDocuments from "../../commonComponents/Drawers/UploadDocuments";
import {
  UPLOAD_FILE_TM_REFINED,
  DASHBOARD_REPLY_FILED_TO_CORRESPONDENCE,
} from "../../../redux/actions/managerActions/trademarkDashboard/types";
import { changeStateValue_L0 } from "../../../functions/commonFunctions/generalFunctions";

import ManagerNotes from "../subComponents/Modal/managerNotes";
import {
  getNotes,
  deleteNotes,
} from "../../../redux/actions/managerActions/commonAction/action";
import {
  GET_TM_NOTES,
  POST_TM_NOTES,
  PATCH_TM_NOTES,
  DELETE_TM_NOTES,
} from "../../../redux/actions/managerActions/commonAction/types";
import UploadDocumentsTable from "../subComponents/trademarkProfileComponents/UploadDocumentsTable";
import NotesTable from "../subComponents/trademarkProfileComponents/NotesTable";
import Timeline from "../subComponents/trademarkProfileComponents/Timeline";
import RenewalTable from "../subComponents/trademarkProfileComponents/RenewalTable";
import CorrespondenceTable from "../subComponents/trademarkProfileComponents/CorrespondenceTable";
import HearingTable from "../subComponents/trademarkProfileComponents/HearingTable";
import TodoTaskTable from "../subComponents/trademarkProfileComponents/TodoTaskTable";
import RenewalFiledModal from "./RenewalFiled";
import ReplyFiledModal from "./ReplyFiled";
import CreateTask from "../to-do-task/CreateTask";
import SendEmail from "../../commonComponents/Modals/sendMail";
import SendEmailNew from "../../commonComponents/Modals/SendEmailNew";
import SentEmail from "./YourTrademak/SentEmail";
import "../../../stylesheets/App.css";

const { Title, Text } = Typography;
const { TextArea } = Input;
const { Panel } = Collapse;

class TrademarkProfile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      render: false,
      buttontext: "Expand All",
      activePanels: [],
      visible: false,
      changeLogVisible: false,
      managerNotes: false,
      notesEditingKey: "",
      notesDeletingKey: "",
      note_id: "",
      note: "",
      changeSwitch: false,
      renewalFiledVisible: false,
      replyFiledVisible: false,
      addTaskVisible: false,
      modalsVisibility: {
        sendEmail: false,
        mailContent: "",
        report_tag: {},
      },
    };
    this.changeStateValue_L0 = changeStateValue_L0.bind(this);
    this.buttonClickText = this.buttonClickText.bind(this);
    this.handlePanel = this.handlePanel.bind(this);
  }

  buttonClickText() {
    if (document.getElementById("expand").innerText === "Expand All") {
      document.getElementById("expand").innerText = "Collapse All";
      this.setState({
        activePanels: [
          "1",
          "2",
          "3",
          "4",
          "5",
          "6",
          "7",
          "8",
          "9",
          "10",
          "11",
          "12",
          "13",
        ],
      });
    } else {
      document.getElementById("expand").innerText = "Expand All";
      this.setState({ activePanels: [] });
    }
  }

  handlePanel(key) {
    this.setState({ activePanels: key });
  }

  componentDidMount() {
    if (localStorage.getItem("token")) {
      let management_trademark_id = sessionStorage.getItem(
        "management_trademark_id",
      );
      if (management_trademark_id) {
        this.props.getRefinedTrademark(management_trademark_id);
        this.props.getTrademarkChangelog(management_trademark_id);
      }

      if (this.props.teamMember === null) {
        this.props.getTeamMember("manager");
      }

      this.props.getNotes({
        actionType: GET_TM_NOTES,
        note_for: TM,
        application_number: sessionStorage.getItem(
          "trademark_application_number",
        ),
      });
    } else {
      this.props.logOut();
    }
  }
  //back
  backClickHandler() {
    this.props.getReplyToCorrespondence({
      mmType: "trademark",
      filterFlag: false,
      filters: TM_DefaultSelectedFilters,
      pageNumber: 1,
    });
    window.history.back();
  }

  handleProprietorProfile(data) {
    if (data) {
      sessionStorage.setItem("proprietor_id", data.id);
      window.open("/manager/trademark/proprietor-profile/" + data.id, "_blank");
      this.setState({
        proprietor_id: data.id,
      });
    }
  }
  reRender() {
    if (localStorage.getItem("token")) {
      let management_trademark_id = sessionStorage.getItem(
        "management_trademark_id",
      );
      this.props.getRefinedTrademark(management_trademark_id);
      this.props.getTrademarkChangelog(management_trademark_id);

      if (this.props.teamMember === null) {
        this.props.getTeamMember("manager");
      }

      this.props.getNotes({
        actionType: GET_TM_NOTES,
        note_for: TM,
        application_number: sessionStorage.getItem(
          "trademark_application_number",
        ),
      });
    } else {
      this.props.logOut();
    }
  }

  showHistoryDetail(type) {
    Modal.info({
      title: "HISTORY/PR DETAILS:",
      width: 800,
      className: "mark-delete-modal",
      content: (
        <div>
          {this.props.refinedTrademark && (
            <div>
              {type === "ipindia" ? (
                <TextArea
                  value={this.props.refinedTrademark.ip_india.history}
                  className="complete-specification-box vertical_scroll"
                  autosize={{ minRows: 5 }}
                />
              ) : type === "pseudo" ? (
                <TextArea
                  value={this.props.refinedTrademark.pseudo.history}
                  className="complete-specification-box vertical_scroll"
                  autosize={{ minRows: 5 }}
                />
              ) : (
                ""
              )}
            </div>
          )}
        </div>
      ),
      okText: "Close",
      // okType: '',
      onOk() {},
    });
  }

  showDescription(type) {
    Modal.info({
      title: "DESCRIPTION",
      width: 800,
      className: "mark-delete-modal",
      content: (
        <div>
          {this.props.refinedTrademark && (
            <div>
              {type === "ipindia" ? (
                <TextArea
                  value={this.props.refinedTrademark.ip_india.description}
                  className="complete-specification-box vertical_scroll"
                  autosize={{ minRows: 5 }}
                />
              ) : type === "pseudo" ? (
                <TextArea
                  value={this.props.refinedTrademark.pseudo.description}
                  className="complete-specification-box vertical_scroll"
                  autosize={{ minRows: 5 }}
                />
              ) : (
                ""
              )}
            </div>
          )}
        </div>
      ),
      okText: "Close",
      // okType: '',
      onOk() {},
    });
  }

  handleDownloadDocumentLink(data) {
    let link = "";
    link = data?.url;
    window.open(link, "_blank");
  }

  handleDownloadDocument(data) {
    let link = "";
    if (data?.file) {
      link = data?.file;
    } else {
      link = data?.url;
    }

    window.open(link, "_blank");
  }

  openExaReport(data) {
    if (data !== null) window.open(data, "_blank");
  }

  onChangeLog() {
    this.setState({
      changeLogVisible: true,
    });
  }

  closeChangeLog() {
    this.setState({
      changeLogVisible: false,
    });
  }

  handleAddNotes() {
    this.setState({
      managerNotes: true,
      type: "ADD",
    });
  }

  handleEditNotes(data) {
    this.setState({
      managerNotes: true,
      notesEditingKey: data.id,
      note_id: data.id,
      note: data.note,
      type: "EDIT",
    });
  }

  async handleDeleteNotes(data) {
    this.setState({
      notesDeletingKey: data.id,
    });
    await this.props.deleteNotes({
      actionType: DELETE_TM_NOTES,
      note_for: TM,
      application_number: sessionStorage.getItem(
        "trademark_application_number",
      ),
      note_id: data.id,
      delete_all: false,
    });
    message[this.props.deleteManageNotesStatus.toLowerCase()](
      this.props.manageNotesMessage,
    );
  }

  async handleDeleteNotesAll() {
    await this.props.deleteNotes({
      actionType: DELETE_TM_NOTES,
      note_for: TM,
      application_number: sessionStorage.getItem(
        "trademark_application_number",
      ),
      delete_all: true,
    });
    message[this.props.deleteAllManageNotesStatus.toLowerCase()](
      this.props.manageNotesMessage,
    );
  }

  handleChangeSwitch() {
    this.setState({
      changeSwitch: !this.state.changeSwitch,
    });
  }

  renewalFiled(data) {
    this.setState({
      renewalFiledVisible: true,
      renewal: false,
      application_number: data.application_number,
      applied_for: data.applied_for,
      pseudo: this.state.changeSwitch,
    });
  }

  replyFiled(data) {
    this.setState({
      replyFiledVisible: true,
      pending_reply: false,
      application_number: data.id,
      applied_for: data.applied_for,
      pseudo: this.state.changeSwitch,
    });
  }

  handleEmail(modalsVisibility, modal, value, data, type) {
    let mailContent = "<p><strong>Trademark Manager</strong></p>";
    if (type === "tm_applied") {
      this.setState({
        [modalsVisibility]: {
          ...this.state[modalsVisibility],
          [modal]: value,
          mailContent: mailContent,
          product: "MANAGER",
          subscription: [
            this.state.changeSwitch ? "PSEUDO_TRADEMARK" : "TRADEMARK",
          ],
          report_tag: {
            journal_number: checkValue(data.journal) ? data.journal.number : "",
            journal_date: checkValue(data.journal)
              ? data.journal.date_of_publication
              : "",
            status: checkValue(data.status) ? data.status : "",
            renewal_date: checkValue(data.valid_upto) ? data.valid_upto : "",
            application_number: checkValue(data.application_number)
              ? data.application_number
              : "",
            mark_name: checkValue(data.applied_for) ? data.applied_for : "",
            date_of_application: checkValue(data.application_date)
              ? data.application_date
              : "",
            class: checkValue(data.associated_class)
              ? data.associated_class.join(", ")
              : "",
            date_of_usage: checkValue(data.date_of_usage)
              ? data.date_of_usage
              : "",
            proprietor_name: checkValue(data.proprietor)
              ? data.proprietor.map((prop) => prop?.name).join(", ")
              : "",
            opponent_name: checkValue(data.opponent)
              ? data.opponent.map((opp) => opp?.name).join(", ")
              : "",
            tla_hearing_date: "",
            opposition_hearing_date: "",
            report_name: "",
          },
          objectType: this.state.changeSwitch
            ? "pseudo_trademark"
            : "trademark",
          applicationNumber: data.application_number,
          markId: data.management_mark_id,
          sourceId: data.management_mark_id,
        },
      });
    } else {
      this.setState({
        [modalsVisibility]: {
          ...this.state[modalsVisibility],
          [modal]: value,
          mailContent: mailContent,
          product: "MANAGER",
          subscription: ["TRADEMARK"],
          report_tag: {
            journal_number: "",
            journal_date: "",
            status: checkValue(data.status) ? data.status : "",
            renewal_date:
              data.trademark && checkValue(data.trademark.valid_upto)
                ? data.trademark.valid_upto
                : "",
            application_number: checkValue(data.application_number)
              ? data.application_number
              : "",
            mark_name:
              data.trademark && checkValue(data.trademark.applied_for)
                ? data.trademark.applied_for
                : "",
            date_of_application:
              data.trademark && checkValue(data.trademark.application_date)
                ? data.trademark.application_date
                : "",
            class:
              data.trademark && checkValue(data.trademark.associated_class)
                ? data.trademark.associated_class.join(", ")
                : "",
            date_of_usage:
              data.trademark && checkValue(data.trademark.date_of_usage)
                ? data.trademark.date_of_usage
                : "",
            proprietor_name: checkValue(data.proprietor)
              ? data.proprietor.map((prop) => prop?.name).join(", ")
              : "",
            opponent_name: checkValue(data.opponent_name)
              ? data.opponent_name
              : "",
            tla_hearing_date:
              type === "tla_hearing"
                ? checkValue(data.hearing_date)
                  ? data.hearing_date
                  : ""
                : "",
            opposition_hearing_date:
              type === "oppostion_hearing"
                ? checkValue(data.hearing_date)
                  ? data.hearing_date
                  : ""
                : "",
            report_name: "",
          },
          objectType: "trademark",
          applicationNumber: data.application_number,
          markId: data.management_mark_id,
          sourceId: data.management_mark_id,
        },
      });
    }
  }

  render() {
    const {
      refinedTrademark,
      refinedTrademarkStatus,
      manageNotes,
      manageNotesStatus,
      deleteManageNotesStatus,
      deleteAllManageNotesStatus,
    } = this.props;

    let customProfile = false;
    let pendingRenewal = "";
    let pendingReply = "";
    let opposedPendingReply = "";
    let tlaHearing = "";
    let oppositionHearing = "";
    let ip_india_tla_hearing = "";
    let todoTask = [];
    if (refinedTrademarkStatus === SUCCESS) {
      customProfile = refinedTrademark.abides_by === "Own Data";
      if (this.state.changeSwitch) {
        pendingRenewal =
          refinedTrademark.pseudo && refinedTrademark.pseudo.pending_renewal;
        pendingReply =
          refinedTrademark.pseudo && refinedTrademark.pseudo.pending_reply;
        opposedPendingReply =
          refinedTrademark.pseudo &&
          refinedTrademark.pseudo.opposed_pending_reply;
        tlaHearing =
          refinedTrademark.pseudo && refinedTrademark.pseudo.tla_hearing;
        ip_india_tla_hearing =
          refinedTrademark.pseudo.ip_india.ip_india_tla_hearing &&
          refinedTrademark.pseudo.ip_india.ip_india_tla_hearing;
        oppositionHearing =
          refinedTrademark.pseudo && refinedTrademark.pseudo.opposition_hearing;
        todoTask =
          refinedTrademark.pseudo && refinedTrademark.pseudo.to_do_task;
      } else {
        pendingRenewal =
          refinedTrademark.ip_india &&
          refinedTrademark.ip_india.pending_renewal;
        pendingReply =
          refinedTrademark.ip_india && refinedTrademark.ip_india.pending_reply;
        opposedPendingReply =
          refinedTrademark.ip_india &&
          refinedTrademark.ip_india.opposed_pending_reply;
        tlaHearing =
          refinedTrademark.ip_india && refinedTrademark.ip_india.tla_hearing;
        ip_india_tla_hearing =
          refinedTrademark.ip_india.ip_india_tla_hearing &&
          refinedTrademark.ip_india.ip_india_tla_hearing;
        oppositionHearing =
          refinedTrademark.ip_india &&
          refinedTrademark.ip_india.opposition_hearing;

        todoTask =
          refinedTrademark.ip_india && refinedTrademark.ip_india.to_do_task;
      }
    }

    const totalDocumentColumns = [
      {
        title: "DOCUMENTS",
        dataIndex: "description",
        width: "60%",
      },

      {
        title: "DATE",
        dataIndex: "date",
        width: "25%",
      },

      {
        title: "SAVE",
        width: "15%",
        align: "center",
        render: (text, document) => {
          return document ? (
            <Button
              type="link"
              disabled={document.file || document.url ? false : true}
              style={{ fontSize: "20px" }}
              onClick={() => this.handleDownloadDocument(document)}
            >
              <DownloadOutlined style={{ fontSize: "20px" }} />
            </Button>
          ) : (
            ""
          );
        },
      },
    ];

    const correspondenceNoticeColumns = [
      {
        title: "CORRESPONDENCE DOCUMENTS",
        dataIndex: "subject",
        width: "35%",
      },

      {
        title: "DATE OF DISPATCH",
        dataIndex: "date_of_dispatch",
        width: "25%",
      },
      {
        title: "DATE OF CORRESPONDENCE",
        dataIndex: "date_of_correspondence",
        width: "25%",
      },

      {
        title: "SAVE",
        width: "15%",
        align: "center",
        render: (text, document) => {
          return document ? (
            <Button
              type="link"
              disabled={document.file || document.url ? false : true}
              style={{ fontSize: "20px" }}
              onClick={() => this.handleDownloadDocumentLink(document)}
            >
              <DownloadOutlined style={{ fontSize: "20px" }} />
            </Button>
          ) : (
            ""
          );
        },
      },
    ];

    const oppositionDocumentColumns = [
      {
        title: "DESCRIPTION",
        dataIndex: "description",
        width: "60%",
      },

      {
        title: "DATE",
        dataIndex: "date",
        width: "25%",
      },

      {
        title: "VIEW",
        width: "15%",
        align: "center",
        render: (text, document) => {
          return document ? (
            <Button
              type="link"
              disabled={document.file || document.url ? false : true}
              style={{ fontSize: "20px" }}
              onClick={() => this.handleDownloadDocument(document)}
            >
              <DownloadOutlined style={{ fontSize: "20px" }} />
            </Button>
          ) : (
            ""
          );
        },
      },
    ];

    const correspondenceNoticeDocumentColumns = [
      {
        title: "CORRESPONDENCE NUMBER",
        dataIndex: "correspondence_number",
        className: "patent-column",
      },

      {
        title: "CORRESPONDENCE DATE",
        dataIndex: "date_of_correspondence",
        className: "patent-column",
      },

      {
        title: "SUBJECT",
        dataIndex: "subject",
        className: "patent-column",
      },

      {
        title: "DISPATCH NUMBER",
        dataIndex: "dispatch_number",
        className: "patent-column",
      },

      {
        title: "DISPATCH DATE",
        dataIndex: "date_of_dispatch",
        className: "patent-column",
      },

      {
        title: "VIEW",
        align: "center",
        render: (text, document) => {
          return document ? (
            <Button
              type="link"
              disabled={document.file || document.url ? false : true}
              style={{ fontSize: "20px" }}
              onClick={() => this.handleDownloadDocumentLink(document)}
            >
              <DownloadOutlined style={{ fontSize: "20px" }} />
            </Button>
          ) : (
            ""
          );
        },
      },
    ];

    return (
      <div>
        {/* back-section */}
        <Row>
          <Col span={4} className="alignL">
            <Text
              strong
              style={{ fontSize: "14px", cursor: "pointer" }}
              onClick={() => this.backClickHandler()}
            >
              <i className="fa fa-arrow-left" aria-hidden="true">
                {" "}
                Back
              </i>
            </Text>
          </Col>
          <Col span={20} className="alignR">
            <Text strong style={{ fontSize: "14px", color: "#5aac44" }}>
              *Data as on -{" "}
              {checkValue(refinedTrademark)
                ? standardizeDateTime(
                    refinedTrademark.ip_india.last_realtime_date,
                  )
                : "N.A."}
            </Text>
          </Col>
        </Row>
        {/* back-section */}
        {/* card-section */}
        <Row gutter={16} className="m-top-10">
          <Col className="gutter-row" span={8}>
            <Card
              hoverable
              bodyStyle={{
                textAlign: "center",
                height: "100px",
                padding: "15px",
              }}
              loading={refinedTrademarkStatus === LOADING ? true : false}
            >
              <Row>
                <Col span={4}>
                  <ReconciliationTwoTone style={{ fontSize: "35px" }} />
                </Col>
                <Col span={20}>
                  <Title level={4} style={{ fontSize: "14px" }}>
                    OPPOSITIONS/RECTIFICATIONS FILED
                  </Title>
                  <Text strong style={{ fontSize: "14px" }}>
                    {checkValue(refinedTrademark)
                      ? refinedTrademark.ip_india.opposition_count
                      : ""}
                  </Text>
                </Col>
              </Row>
            </Card>
          </Col>
          <Col className="gutter-row" span={8}>
            <Card
              hoverable
              bodyStyle={{
                textAlign: "center",
                height: "100px",
                padding: "15px",
              }}
              loading={refinedTrademarkStatus === LOADING ? true : false}
            >
              <Row>
                <Col span={4}>
                  <AppstoreTwoTone style={{ fontSize: "35px" }} />
                </Col>
                <Col span={20}>
                  <Title level={4} style={{ fontSize: "14px" }}>
                    TRADEMARK TYPE
                  </Title>
                  <Text strong style={{ fontSize: "14px" }}>
                    {checkValue(refinedTrademark)
                      ? refinedTrademark.ip_india.type?.name
                      : ""}
                  </Text>
                </Col>
              </Row>
            </Card>
          </Col>
          <Col className="gutter-row" span={8}>
            <Card
              hoverable
              bodyStyle={{
                textAlign: "center",
                height: "100px",
                padding: "15px",
              }}
              loading={refinedTrademarkStatus === LOADING ? true : false}
            >
              <Row>
                <Col span={4}>
                  <DashboardTwoTone style={{ fontSize: "35px" }} />
                </Col>
                <Col span={20}>
                  <Title level={4} style={{ fontSize: "14px" }}>
                    TRADEMARK STATUS
                  </Title>
                  <Text strong style={{ fontSize: "14px" }}>
                    {checkValue(refinedTrademark)
                      ? refinedTrademark.ip_india.status
                      : ""}
                  </Text>
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
        {/* card-section */}
        {/* TRADEMARK INFORMATION */}
        <Row gutter={16} className="m-top-20">
          <Col span={24}>
            <Card
              title="TRADEMARK INFORMATION"
              extra={
                <div>
                  <Button type="primary" onClick={() => this.onChangeLog()}>
                    Change Log
                  </Button>
                </div>
              }
              headStyle={{ background: "#e8e8e8" }}
              loading={refinedTrademarkStatus === LOADING ? true : false}
            >
              <Col span={24}>
                {checkValue(refinedTrademark) ? (
                  checkValue(refinedTrademark.ip_india.alert) ? (
                    <div className="alignL m-bot-20">
                      <Alert
                        className="trademark-alert-message"
                        message={refinedTrademark.ip_india.alert}
                        type="error"
                      />
                    </div>
                  ) : (
                    ""
                  )
                ) : (
                  ""
                )}
                <div>
                  <table className="trademark-information-table">
                    <tbody>
                      <tr>
                        <td>
                          <div>
                            <p>NAME OF TRADEMARK:</p>
                          </div>
                        </td>
                        <td>
                          {refinedTrademark &&
                            refinedTrademark.ip_india &&
                            refinedTrademark.pseudo &&
                            refinedTrademark.ip_india.applied_for && (
                              <div>
                                <label className="ip-india-heading">
                                  IP INDIA
                                </label>
                              </div>
                            )}
                          {refinedTrademark &&
                            refinedTrademark.ip_india &&
                            !refinedTrademark.pseudo && (
                              <div>
                                <p></p>
                              </div>
                            )}
                        </td>
                        <td>
                          {refinedTrademark && refinedTrademark.ip_india && (
                            <div>
                              <p>
                                {checkValue(
                                  refinedTrademark.ip_india.applied_for,
                                )
                                  ? refinedTrademark.ip_india.applied_for
                                  : "N.A."}
                              </p>
                            </div>
                          )}
                          {refinedTrademark && !refinedTrademark.ip_india && (
                            <div>
                              <p></p>
                            </div>
                          )}
                        </td>
                        <td>
                          {refinedTrademark &&
                            refinedTrademark.pseudo &&
                            refinedTrademark.pseudo.applied_for && (
                              <div>
                                <label className="custom-data-heading">
                                  CUSTOM
                                </label>
                              </div>
                            )}
                          {refinedTrademark && !refinedTrademark.pseudo && (
                            <div>
                              <p></p>
                            </div>
                          )}
                        </td>
                        <td>
                          {refinedTrademark &&
                            refinedTrademark.pseudo &&
                            refinedTrademark.pseudo.applied_for && (
                              <div>
                                <p>
                                  {checkValue(
                                    refinedTrademark.pseudo.applied_for,
                                  )
                                    ? refinedTrademark.pseudo.applied_for
                                    : "N.A."}
                                </p>
                              </div>
                            )}
                          {refinedTrademark && !refinedTrademark.pseudo && (
                            <div>
                              <p></p>
                            </div>
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <div>
                            <p>TRADEMARK LOGO:</p>
                          </div>
                        </td>
                        <td>
                          {refinedTrademark &&
                            refinedTrademark.ip_india &&
                            refinedTrademark.pseudo &&
                            refinedTrademark.ip_india.associated_image && (
                              <div>
                                <label className="ip-india-heading">
                                  IP INDIA
                                </label>
                              </div>
                            )}
                          {refinedTrademark &&
                            refinedTrademark.ip_india &&
                            !refinedTrademark.pseudo && (
                              <div>
                                <p></p>
                              </div>
                            )}
                        </td>
                        <td>
                          {refinedTrademark && refinedTrademark.ip_india && (
                            <div>
                              <p>
                                {checkValue(
                                  refinedTrademark.ip_india.associated_image,
                                ) ? (
                                  <div
                                    className="thumbnail"
                                    style={{ margin: "0px" }}
                                  >
                                    <img
                                      className="trademark-profile-logo"
                                      src={
                                        refinedTrademark.ip_india
                                          .associated_image
                                      }
                                      alt="Trademark Logo"
                                    />
                                  </div>
                                ) : (
                                  "N.A."
                                )}
                              </p>
                            </div>
                          )}
                          {refinedTrademark && !refinedTrademark.ip_india && (
                            <div>
                              <p></p>
                            </div>
                          )}
                        </td>
                        <td>
                          {refinedTrademark &&
                            refinedTrademark.pseudo &&
                            refinedTrademark.pseudo.associated_image && (
                              <div>
                                <label className="custom-data-heading">
                                  CUSTOM
                                </label>
                              </div>
                            )}
                          {refinedTrademark && !refinedTrademark.pseudo && (
                            <div>
                              <p></p>
                            </div>
                          )}
                        </td>
                        <td>
                          {refinedTrademark &&
                            refinedTrademark.pseudo &&
                            refinedTrademark.pseudo.associated_image && (
                              <div>
                                <p className="thumbnail">
                                  {checkValue(
                                    refinedTrademark.pseudo.associated_image,
                                  ) ? (
                                    <div
                                      className="thumbnail"
                                      style={{ margin: "0px" }}
                                    >
                                      <img
                                        className="trademark-profile-logo"
                                        src={
                                          refinedTrademark.pseudo
                                            .associated_image
                                        }
                                        alt="Trademark Logo"
                                      />
                                    </div>
                                  ) : (
                                    "N.A."
                                  )}
                                </p>
                              </div>
                            )}
                          {refinedTrademark && !refinedTrademark.pseudo && (
                            <div>
                              <p></p>
                            </div>
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <div>
                            <p>APPLICATION NUMBER:</p>
                          </div>
                        </td>
                        <td>
                          {refinedTrademark &&
                            refinedTrademark.ip_india &&
                            refinedTrademark.pseudo &&
                            refinedTrademark.ip_india.application_number && (
                              <div>
                                <label className="ip-india-heading">
                                  IP INDIA
                                </label>
                              </div>
                            )}
                          {refinedTrademark &&
                            refinedTrademark.ip_india &&
                            !refinedTrademark.pseudo && (
                              <div>
                                <p></p>
                              </div>
                            )}
                        </td>
                        <td>
                          {refinedTrademark && refinedTrademark.ip_india && (
                            <div>
                              <p>
                                {checkValue(
                                  refinedTrademark.ip_india.application_number,
                                )
                                  ? refinedTrademark.ip_india.application_number
                                  : "N.A."}
                              </p>
                            </div>
                          )}
                          {refinedTrademark && !refinedTrademark.ip_india && (
                            <div>
                              <p></p>
                            </div>
                          )}
                        </td>
                        <td>
                          {refinedTrademark &&
                            refinedTrademark.pseudo &&
                            refinedTrademark.pseudo.application_number && (
                              <div>
                                <label className="custom-data-heading">
                                  CUSTOM
                                </label>
                              </div>
                            )}
                          {refinedTrademark && !refinedTrademark.pseudo && (
                            <div>
                              <p></p>
                            </div>
                          )}
                        </td>
                        <td>
                          {refinedTrademark &&
                            refinedTrademark.pseudo &&
                            refinedTrademark.pseudo.application_number && (
                              <div>
                                <p>
                                  {refinedTrademark.pseudo.application_number}
                                </p>
                              </div>
                            )}
                          {refinedTrademark && !refinedTrademark.pseudo && (
                            <div>
                              <p></p>
                            </div>
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <div>
                            <p>CLASSES:</p>
                          </div>
                        </td>
                        <td>
                          {refinedTrademark &&
                            refinedTrademark.ip_india &&
                            refinedTrademark.pseudo &&
                            refinedTrademark.ip_india.associated_class && (
                              <div>
                                <label className="ip-india-heading">
                                  IP INDIA
                                </label>
                              </div>
                            )}
                          {refinedTrademark &&
                            refinedTrademark.ip_india &&
                            !refinedTrademark.pseudo && (
                              <div>
                                <p></p>
                              </div>
                            )}
                        </td>
                        <td>
                          {refinedTrademark &&
                            refinedTrademark.ip_india &&
                            refinedTrademark.ip_india.associated_class && (
                              <div>
                                <p>
                                  {refinedTrademark.ip_india.associated_class.join(
                                    ", ",
                                  )}
                                </p>
                              </div>
                            )}
                          {refinedTrademark && !refinedTrademark.ip_india && (
                            <div>
                              <p></p>
                            </div>
                          )}
                        </td>
                        <td>
                          {refinedTrademark &&
                            refinedTrademark.pseudo &&
                            refinedTrademark.pseudo.associated_class && (
                              <div>
                                <label className="custom-data-heading">
                                  CUSTOM
                                </label>
                              </div>
                            )}
                          {refinedTrademark && !refinedTrademark.pseudo && (
                            <div>
                              <p></p>
                            </div>
                          )}
                        </td>
                        <td>
                          {refinedTrademark &&
                            refinedTrademark.pseudo &&
                            refinedTrademark.pseudo.associated_class && (
                              <div>
                                <p>
                                  {refinedTrademark.pseudo.associated_class.join(
                                    ", ",
                                  )}
                                </p>
                              </div>
                            )}
                          {refinedTrademark && !refinedTrademark.pseudo && (
                            <div>
                              <p></p>
                            </div>
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <div>
                            <p>STATUS:</p>
                          </div>
                        </td>
                        <td>
                          {refinedTrademark &&
                            refinedTrademark.ip_india &&
                            refinedTrademark.pseudo &&
                            refinedTrademark.ip_india.status && (
                              <div>
                                <label className="ip-india-heading">
                                  IP INDIA
                                </label>
                              </div>
                            )}
                          {refinedTrademark &&
                            refinedTrademark.ip_india &&
                            !refinedTrademark.pseudo && (
                              <div>
                                <p></p>
                              </div>
                            )}
                        </td>
                        <td>
                          {refinedTrademark && refinedTrademark.ip_india && (
                            <div>
                              <p>
                                {checkValue(refinedTrademark.ip_india.status)
                                  ? refinedTrademark.ip_india.status
                                  : "N.A."}
                              </p>
                            </div>
                          )}
                          {refinedTrademark && !refinedTrademark.ip_india && (
                            <div>
                              <p></p>
                            </div>
                          )}
                        </td>
                        <td>
                          {refinedTrademark &&
                            refinedTrademark.pseudo &&
                            refinedTrademark.pseudo.status && (
                              <div>
                                <label className="custom-data-heading">
                                  CUSTOM
                                </label>
                              </div>
                            )}
                          {refinedTrademark && !refinedTrademark.pseudo && (
                            <div>
                              <p></p>
                            </div>
                          )}
                        </td>
                        <td>
                          {refinedTrademark &&
                            refinedTrademark.pseudo &&
                            refinedTrademark.pseudo.status && (
                              <div>
                                <p>{refinedTrademark.pseudo.status}</p>
                              </div>
                            )}
                          {refinedTrademark && !refinedTrademark.pseudo && (
                            <div>
                              <p></p>
                            </div>
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <div>
                            <p>TRADEMARK TYPE:</p>
                          </div>
                        </td>
                        <td>
                          {refinedTrademark &&
                            refinedTrademark.ip_india &&
                            refinedTrademark.pseudo &&
                            refinedTrademark.ip_india.type && (
                              <div>
                                <label className="ip-india-heading">
                                  IP INDIA
                                </label>
                              </div>
                            )}
                          {refinedTrademark &&
                            refinedTrademark.ip_india &&
                            !refinedTrademark.pseudo && (
                              <div>
                                <p></p>
                              </div>
                            )}
                        </td>
                        <td>
                          {refinedTrademark &&
                            refinedTrademark.ip_india &&
                            refinedTrademark.ip_india.type && (
                              <div>
                                <p>
                                  {checkValue(
                                    refinedTrademark.ip_india.type?.name,
                                  )
                                    ? refinedTrademark.ip_india.type?.name
                                    : "N.A."}
                                </p>
                              </div>
                            )}
                          {refinedTrademark && !refinedTrademark.ip_india && (
                            <div>
                              <p></p>
                            </div>
                          )}
                        </td>
                        <td>
                          {refinedTrademark &&
                            refinedTrademark.pseudo &&
                            refinedTrademark.pseudo.type && (
                              <div>
                                <label className="custom-data-heading">
                                  CUSTOM
                                </label>
                              </div>
                            )}
                          {refinedTrademark && !refinedTrademark.pseudo && (
                            <div>
                              <p></p>
                            </div>
                          )}
                        </td>
                        <td>
                          {refinedTrademark &&
                            refinedTrademark.pseudo &&
                            refinedTrademark.pseudo.type && (
                              <div>
                                <p>{refinedTrademark.pseudo.type?.name}</p>
                              </div>
                            )}
                          {refinedTrademark && !refinedTrademark.pseudo && (
                            <div>
                              <p></p>
                            </div>
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <div>
                            <p>DATE OF APPLICATION:</p>
                          </div>
                        </td>
                        <td>
                          {refinedTrademark &&
                            refinedTrademark.ip_india &&
                            refinedTrademark.pseudo &&
                            refinedTrademark.ip_india.application_date && (
                              <div>
                                <label className="ip-india-heading">
                                  IP INDIA
                                </label>
                              </div>
                            )}
                          {refinedTrademark &&
                            refinedTrademark.ip_india &&
                            !refinedTrademark.pseudo && (
                              <div>
                                <p></p>
                              </div>
                            )}
                        </td>
                        <td>
                          {refinedTrademark && refinedTrademark.ip_india && (
                            <div>
                              <p>
                                {checkValue(
                                  refinedTrademark.ip_india.application_date,
                                )
                                  ? refinedTrademark.ip_india.application_date
                                  : "N.A."}
                              </p>
                            </div>
                          )}
                          {refinedTrademark && !refinedTrademark.ip_india && (
                            <div>
                              <p></p>
                            </div>
                          )}
                        </td>
                        <td>
                          {refinedTrademark &&
                            refinedTrademark.pseudo &&
                            refinedTrademark.pseudo.application_date && (
                              <div>
                                <label className="custom-data-heading">
                                  CUSTOM
                                </label>
                              </div>
                            )}
                          {refinedTrademark && !refinedTrademark.pseudo && (
                            <div>
                              <p></p>
                            </div>
                          )}
                        </td>
                        <td>
                          {refinedTrademark &&
                            refinedTrademark.pseudo &&
                            refinedTrademark.pseudo.application_date && (
                              <div>
                                <p>
                                  {refinedTrademark.pseudo.application_date}
                                </p>
                              </div>
                            )}
                          {refinedTrademark && !refinedTrademark.pseudo && (
                            <div>
                              <p></p>
                            </div>
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <div>
                            <p>DATE OF USAGE:</p>
                          </div>
                        </td>
                        <td>
                          {refinedTrademark &&
                            refinedTrademark.ip_india &&
                            refinedTrademark.pseudo && (
                              <div>
                                <label className="ip-india-heading">
                                  IP INDIA
                                </label>
                              </div>
                            )}
                          {refinedTrademark &&
                            refinedTrademark.ip_india &&
                            !refinedTrademark.pseudo && (
                              <div>
                                <p></p>
                              </div>
                            )}
                        </td>
                        <td>
                          {refinedTrademark && refinedTrademark.ip_india && (
                            <div>
                              <p>
                                {refinedTrademark.ip_india.date_of_usage ==
                                null ? (
                                  <span>Proposed To Be Used</span>
                                ) : (
                                  <span>
                                    {refinedTrademark.ip_india.date_of_usage}
                                  </span>
                                )}
                              </p>
                            </div>
                          )}
                          {refinedTrademark && !refinedTrademark.ip_india && (
                            <div>
                              <p></p>
                            </div>
                          )}
                        </td>
                        <td>
                          {refinedTrademark && refinedTrademark.pseudo && (
                            <div>
                              <label className="custom-data-heading">
                                CUSTOM
                              </label>
                            </div>
                          )}
                          {refinedTrademark && !refinedTrademark.pseudo && (
                            <div>
                              <p></p>
                            </div>
                          )}
                        </td>
                        <td>
                          {refinedTrademark && refinedTrademark.pseudo && (
                            <div>
                              <p>
                                {refinedTrademark.pseudo.date_of_usage ==
                                null ? (
                                  <span>Proposed To Be Used</span>
                                ) : (
                                  <span>
                                    {refinedTrademark.pseudo.date_of_usage}
                                  </span>
                                )}
                              </p>
                            </div>
                          )}
                          {refinedTrademark && !refinedTrademark.pseudo && (
                            <div>
                              <p></p>
                            </div>
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <div>
                            <p>VALID UPTO:</p>
                          </div>
                        </td>
                        <td>
                          {refinedTrademark &&
                            refinedTrademark.ip_india &&
                            refinedTrademark.pseudo &&
                            refinedTrademark.ip_india.valid_upto && (
                              <div>
                                <label className="ip-india-heading">
                                  IP INDIA
                                </label>
                              </div>
                            )}
                          {refinedTrademark &&
                            refinedTrademark.ip_india &&
                            !refinedTrademark.pseudo && (
                              <div>
                                <p></p>
                              </div>
                            )}
                        </td>
                        <td>
                          {refinedTrademark && refinedTrademark.ip_india && (
                            <div>
                              <p>
                                {checkValue(
                                  refinedTrademark.ip_india.valid_upto,
                                )
                                  ? refinedTrademark.ip_india.valid_upto
                                  : "N.A."}
                              </p>
                            </div>
                          )}
                          {refinedTrademark && !refinedTrademark.ip_india && (
                            <div>
                              <p></p>
                            </div>
                          )}
                        </td>
                        <td>
                          {refinedTrademark &&
                            refinedTrademark.pseudo &&
                            refinedTrademark.pseudo.valid_upto && (
                              <div>
                                <label className="custom-data-heading">
                                  CUSTOM
                                </label>
                              </div>
                            )}
                          {refinedTrademark && !refinedTrademark.pseudo && (
                            <div>
                              <p></p>
                            </div>
                          )}
                        </td>
                        <td>
                          {refinedTrademark &&
                            refinedTrademark.pseudo &&
                            refinedTrademark.pseudo.valid_upto && (
                              <div>
                                <p>{refinedTrademark.pseudo.valid_upto}</p>
                              </div>
                            )}
                          {refinedTrademark && !refinedTrademark.pseudo && (
                            <div>
                              <p></p>
                            </div>
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <div>
                            <p>RESTRICTIONS:</p>
                          </div>
                        </td>
                        <td>
                          {refinedTrademark &&
                            refinedTrademark.ip_india &&
                            refinedTrademark.pseudo &&
                            refinedTrademark.ip_india.restrictions && (
                              <div>
                                <label className="ip-india-heading">
                                  IP INDIA
                                </label>
                              </div>
                            )}
                          {refinedTrademark &&
                            refinedTrademark.ip_india &&
                            !refinedTrademark.pseudo && (
                              <div>
                                <p></p>
                              </div>
                            )}
                        </td>
                        <td>
                          {refinedTrademark && refinedTrademark.ip_india && (
                            <div>
                              <p>
                                {checkValue(
                                  refinedTrademark.ip_india.restrictions,
                                )
                                  ? refinedTrademark.ip_india.restrictions
                                  : "N.A."}
                              </p>
                            </div>
                          )}
                          {refinedTrademark && !refinedTrademark.ip_india && (
                            <div>
                              <p></p>
                            </div>
                          )}
                        </td>
                        <td>
                          {refinedTrademark &&
                            refinedTrademark.pseudo &&
                            refinedTrademark.pseudo.restrictions && (
                              <div>
                                <label className="custom-data-heading">
                                  CUSTOM
                                </label>
                              </div>
                            )}
                          {refinedTrademark && !refinedTrademark.pseudo && (
                            <div>
                              <p></p>
                            </div>
                          )}
                        </td>
                        <td>
                          {refinedTrademark &&
                            refinedTrademark.pseudo &&
                            refinedTrademark.pseudo.restrictions && (
                              <div>
                                <p>
                                  {checkValue(
                                    refinedTrademark.pseudo.restrictions,
                                  )
                                    ? refinedTrademark.pseudo.restrictions
                                    : "N.A."}
                                </p>
                              </div>
                            )}
                          {refinedTrademark && !refinedTrademark.pseudo && (
                            <div>
                              <p></p>
                            </div>
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <div>
                            <p>CONDITION:</p>
                          </div>
                        </td>
                        <td>
                          {refinedTrademark &&
                            refinedTrademark.ip_india &&
                            refinedTrademark.pseudo &&
                            refinedTrademark.ip_india.condition && (
                              <div>
                                <label className="ip-india-heading">
                                  IP INDIA
                                </label>
                              </div>
                            )}
                          {refinedTrademark &&
                            refinedTrademark.ip_india &&
                            !refinedTrademark.pseudo && (
                              <div>
                                <p></p>
                              </div>
                            )}
                        </td>
                        <td>
                          {refinedTrademark && refinedTrademark.ip_india && (
                            <div>
                              <p>
                                {checkValue(refinedTrademark.ip_india.condition)
                                  ? refinedTrademark.ip_india.condition
                                  : "N.A."}
                              </p>
                            </div>
                          )}
                          {refinedTrademark && !refinedTrademark.ip_india && (
                            <div>
                              <p></p>
                            </div>
                          )}
                        </td>
                        <td>
                          {refinedTrademark &&
                            refinedTrademark.pseudo &&
                            refinedTrademark.pseudo.condition && (
                              <div>
                                <label className="custom-data-heading">
                                  CUSTOM
                                </label>
                              </div>
                            )}
                          {refinedTrademark && !refinedTrademark.pseudo && (
                            <div>
                              <p></p>
                            </div>
                          )}
                        </td>
                        <td>
                          {refinedTrademark &&
                            refinedTrademark.pseudo &&
                            refinedTrademark.pseudo.condition && (
                              <div>
                                <p>
                                  {checkValue(refinedTrademark.pseudo.condition)
                                    ? refinedTrademark.pseudo.condition
                                    : "N.A."}
                                </p>
                              </div>
                            )}
                          {refinedTrademark && !refinedTrademark.pseudo && (
                            <div>
                              <p></p>
                            </div>
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <div>
                            <p>HISTORY/PR DETAILS:</p>
                          </div>
                        </td>
                        <td>
                          {refinedTrademark &&
                            refinedTrademark.ip_india &&
                            refinedTrademark.pseudo &&
                            refinedTrademark.ip_india.history && (
                              <div>
                                <label className="ip-india-heading">
                                  IP INDIA
                                </label>
                              </div>
                            )}
                          {refinedTrademark &&
                            refinedTrademark.ip_india &&
                            !refinedTrademark.pseudo && (
                              <div>
                                <p></p>
                              </div>
                            )}
                        </td>
                        <td>
                          {refinedTrademark && refinedTrademark.ip_india && (
                            <div>
                              <p>
                                {checkValue(
                                  refinedTrademark.ip_india.history,
                                ) ? (
                                  <span
                                    className="application-text"
                                    onClick={() =>
                                      this.showHistoryDetail("ipindia")
                                    }
                                  >
                                    Click Here to Show History/PR Details
                                  </span>
                                ) : (
                                  "N.A."
                                )}
                              </p>
                            </div>
                          )}
                          {refinedTrademark && !refinedTrademark.ip_india && (
                            <div>
                              <p></p>
                            </div>
                          )}
                        </td>
                        <td>
                          {refinedTrademark &&
                            refinedTrademark.pseudo &&
                            refinedTrademark.pseudo.history && (
                              <div>
                                <label className="custom-data-heading">
                                  CUSTOM
                                </label>
                              </div>
                            )}
                          {refinedTrademark && !refinedTrademark.pseudo && (
                            <div>
                              <p></p>
                            </div>
                          )}
                        </td>
                        <td>
                          {refinedTrademark &&
                            refinedTrademark.pseudo &&
                            refinedTrademark.pseudo.history && (
                              <div>
                                <p>
                                  {checkValue(
                                    refinedTrademark.pseudo.history,
                                  ) ? (
                                    <span
                                      className="application-text"
                                      onClick={() =>
                                        this.showHistoryDetail("pseudo")
                                      }
                                    >
                                      Click Here to Show History/PR Details
                                    </span>
                                  ) : (
                                    "N.A."
                                  )}
                                </p>
                              </div>
                            )}
                          {refinedTrademark && !refinedTrademark.pseudo && (
                            <div>
                              <p></p>
                            </div>
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <div>
                            <p>CATEGORY:</p>
                          </div>
                        </td>
                        <td>
                          {refinedTrademark &&
                            refinedTrademark.ip_india &&
                            refinedTrademark.pseudo &&
                            refinedTrademark.ip_india.category && (
                              <div>
                                <label className="ip-india-heading">
                                  IP INDIA
                                </label>
                              </div>
                            )}
                          {refinedTrademark &&
                            refinedTrademark.ip_india &&
                            !refinedTrademark.pseudo && (
                              <div>
                                <p></p>
                              </div>
                            )}
                        </td>
                        <td>
                          {refinedTrademark &&
                            refinedTrademark.ip_india &&
                            refinedTrademark.ip_india.category && (
                              <div>
                                <p>
                                  {checkValue(
                                    refinedTrademark.ip_india.category?.name,
                                  )
                                    ? refinedTrademark.ip_india.category?.name
                                    : "N.A."}
                                </p>
                              </div>
                            )}
                          {refinedTrademark &&
                            refinedTrademark.ip_india &&
                            refinedTrademark.ip_india.category &&
                            !refinedTrademark.ip_india.category?.name && (
                              <div>
                                <p></p>
                              </div>
                            )}
                          {refinedTrademark && !refinedTrademark.ip_india && (
                            <div>
                              <p></p>
                            </div>
                          )}
                        </td>
                        <td>
                          {refinedTrademark &&
                            refinedTrademark.pseudo &&
                            refinedTrademark.pseudo.category && (
                              <div>
                                <label className="custom-data-heading">
                                  CUSTOM
                                </label>
                              </div>
                            )}
                          {refinedTrademark && !refinedTrademark.pseudo && (
                            <div>
                              <p></p>
                            </div>
                          )}
                        </td>
                        <td>
                          {refinedTrademark &&
                            refinedTrademark.pseudo &&
                            refinedTrademark.pseudo.category &&
                            refinedTrademark.pseudo.category?.name && (
                              <div>
                                <p>{refinedTrademark.pseudo.category?.name}</p>
                              </div>
                            )}
                          {refinedTrademark &&
                            refinedTrademark.pseudo &&
                            refinedTrademark.pseudo.category &&
                            !refinedTrademark.pseudo.category?.name && (
                              <div>
                                <p></p>
                              </div>
                            )}
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <div>
                            <p>APPROPRIATE OFFICE:</p>
                          </div>
                        </td>
                        <td>
                          {refinedTrademark &&
                            refinedTrademark.ip_india &&
                            refinedTrademark.pseudo &&
                            refinedTrademark.ip_india.application_office && (
                              <div>
                                <label className="ip-india-heading">
                                  IP INDIA
                                </label>
                              </div>
                            )}
                          {refinedTrademark &&
                            refinedTrademark.ip_india &&
                            !refinedTrademark.pseudo && (
                              <div>
                                <p></p>
                              </div>
                            )}
                        </td>
                        <td>
                          {refinedTrademark &&
                            refinedTrademark.ip_india &&
                            refinedTrademark.ip_india.application_office && (
                              <div>
                                <p>
                                  {checkValue(
                                    refinedTrademark.ip_india.application_office
                                      ?.name,
                                  )
                                    ? refinedTrademark.ip_india
                                        .application_office?.name
                                    : "N.A."}
                                </p>
                              </div>
                            )}
                          {refinedTrademark && !refinedTrademark.ip_india && (
                            <div>
                              <p></p>
                            </div>
                          )}
                        </td>
                        <td>
                          {refinedTrademark &&
                            refinedTrademark.pseudo &&
                            refinedTrademark.pseudo.application_office && (
                              <div>
                                <label className="custom-data-heading">
                                  CUSTOM
                                </label>
                              </div>
                            )}
                          {refinedTrademark && !refinedTrademark.pseudo && (
                            <div>
                              <p></p>
                            </div>
                          )}
                        </td>
                        <td>
                          {refinedTrademark &&
                            refinedTrademark.pseudo &&
                            refinedTrademark.pseudo.application_office && (
                              <div>
                                <p>
                                  {
                                    refinedTrademark.pseudo.application_office
                                      ?.name
                                  }
                                </p>
                              </div>
                            )}
                          {refinedTrademark && !refinedTrademark.pseudo && (
                            <div>
                              <p></p>
                            </div>
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <div>
                            <p>INTERNATIONAL REGISTRATION NUMBER:</p>
                          </div>
                        </td>
                        <td>
                          {refinedTrademark &&
                            refinedTrademark.ip_india &&
                            refinedTrademark.pseudo &&
                            refinedTrademark.ip_india
                              .international_registration_number && (
                              <div>
                                <label className="ip-india-heading">
                                  IP INDIA
                                </label>
                              </div>
                            )}
                          {refinedTrademark &&
                            refinedTrademark.ip_india &&
                            !refinedTrademark.pseudo && (
                              <div>
                                <p></p>
                              </div>
                            )}
                        </td>
                        <td>
                          {refinedTrademark && refinedTrademark.ip_india && (
                            <div>
                              <p>
                                {checkValue(
                                  refinedTrademark.ip_india
                                    .international_registration_number,
                                )
                                  ? refinedTrademark.ip_india
                                      .international_registration_number
                                  : "N.A."}
                              </p>
                            </div>
                          )}
                          {refinedTrademark && !refinedTrademark.ip_india && (
                            <div>
                              <p></p>
                            </div>
                          )}
                        </td>
                        <td>
                          {refinedTrademark &&
                            refinedTrademark.pseudo &&
                            refinedTrademark.pseudo
                              .international_registration_number && (
                              <div>
                                <label className="custom-data-heading">
                                  CUSTOM
                                </label>
                              </div>
                            )}
                          {refinedTrademark && !refinedTrademark.pseudo && (
                            <div>
                              <p></p>
                            </div>
                          )}
                        </td>
                        <td>
                          {refinedTrademark &&
                            refinedTrademark.pseudo &&
                            refinedTrademark.pseudo
                              .international_registration_number && (
                              <div>
                                <p>
                                  {
                                    refinedTrademark.pseudo
                                      .international_registration_number
                                  }
                                </p>
                              </div>
                            )}
                          {refinedTrademark && !refinedTrademark.pseudo && (
                            <div>
                              <p></p>
                            </div>
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <div>
                            <p>PUBLICATION DETAILS:</p>
                          </div>
                        </td>
                        <td>
                          {refinedTrademark &&
                            refinedTrademark.ip_india &&
                            refinedTrademark.pseudo &&
                            refinedTrademark.ip_india.journal && (
                              <div>
                                <label className="ip-india-heading">
                                  IP INDIA
                                </label>
                              </div>
                            )}
                          {refinedTrademark &&
                            refinedTrademark.ip_india &&
                            !refinedTrademark.pseudo && (
                              <div>
                                <p></p>
                              </div>
                            )}
                        </td>
                        <td>
                          {refinedTrademark &&
                            refinedTrademark.ip_india &&
                            refinedTrademark.ip_india.journal &&
                            refinedTrademark.ip_india.journal.number && (
                              <div>
                                <p>
                                  <Text strong>Journal Number: </Text>
                                  <Text>
                                    {refinedTrademark.ip_india.journal.number}
                                  </Text>
                                </p>
                              </div>
                            )}
                          {refinedTrademark &&
                            refinedTrademark.ip_india &&
                            refinedTrademark.ip_india.journal &&
                            refinedTrademark.ip_india.journal
                              .date_of_publication !== null && (
                              <div>
                                <p>
                                  <Text strong>Journal Date: </Text>
                                  <Text>
                                    {
                                      refinedTrademark.ip_india.journal
                                        .date_of_publication
                                    }
                                  </Text>
                                </p>
                              </div>
                            )}
                          {refinedTrademark && !refinedTrademark.ip_india && (
                            <div>
                              <p></p>
                            </div>
                          )}
                        </td>
                        <td>
                          {refinedTrademark &&
                            refinedTrademark.pseudo &&
                            refinedTrademark.pseudo.journal && (
                              <div>
                                <label className="custom-data-heading">
                                  CUSTOM
                                </label>
                              </div>
                            )}
                          {refinedTrademark && !refinedTrademark.pseudo && (
                            <div>
                              <p></p>
                            </div>
                          )}
                        </td>
                        <td>
                          {refinedTrademark &&
                            refinedTrademark.pseudo &&
                            refinedTrademark.pseudo.journal &&
                            refinedTrademark.pseudo.journal.number && (
                              <div>
                                <p>
                                  <Text strong>Journal Number: </Text>
                                  <Text>
                                    {refinedTrademark.pseudo.journal.number}
                                  </Text>
                                </p>
                              </div>
                            )}
                          {refinedTrademark &&
                            refinedTrademark.pseudo &&
                            refinedTrademark.pseudo.journal &&
                            refinedTrademark.pseudo.journal
                              .date_of_publication !== null && (
                              <div>
                                <p>
                                  <Text strong>Journal Date: </Text>
                                  <Text>
                                    {
                                      refinedTrademark.pseudo.journal
                                        .date_of_publication
                                    }
                                  </Text>
                                </p>
                              </div>
                            )}
                          {refinedTrademark && !refinedTrademark.pseudo && (
                            <div>
                              <p></p>
                            </div>
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <div>
                            <p>PROPRIETOR NAME:</p>
                          </div>
                        </td>
                        <td>
                          {refinedTrademark &&
                            refinedTrademark.ip_india &&
                            refinedTrademark.pseudo &&
                            refinedTrademark.ip_india.proprietor && (
                              <div>
                                <label className="ip-india-heading">
                                  IP INDIA
                                </label>
                              </div>
                            )}
                          {refinedTrademark &&
                            refinedTrademark.ip_india &&
                            !refinedTrademark.pseudo && (
                              <div>
                                <p></p>
                              </div>
                            )}
                        </td>
                        <td>
                          {refinedTrademark &&
                            refinedTrademark.ip_india &&
                            refinedTrademark.ip_india.proprietor &&
                            refinedTrademark.ip_india.proprietor.length > 0 && (
                              <div>
                                <ul>
                                  {refinedTrademark.ip_india.proprietor.map(
                                    (propr) => (
                                      <li>
                                        <Text
                                          className="application-text"
                                          onClick={() =>
                                            this.handleProprietorProfile(propr)
                                          }
                                        >
                                          {propr?.name}
                                        </Text>
                                        <br />
                                        <Text>{propr.address}</Text>
                                      </li>
                                    ),
                                  )}
                                </ul>
                              </div>
                            )}
                          {refinedTrademark && !refinedTrademark.ip_india && (
                            <div>
                              <p></p>
                            </div>
                          )}
                        </td>
                        <td>
                          {refinedTrademark &&
                            refinedTrademark.pseudo &&
                            refinedTrademark.pseudo.proprietor && (
                              <div>
                                <label className="custom-data-heading">
                                  CUSTOM
                                </label>
                              </div>
                            )}
                          {refinedTrademark && !refinedTrademark.pseudo && (
                            <div>
                              <p></p>
                            </div>
                          )}
                        </td>
                        <td>
                          {refinedTrademark &&
                            refinedTrademark.pseudo &&
                            refinedTrademark.pseudo.proprietor &&
                            refinedTrademark.pseudo.proprietor.length > 0 && (
                              <div>
                                <ul>
                                  {refinedTrademark.pseudo.proprietor.map(
                                    (propr) => (
                                      <li>
                                        <Text>{propr?.name}</Text>
                                        <br />
                                        <Text>{propr.address}</Text>
                                      </li>
                                    ),
                                  )}
                                </ul>
                              </div>
                            )}
                          {refinedTrademark && !refinedTrademark.pseudo && (
                            <div>
                              <p></p>
                            </div>
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <div>
                            <p>ATTORNEY NAME:</p>
                          </div>
                        </td>
                        <td>
                          {refinedTrademark &&
                            refinedTrademark.ip_india &&
                            refinedTrademark.pseudo &&
                            refinedTrademark.ip_india.attorney && (
                              <div>
                                <label className="ip-india-heading">
                                  IP INDIA
                                </label>
                              </div>
                            )}
                          {refinedTrademark &&
                            refinedTrademark.ip_india &&
                            !refinedTrademark.pseudo && (
                              <div>
                                <p></p>
                              </div>
                            )}
                        </td>
                        <td>
                          {refinedTrademark && refinedTrademark.ip_india && (
                            <div>
                              <p>
                                {checkValue(refinedTrademark.ip_india.attorney)
                                  ? refinedTrademark.ip_india.attorney?.name
                                  : "N.A."}
                              </p>
                            </div>
                          )}
                          {refinedTrademark && !refinedTrademark.ip_india && (
                            <div>
                              <p></p>
                            </div>
                          )}
                        </td>
                        <td>
                          {refinedTrademark &&
                            refinedTrademark.pseudo &&
                            refinedTrademark.pseudo.attorney && (
                              <div>
                                <label className="custom-data-heading">
                                  CUSTOM
                                </label>
                              </div>
                            )}
                          {refinedTrademark && !refinedTrademark.pseudo && (
                            <div>
                              <p></p>
                            </div>
                          )}
                        </td>
                        <td>
                          {refinedTrademark &&
                            refinedTrademark.pseudo &&
                            refinedTrademark.pseudo.attorney && (
                              <div>
                                <p>
                                  {checkValue(refinedTrademark.pseudo.attorney)
                                    ? refinedTrademark.pseudo.attorney?.name
                                    : "N.A."}
                                </p>
                              </div>
                            )}
                          {refinedTrademark && !refinedTrademark.pseudo && (
                            <div>
                              <p></p>
                            </div>
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <div>
                            <p>DESCRIPTION:</p>
                          </div>
                        </td>
                        <td>
                          {refinedTrademark &&
                            refinedTrademark.ip_india &&
                            refinedTrademark.pseudo &&
                            refinedTrademark.ip_india.description && (
                              <div>
                                <label className="ip-india-heading">
                                  IP INDIA
                                </label>
                              </div>
                            )}
                          {refinedTrademark &&
                            refinedTrademark.ip_india &&
                            !refinedTrademark.pseudo && (
                              <div>
                                <p></p>
                              </div>
                            )}
                        </td>
                        <td>
                          {refinedTrademark && refinedTrademark.ip_india && (
                            <div>
                              <p>
                                {checkValue(
                                  refinedTrademark.ip_india.description,
                                ) ? (
                                  <span
                                    className="application-text"
                                    onClick={() =>
                                      this.showDescription("ipindia")
                                    }
                                  >
                                    Click Here to Show Description
                                  </span>
                                ) : (
                                  "N.A."
                                )}
                              </p>
                            </div>
                          )}
                          {refinedTrademark && !refinedTrademark.ip_india && (
                            <div>
                              <p></p>
                            </div>
                          )}
                        </td>
                        <td>
                          {refinedTrademark &&
                            refinedTrademark.pseudo &&
                            refinedTrademark.pseudo.description && (
                              <div>
                                <label className="custom-data-heading">
                                  CUSTOM
                                </label>
                              </div>
                            )}
                          {refinedTrademark && !refinedTrademark.pseudo && (
                            <div>
                              <p></p>
                            </div>
                          )}
                        </td>
                        <td>
                          {refinedTrademark &&
                            refinedTrademark.pseudo &&
                            refinedTrademark.pseudo.description && (
                              <div>
                                <p>
                                  {checkValue(
                                    refinedTrademark.pseudo.description,
                                  ) ? (
                                    <span
                                      className="application-text"
                                      onClick={() =>
                                        this.showDescription("pseudo")
                                      }
                                    >
                                      Click Here to Show Description
                                    </span>
                                  ) : (
                                    "N.A."
                                  )}
                                </p>
                              </div>
                            )}
                          {refinedTrademark && !refinedTrademark.pseudo && (
                            <div>
                              <p></p>
                            </div>
                          )}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </Col>
            </Card>
          </Col>
        </Row>
        {/* TRADEMARK INFORMATION */}
        {/* EXPAND ALL AND COLLPASE ALL BUTTON */}
        <Button
          type="text"
          className="btn btn shadow-none position-relative mt-5"
          id="expand"
          style={{
            left: "30px",
            fontSize: "12px",
            backgroundColor: "#1890FF",
            color: "white",
            width: "100px",
          }}
          onClick={this.buttonClickText}
        >
          {this.state.buttontext}
        </Button>
        {/* Timeline */}
        <Collapse
          activeKey={this.state.activePanels}
          onChange={this.handlePanel}
          style={{ marginTop: "70px" }}
        >
          <Panel
            header="TIMELINE"
            key="1"
            style={{ fontSize: "15px", background: "#EBF4FF" }}
          >
            {refinedTrademark && refinedTrademark.ip_india && (
              <Card headStyle={{ background: "white" }}>
                <Timeline
                  documents={
                    refinedTrademark.ip_india.documents
                      ? refinedTrademark.ip_india.documents
                      : []
                  }
                  status={refinedTrademarkStatus}
                  description="description"
                />
              </Card>
            )}
          </Panel>

          {/* Timeline */}

          {/*  DOCUMENTS AND CORRESPONDENCE NOTICE */}

          <Panel
            header="DOCUMENTS AND CORRESPONDENCE NOTICE"
            key="2"
            className="mt-5"
            style={{ fontSize: "15px", background: "#EBF4FF" }}
          >
            <Row gutter={16} className="m-top-20">
              <Col span={12}>
                <Card
                  //  title = "DOCUMENT"
                  headStyle={{ background: "#e8e8e8" }}
                  loading={refinedTrademarkStatus === LOADING ? true : false}
                >
                  <Table
                    rowKey={(record) => record.id}
                    bordered
                    dataSource={
                      refinedTrademark && refinedTrademark.ip_india.documents
                    }
                    columns={totalDocumentColumns}
                    pagination={false}
                    scroll={{ y: "calc(70vh - 200px)" }}
                  />
                </Card>
              </Col>
              <Col span={12}>
                <Card
                  // title = "CORRESPONDENCE NOTICES"
                  headStyle={{ background: "#e8e8e8" }}
                  loading={refinedTrademarkStatus === LOADING ? true : false}
                >
                  <div>
                    <Table
                      rowKey={(record) => record.id}
                      bordered
                      dataSource={
                        refinedTrademark &&
                        refinedTrademark.ip_india.correspondence_notices
                      }
                      columns={correspondenceNoticeColumns}
                      pagination={false}
                      scroll={{ y: "calc(70vh - 200px)" }}
                    />
                  </div>
                </Card>
              </Col>
            </Row>
          </Panel>

          {/* CORRESPONDENCE NOTICES */}

          {/* OPPOSITION/RECTIFICATION DETAILS */}
          <Panel
            className="mt-5"
            header="OPPOSITION/RECTIFICATION DETAILS"
            key="3"
            style={{ background: "#EBF4FF", fontSize: "15px" }}
          >
            <Row gutter={16} className="m-top-20">
              <Col span={24}>
                <Card
                  // title = "OPPOSITION/RECTIFICATION DETAILS"
                  headStyle={{ background: "#e8e8e8" }}
                  loading={refinedTrademarkStatus === LOADING ? true : false}
                >
                  {refinedTrademark &&
                    refinedTrademark.ip_india.opposition_documents.length > 0 &&
                    refinedTrademark.ip_india.opposition_documents.map(
                      (doc) => (
                        <Card
                          style={{ marginTop: 10 }}
                          type="inner"
                          title={
                            checkValue(doc.detail) ? (
                              <div style={{ background: "#EBF4FF" }}>
                                <Text
                                  style={{ fontSize: "16px", margin: "0px" }}
                                >
                                  {doc.detail.number}
                                </Text>
                              </div>
                            ) : (
                              ""
                            )
                          }
                        >
                          {checkValue(doc.detail) ? (
                            <div>
                              <div>
                                <table className="patent-information-table">
                                  <tbody>
                                    <tr>
                                      <td style={{ border: "0" }}>
                                        <Text strong>Opponent Name:</Text>
                                      </td>
                                      <td style={{ border: "0" }}>
                                        <Text>
                                          {checkValue(doc.detail.opponent_name)
                                            ? doc.detail.opponent_name
                                            : "N.A."}
                                        </Text>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td style={{ border: "0" }}>
                                        <Text strong>Opponent Address:</Text>
                                      </td>
                                      <td style={{ border: "0" }}>
                                        <Text>
                                          {checkValue(
                                            doc.detail.opponent_address,
                                          )
                                            ? doc.detail.opponent_address
                                            : "N.A."}
                                        </Text>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td style={{ border: "0" }}>
                                        <Text strong>Opposition Date:</Text>
                                      </td>
                                      <td style={{ border: "0" }}>
                                        <Text>
                                          {checkValue(doc.detail.date)
                                            ? doc.detail.date
                                            : "N.A."}
                                        </Text>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td style={{ border: "0" }}>
                                        <Text strong>Decision:</Text>
                                      </td>
                                      <td style={{ border: "0" }}>
                                        <Text>
                                          {checkValue(doc.detail.decision)
                                            ? doc.detail.decision
                                            : "N.A."}
                                        </Text>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td style={{ border: "0" }}>
                                        <Text strong>Status:</Text>
                                      </td>
                                      <td style={{ border: "0" }}>
                                        <Text>
                                          {checkValue(doc.detail.status)
                                            ? doc.detail.status
                                            : "N.A."}
                                        </Text>
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </div>

                              <div className="m-top-20">
                                <Table
                                  rowKey={(record) => record.id}
                                  bordered
                                  dataSource={doc.detail.documents}
                                  columns={oppositionDocumentColumns}
                                  pagination={false}
                                  scroll={{ y: "calc(70vh - 200px)" }}
                                />
                              </div>

                              <div className="m-top-20">
                                <Table
                                  rowKey={(record) => record.id}
                                  bordered
                                  dataSource={doc.detail.correspondence_notices}
                                  columns={correspondenceNoticeDocumentColumns}
                                  pagination={false}
                                  scroll={{ y: "calc(70vh - 200px)" }}
                                />
                              </div>

                              {doc.detail.documents.length === 0 &&
                                doc.detail.correspondence_notices.length ===
                                  0 && (
                                  <div className="alignC">
                                    <Text style={{ fontSize: "18px" }}>
                                      No Opposition/Rectification Details Found
                                    </Text>
                                  </div>
                                )}
                            </div>
                          ) : (
                            <div className="alignC">
                              <Text style={{ fontSize: "18px" }}>
                                No Opposition/Rectification Details Found
                              </Text>
                            </div>
                          )}
                        </Card>
                      ),
                    )}
                  {refinedTrademark &&
                    refinedTrademark.ip_india.opposition_documents.length ===
                      0 && (
                      <div className="alignC">
                        <Text style={{ fontSize: "18px" }}>
                          No Opposition/Rectification Details Found
                        </Text>
                      </div>
                    )}
                </Card>
              </Col>
            </Row>
          </Panel>
          {/* OPPOSITION/RECTIFICATION DETAILS */}

          {/* MISCELLANEOUS DOCUMENTS */}
          <Panel
            className="mt-5"
            header="MISCELLANEOUS DOCUMENTS"
            key="4"
            style={{ background: "#EBF4FF", fontSize: "15px" }}
          >
            <Row gutter={16} className="m-top-20">
              <Col span={24}>
                <Card
                  // title = "MISCELLANEOUS DOCUMENTS"
                  headStyle={{ background: "#e8e8e8" }}
                  loading={refinedTrademarkStatus === LOADING ? true : false}
                >
                  {refinedTrademark && (
                    <Col span={24}>
                      {checkValue(
                        refinedTrademark.ip_india.examination_report,
                      ) ? (
                        <div>
                          <table className="patent-information-table">
                            <tbody>
                              <tr>
                                <td>EXAMINATION REPORT</td>
                                <td className="alignR">
                                  <DownloadOutlined
                                    style={{
                                      color: "#4285f4",
                                      fontSize: "20px",
                                    }}
                                    onClick={() =>
                                      this.openExaReport(
                                        refinedTrademark.ip_india
                                          .examination_report,
                                      )
                                    }
                                  />
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      ) : (
                        <div className="alignC">
                          <Text style={{ fontSize: "18px" }}>
                            No Miscellaneous Document Found
                          </Text>
                        </div>
                      )}
                    </Col>
                  )}
                </Card>
              </Col>
            </Row>
          </Panel>
          {/* MISCELLANEOUS DOCUMENTS */}

          {/* UPLOADED DOCUMENTS TABLE */}
          <Panel
            className="mt-5"
            header="UPLOADED DOCUMENTS TABLE"
            key="5"
            style={{ background: "#EBF4FF", fontSize: "15px" }}
          >
            {refinedTrademark && (
              <UploadDocumentsTable
                data={refinedTrademark.ip_india}
                status={refinedTrademarkStatus}
                postDownloadFile={(title) =>
                  this.props.postDownloadFile({
                    applicationNumber:
                      refinedTrademark.ip_india.application_number,
                    documentFor: TM,
                    fileName: title,
                  })
                }
                changeVisible={() => this.setState({ visible: true })}
              />
            )}
          </Panel>
          {/* UPLOADED DOCUMENTS TABLE */}

          {/* NOTES SECTION TABLE */}
          <Panel
            className="mt-5"
            header="NOTES"
            key="6"
            style={{ background: "#EBF4FF", fontSize: "15px" }}
          >
            {manageNotes && (
              <NotesTable
                data={manageNotes}
                manageNotesStatus={manageNotesStatus}
                deleteAllManageNotesStatus={deleteAllManageNotesStatus}
                deleteManageNotesStatus={deleteManageNotesStatus}
                handleDeleteNotesAll={() => this.handleDeleteNotesAll()}
                handleAddNotes={() => this.handleAddNotes()}
                handleEditNotes={(record) => this.handleEditNotes(record)}
                handleDeleteNotes={(record) => this.handleDeleteNotes(record)}
                notesDeletingKey={this.state.notesDeletingKey}
              />
            )}
          </Panel>

          {/* UPCOMING RENEWAL */}
          <Panel
            className="mt-5"
            header="UPCOMING RENEWAL"
            key="7"
            style={{ background: "#EBF4FF", fontSize: "15px" }}
          >
            <RenewalTable
              // renewalType={`UPCOMING RENEWAL`}
              customProfile={customProfile}
              renewalData={pendingRenewal}
              changeSwitch={this.state.changeSwitch}
              handleChangeSwitch={() => this.handleChangeSwitch()}
              renewalFiled={(data) => this.renewalFiled(data)}
              changeStateValue_L0={() =>
                this.changeStateValue_L0("addTaskVisible", true)
              }
              handleEmail={(data) =>
                this.handleEmail(
                  "modalsVisibility",
                  "sendEmail",
                  true,
                  data,
                  "tm_applied",
                )
              }
            />
          </Panel>
          {/* UPCOMING RENEWAL */}

          {/* REPLY TO CORRESPONDENCE */}
          <Panel
            className="mt-5"
            header="REPLY TO CORRESPONDENCE"
            key="8"
            style={{ background: "#EBF4FF", fontSize: "15px" }}
          >
            <CorrespondenceTable
              correspondenceType={`REPLY TO CORRESPONDENCE`}
              customProfile={customProfile}
              correspondenceData={pendingReply}
              changeSwitch={this.state.changeSwitch}
              handleChangeSwitch={() => this.handleChangeSwitch()}
              replyFiled={(data) => this.replyFiled(data)}
              changeStateValue_L0={() =>
                this.changeStateValue_L0("addTaskVisible", true)
              }
              handleEmail={(data) =>
                this.handleEmail(
                  "modalsVisibility",
                  "sendEmail",
                  true,
                  data,
                  "tm_applied",
                )
              }
            />
          </Panel>
          {/* REPLY TO CORRESPONDENCE */}

          {/* OPPOSED REPLY TO CORRESPONDENCE */}
          <Panel
            className="mt-5"
            header="OPPOSED REPLY TO CORRESPONDENCE"
            key="9"
            style={{ background: "#EBF4FF", fontSize: "15px" }}
          >
            <CorrespondenceTable
              correspondenceType={`OPPOSED REPLY TO CORRESPONDENCE`}
              customProfile={customProfile}
              correspondenceData={opposedPendingReply}
              changeSwitch={this.state.changeSwitch}
              handleChangeSwitch={() => this.handleChangeSwitch()}
              replyFiled={(data) => this.replyFiled(data)}
              changeStateValue_L0={() =>
                this.changeStateValue_L0("addTaskVisible", true)
              }
              handleEmail={(data) =>
                this.handleEmail(
                  "modalsVisibility",
                  "sendEmail",
                  true,
                  data,
                  "tm_applied",
                )
              }
            />
          </Panel>
          {/* REPLY TO CORRESPONDENCE */}

          {/* TLA HEARING */}
          <Panel
            className="mt-5"
            header="TLA HEARING"
            key="10"
            style={{ background: "#EBF4FF", fontSize: "15px" }}
          >
            {tlaHearing && (
              <HearingTable
                hearingType={`TLA HEARING`}
                hearingData={tlaHearing}
                ipIndiaHearingData={ip_india_tla_hearing}
                changeStateValue_L0={() =>
                  this.changeStateValue_L0("addTaskVisible", true)
                }
                handleEmail={(data) =>
                  this.handleEmail(
                    "modalsVisibility",
                    "sendEmail",
                    true,
                    data,
                    "tla_hearing",
                  )
                }
              />
            )}
          </Panel>
          {/* TLA HEARING */}

          {/* OPPOSITION HEARING */}
          <Panel
            className="mt-5"
            header="OPPOSITION HEARING"
            key="11"
            style={{ background: "#EBF4FF", fontSize: "15px" }}
          >
            {oppositionHearing && (
              <HearingTable
                // hearingType={`OPPOSITION HEARING`}
                hearingData={oppositionHearing}
                changeStateValue_L0={() =>
                  this.changeStateValue_L0("addTaskVisible", true)
                }
                handleEmail={(data) =>
                  this.handleEmail(
                    "modalsVisibility",
                    "sendEmail",
                    true,
                    data,
                    "oppostion_hearing",
                  )
                }
              />
            )}
          </Panel>
          {/* TLA HEARING */}

          {/* <p>Email Sent Dashboard</p> */}
          <Panel
            className="mt-5"
            header="EMAIL LOGS"
            key="12"
            style={{ background: "#EBF4FF", fontSize: "15px" }}
          >
            <SentEmail />
          </Panel>

          <Panel
            className="mt-5"
            header="TODO TASK"
            key="13"
            style={{ background: "#EBF4FF", fontSize: "15px" }}
          >
            {/* TODO TASK */}
            {todoTask && todoTask.length > 0 && (
              <TodoTaskTable
                TodoTaskType={`TODO TASK`}
                TodoTaskData={todoTask}
              />
            )}
          </Panel>
        </Collapse>
        {/* TODO TASK */}
        <ChangeLogTrademark
          visible={this.state.changeLogVisible}
          onCloseModal={() => this.closeChangeLog()}
        />
        <Drawer
          title={
            <Title level={4}>
              Uploaded Documents (
              {refinedTrademark && refinedTrademark.ip_india.application_number}
              )
            </Title>
          }
          placement="right"
          width={400}
          closable={true}
          onClose={() => this.setState({ visible: false })}
          open={this.state.visible}
          bodyStyle={{ paddingBottom: 80 }}
        >
          {refinedTrademark && (
            <UploadDocuments
              applicationNumber={refinedTrademark.ip_india.application_number}
              documentFor={TM}
              teamMembers={
                checkValue(this.props.userDetails)
                  ? checkValue(this.props.teamMembe)
                    ? [...this.props.teamMember, this.props.userDetails]
                    : ""
                  : ""
              }
              defaultEmail={
                checkValue(this.props.userDetails)
                  ? this.props.userDetails.email
                  : ""
              }
              uploadedDocuments={refinedTrademark.ip_india.uploaded_documents}
              putUploadedFile={(Obj) =>
                this.props.putUploadedFile(Obj, UPLOAD_FILE_TM_REFINED)
              }
              postUploadFile={(Obj) =>
                this.props.postUploadFile(Obj, UPLOAD_FILE_TM_REFINED)
              }
              deleteUploadedFile={(Obj) =>
                this.props.deleteUploadedFile(Obj, UPLOAD_FILE_TM_REFINED)
              }
            />
          )}
        </Drawer>
        <ManagerNotes
          visible={this.state.managerNotes}
          onCloseModal={() => this.changeStateValue_L0("managerNotes", false)}
          actionType={[POST_TM_NOTES, PATCH_TM_NOTES]}
          email={
            checkValue(this.props.userDetails)
              ? this.props.userDetails.email
              : ""
          }
          note_for={TM}
          application_number={sessionStorage.getItem(
            "trademark_application_number",
          )}
          note_id={this.state.note_id}
          note={this.state.note}
          type={this.state.type}
        />
        <RenewalFiledModal
          visible={this.state.renewalFiledVisible}
          onCloseModal={() =>
            this.changeStateValue_L0("renewalFiledVisible", false)
          }
          renewal={this.state.renewal}
          application_number={this.state.application_number}
          applied_for={this.state.applied_for}
          pseudo={this.state.pseudo}
          reRender={() => this.reRender()}
        />
        <ReplyFiledModal
          visible={this.state.replyFiledVisible}
          onCloseModal={() =>
            this.changeStateValue_L0("replyFiledVisible", false)
          }
          mm_type={"reply_to_correspondance"}
          pending_reply={this.state.pending_reply}
          application_number={this.state.application_number}
          applied_for={this.state.applied_for}
          pseudo={this.state.pseudo}
          mark_type={"Portfolio trademark"}
          actionType={DASHBOARD_REPLY_FILED_TO_CORRESPONDENCE}
        />
        <CreateTask
          visible={this.state.addTaskVisible}
          onCloseModal={() => this.changeStateValue_L0("addTaskVisible", false)}
          type={"trademark"}
        />
        <SendEmailNew
          visible={this.state.modalsVisibility.sendEmail}
          onCloseModal={() =>
            this.handleEmail("modalsVisibility", "sendEmail", false, {}, "")
          }
          sendFrom={
            this.props.userDetails && this.props.userDetails.communication_email
          }
          sendCC={this.props.userTeam && this.props.userTeam.manager}
          mailContent={this.state.modalsVisibility.mailContent}
          product={this.state.modalsVisibility.product}
          subscription={this.state.modalsVisibility.subscription}
          report_tag={this.state.modalsVisibility.report_tag}
          objectType={this.state.modalsVisibility.objectType}
          applicationNumber={this.state.modalsVisibility.applicationNumber}
          markId={this.state.modalsVisibility.markId}
          doc_link={true}
          sourceId={this.state.modalsVisibility.sourceId}
          source={`TRADEMARK`}
          sub_section="DASHBOARD"
          managerEmail={true}
        />
      </div>
    );
  }
}

const mapDispatchToProps = {
  getRefinedTrademark,
  getTrademarkChangelog,
  putUploadedFile,
  postDownloadFile,
  deleteUploadedFile,
  postUploadFile,
  getTeamMember,
  logOut,
  getNotes,
  deleteNotes,
  getReplyToCorrespondence,
};

function mapStateToProps(state) {
  const {
    refinedTrademark,
    refinedTrademarkStatus,
    trademarkChangelog,
    trademarkChangelogStatus,
  } = state.managerState.trademarkDashboardState;

  const { teamMember, teamMemberStatus } = state.profile;

  const {
    manageNotes,
    manageNotesStatus,
    manageNotesMessage,
    addManageNotesStatus,
    editManageNotesStatus,
    deleteManageNotesStatus,
    deleteAllManageNotesStatus,
  } = state.managerState.notesState;

  const { userSubscriptions, userDetails, userTeam, userSubscriptionStatus } =
    state.userSubscriptions;

  return deepFreeze({
    teamMember,
    teamMemberStatus,
    refinedTrademark,
    refinedTrademarkStatus,
    trademarkChangelog,
    trademarkChangelogStatus,

    userSubscriptions,
    userDetails,
    userTeam,
    userSubscriptionStatus,

    manageNotes,
    manageNotesStatus,
    manageNotesMessage,
    addManageNotesStatus,
    editManageNotesStatus,
    deleteManageNotesStatus,
    deleteAllManageNotesStatus,
  });
}

export default connect(mapStateToProps, mapDispatchToProps)(TrademarkProfile);
