//--------LIBRARIES--------
import React from "react";
import { Badge, Card, Tooltip, Checkbox, Image } from "antd";
import { EyeFilled, StarFilled } from "@ant-design/icons";
import { checkValue } from "../../../../../functions/commonFunctions/checkValueFunctions";
import { useDispatch, useSelector } from "react-redux";
import { dateFormat } from "../../../../../functions/commonFunctions/dateFunctions";
import { round } from "lodash";

function GridContent({
  eachMark,
  mark,
  onSelectMark,
  checked,
  selectAllCards,
  changeStateValue_L1,
  styleCard,
  similarity,
  status,
  proprietorClickable,
  proprietorFlat,
  onProprietorClick,
  dataAsOn,
  classesFlat,
  created_custom_report,
  imageLabel,
  imageText,
  tmProtect,
  algorithm,
  setJournalPageVisible,
  setMailObj,
  publishedDate,
  journal,
}) {
  const dispatch = useDispatch();
  let urlKey = window.location.pathname.split("/")[2] || "";
  let dashboardLayout = window.location.pathname.split("/")[3] || "";

  const textLabel = (label, text) => (
    <div style={{ margin: "4px 0px" }}>
      <div className="Threat-Mark-Card-Title">{label} :</div>
      <div className="Threat-Mark-Card-Detail">
        {/* {checkNA(eachMark.applied_for)} */}
        {text}
      </div>
    </div>
  );

  const threat = eachMark;

  const content = (
    <Card
      style={{ minWidth: 200, padding: "8px", border: "none" }}
      size="small"
      cover={
        <div
          style={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <div
            style={{
              display: "flex",
              margin: "2px",
            }}
          >
            <p style={{ margin: "0px 8px" }}>
              <span>
                {checkValue(threat?.application_number)
                  ? threat?.application_number
                  : "N.A."}{" "}
              </span>
            </p>
          </div>
          {threat?.associated_image ? (
            <div style={{ padding: "4px" }}>
              <Image
                src={threat?.associated_image}
                style={{
                  height: "100px",
                  maxWidth: "200px",
                }}
              />
            </div>
          ) : (
            <div
              style={{
                height: "100px",
                maxWidth: "120px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                background: "#efefef",
              }}
            >
              N.A.
            </div>
          )}
        </div>
      }
    >
      <Card.Meta
        title={
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              height: "200px",
              overflowY: "auto",
              overflowX: "hidden",
            }}
          >
            <div>
              {textLabel("APPLIED FOR", threat?.applied_for)}
              {textLabel(
                "Classes",
                threat?.associated_class.map((eachClass, index, allClasses) =>
                  allClasses.length - 1 === index
                    ? `${eachClass}`
                    : `${eachClass}, `,
                ),
              )}
              {textLabel(
                "DoA | DoU",
                `${threat?.date_of_application} | ${threat?.date_of_usage}`,
              )}
              {textLabel(
                "Proprietors",
                threat?.proprietor.map((eachProprietor) => (
                  <React.Fragment
                    key={`${eachMark.application_number}-${eachProprietor.id}`}
                  >
                    {proprietorClickable ? (
                      <span
                        className="Application-Number"
                        onClick={() => onProprietorClick(eachProprietor.id)}
                      >
                        {eachProprietor.name}
                      </span>
                    ) : (
                      <span>{eachProprietor.name}</span>
                    )}
                    <br />
                  </React.Fragment>
                )),
              )}
              {textLabel(
                "Similarity",
                `${round(threat?.similarity_score * 100, 2)}%`,
              )}
            </div>
          </div>
        }
      />
    </Card>
  );

  if (
    mark?.associated_class?.filter((element) =>
      threat?.associated_class.includes(element),
    ).length > 0
  ) {
    return (
      <div
        className="ThreatCard"
        style={{
          boxShadow: "0px 0px 5px #c9c9c9",
          margin: "8px",
          border: "1px solid red",
          borderRadius: "8px",
        }}
      >
        <Badge.Ribbon color="red" text="Same Class">
          {content}
        </Badge.Ribbon>
      </div>
    );
  }

  return (
    <div
      style={{
        margin: "8px",
        borderRadius: "8px",
        boxShadow: "0px 0px 5px #c9c9c9",
      }}
      className="ThreatCard"
    >
      {content}
    </div>
  );
}

export default GridContent;
