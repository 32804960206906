//--------LIBRARIES--------
import React from "react";
import { EyeOutlined } from "@ant-design/icons";
import { Badge, Card, Tooltip, Checkbox, Image } from "antd";
import { EyeFilled, StarFilled } from "@ant-design/icons";
import { checkValue } from "../../../../../functions/commonFunctions/checkValueFunctions";
import { ROOT_URL } from "../../../../../configs/rootConfigs";
import { useDispatch, useSelector } from "react-redux";
import { getEmailTemplateData } from "../../../../../redux/actions/commonActions/actions";
import { dateFormat } from "../../../../../functions/commonFunctions/dateFunctions";
import useSelection from "antd/es/table/hooks/useSelection";
//--------Check value functions--------
// import { checkNA } from "../../../functions/commonFunctions/checkValueFunctions";

function ThreatCard({
  eachMark,
  mark,
  onSelectMark,
  checked,
  selectAllCards,
  changeStateValue_L1,
  styleCard,
  similarity,
  status,
  proprietorClickable,
  proprietorFlat,
  onProprietorClick,
  dataAsOn,
  classesFlat,
  created_custom_report,
  imageLabel,
  imageText,
  tmProtect,
  algorithm,
  setJournalPageVisible,
  setMailObj,
  publishedDate,
  journal,
}) {
  const dispatch = useDispatch();
  let urlKey = window.location.pathname.split("/")[2] || "";
  let dashboardLayout = window.location.pathname.split("/")[3] || "";

  const { currentJournalDate } =
    useSelector(
      (state) =>
        state.watchState.watchFilters[dashboardLayout][urlKey].config
          ?.journalConfig,
    ) || [];

  const handleEmail = async () => {
    let data = eachMark;
    let journalNumber = journal;
    let mailContent = "<p><strong>Trademark Watch</strong></p>";

    let applicationNumber = checkValue(data.threat)
      ? data.threat?.application_number
      : "";

    let mark_type = checkValue(mark) ? mark?.term : "";

    let journalDate = currentJournalDate;

    let FilingDate = new Date(journalDate);
    let d = FilingDate.getDate();
    FilingDate.setMonth(FilingDate.getMonth() + 4);
    if (FilingDate.getDate() !== d) {
      FilingDate.setDate(0);
    }

    let tempDate = new Date();
    let currDate =
      tempDate.getFullYear() +
      "-" +
      (tempDate.getMonth() + 1) +
      "-" +
      tempDate.getDate() +
      " " +
      tempDate.getHours() +
      ":" +
      tempDate.getMinutes() +
      ":" +
      tempDate.getSeconds();
    let currSubscription = "DASHBOARD";

    if (urlKey === "image_label") {
      currSubscription = "IMAGE_RECOGNITION";
    } else if (urlKey === "tm_applied") {
      currSubscription = "JOURNAL WATCH";
    } else if (urlKey === "image_text" || urlKey === "image_text_new") {
      currSubscription = "IMAGE_RECOGNITION";
    } else if (urlKey === "tm_protect") {
      currSubscription = "TM PROTECT";
    }
    setMailObj({
      visible: true,
      mailContent: mailContent,
      product: "WATCH",
      subscription: currSubscription,
      report_tag: {
        report_name: "",
        journal_number: journalNumber,
        journal_date: dateFormat(journalDate, "-"),
        journal_copy: `${ROOT_URL}/watch/api/journal_threat_pdf/?journal_number=${journalNumber}&application_number=${applicationNumber}`,
        opposition: dateFormat(FilingDate, "-"),
        your_mark: checkValue(mark) ? mark?.applied_for : "",
        your_application: checkValue(mark) ? mark?.application_number : "",
        your_proprietor: checkValue(mark.proprietor)
          ? mark?.proprietor?.map((propData) => propData.name).join(", ")
          : "",
        your_DOA: checkValue(mark) ? mark?.date_of_application : "",
        your_DOU: checkValue(mark) ? mark?.date_of_usage : "",
        your_class: checkValue(mark) ? mark?.associated_class?.join(", ") : "",
        your_mark_image: checkValue(mark) ? mark?.associated_image : "",
        threat_mark: checkValue(data.threat) ? data?.threat?.applied_for : "",
        threat_application: checkValue(data.threat)
          ? data.threat?.application_number
          : "",
        threat_proprietor: checkValue(data.threat)
          ? data.threat?.proprietor?.map((propData) => propData.name).join(", ")
          : "",
        threat_address: checkValue(data.threat)
          ? data.threat?.proprietor
              ?.map((propData) => propData.address)
              .join(", ")
          : "",
        threat_DOA: checkValue(data.threat)
          ? data?.threat.date_of_application
          : "",
        threat_DOU: checkValue(data.threat) ? data?.threat.date_of_usage : "",
        threat_class: checkValue(data.threat)
          ? data?.threat?.associated_class.join(", ")
          : "",
        threat_mark_image: checkValue(data.threat)
          ? data?.threat?.associated_image
          : "",
        current_date: checkValue(currDate) ? dateFormat(currDate, "-") : "",
      },
      journal_copy: `${ROOT_URL}/watch/api/journal_threat_pdf/?journal_number=${journalNumber}&application_number=${applicationNumber}`,
      objectType: "trademark",
      // applicationNumber: checkValue(mark) ? mark.application_number : "",
      applicationNumber:
        urlKey === "tm_protect"
          ? checkValue(data.mark)
            ? data.mark?.term
            : ""
          : checkValue(data.mark)
            ? data.mark?.application_number
            : "",
      mark_type,
    });
    if (urlKey === "image_label") {
      await dispatch(
        getEmailTemplateData({
          subscription: "IMAGE_RECOGNITION",
          product: "WATCH",
          object_id: data?.id,
          sub_section: "DASHBOARD",
          image_recognition_type: "IMAGE_LABEL_RECOGNITION_TYPE",
        }),
      );
    } else if (urlKey === "tm_applied") {
      await dispatch(
        getEmailTemplateData({
          subscription: "Journal Watch",
          product: "WATCH",
          object_id: data?.id,
          sub_section: "DASHBOARD",
        }),
      );
    } else if (urlKey === "image_text" || urlKey === "image_text_new") {
      await dispatch(
        getEmailTemplateData({
          subscription: "IMAGE_RECOGNITION",
          product: "WATCH",
          object_id: data?.id,
          sub_section: "DASHBOARD",
          image_recognition_type: "IMAGE_TEXT_RECOGNITION_TYPE",
        }),
      );
    } else if (urlKey === "tm_protect") {
      await dispatch(
        getEmailTemplateData({
          subscription: "TM Protect",
          product: "WATCH",
          object_id: data?.id,
          sub_section: "DASHBOARD",
        }),
      );
    }
  };

  const textLabel = (label, text) => (
    <div style={{ margin: "4px 0px" }}>
      <div className="Threat-Mark-Card-Title">{label} :</div>
      <div className="Threat-Mark-Card-Detail">
        {/* {checkNA(eachMark.applied_for)} */}
        {text}
      </div>
    </div>
  );

  const threat = eachMark?.threat;

  const content = (
    <Card
      style={{ minWidth: 200, padding: "8px", border: "none" }}
      size="small"
      cover={
        <div
          style={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <div
            style={{
              display: "flex",
              margin: "2px",
            }}
          >
            <Checkbox
              checked={checked}
              onChange={(event) => onSelectMark(eachMark, event.target.checked)}
              disabled={selectAllCards}
              className="checkbox-content-list"
            />
            <p
              className={urlKey !== "tm_protect" ? "application-text" : ""}
              style={{ margin: "0px 8px" }}
              onClick={() =>
                urlKey !== "tm_protect" &&
                setJournalPageVisible(threat?.application_number)
              }
            >
              <span>
                {checkValue(threat?.application_number)
                  ? threat?.application_number
                  : "N.A."}{" "}
              </span>
            </p>
            {eachMark?.created_custom_report && (
              <Tooltip title="Custom Report Created">
                <EyeFilled
                  style={{
                    color: "#1890FF",
                    fontSize: "18px",
                    margin: "0px 4px",
                  }}
                />
              </Tooltip>
            )}
            {eachMark?.is_shortlist && (
              <Tooltip title="Shortlisted">
                <StarFilled
                  style={{
                    margin: "0px 4px",
                    color: "#FFA701",
                    fontSize: "18px",
                  }}
                />
              </Tooltip>
            )}
          </div>
          {threat?.associated_image ? (
            <div style={{ padding: "4px" }}>
              <Image
                src={threat?.associated_image}
                style={{
                  height: "100px",
                  maxWidth: "200px",
                }}
              />
            </div>
          ) : (
            <div
              style={{
                height: "100px",
                maxWidth: "120px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                background: "#efefef",
              }}
            >
              N.A.
            </div>
          )}
        </div>
      }
    >
      <Card.Meta
        title={
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              height: "260px",
              overflowY: "auto",
              overflowX: "hidden",
            }}
          >
            <div>
              {textLabel("APPLIED FOR", threat?.applied_for)}
              {textLabel(
                "Classes",
                threat?.associated_class.map((eachClass, index, allClasses) =>
                  allClasses.length - 1 === index
                    ? `${eachClass}`
                    : `${eachClass}, `,
                ),
              )}
              {textLabel(
                "DoA | DoU",
                `${threat?.date_of_application} | ${threat?.date_of_usage}`,
              )}
              {textLabel(
                "Proprietors",
                threat?.proprietor.map((eachProprietor) => (
                  <React.Fragment
                    key={`${eachMark.application_number}-${eachProprietor.id}`}
                  >
                    {proprietorClickable ? (
                      <span
                        className="Application-Number"
                        onClick={() => onProprietorClick(eachProprietor.id)}
                      >
                        {eachProprietor.name}
                      </span>
                    ) : (
                      <span>{eachProprietor.name}</span>
                    )}
                    <br />
                  </React.Fragment>
                )),
              )}
              {urlKey === "image_label" && (
                <Tooltip
                  title={`Focus Label: ${eachMark?.similarity?.focus_word}`}
                >
                  {textLabel(
                    "Similarity | Threat Labels",
                    `${
                      eachMark?.similarity?.score
                    }% | ${eachMark?.similarity?.image_labels.join(", ")}`,
                  )}
                </Tooltip>
              )}
              {(urlKey === "image_text" || urlKey === "image_text_new") && (
                <Tooltip
                  title={
                    (eachMark?.similarity?.comparison ===
                      "IMAGE_FW_TO_APPLIED_FOR_FW" ||
                      eachMark?.similarity?.comparison ===
                        "IMAGE_FW_TO_IMAGE_FW") &&
                    eachMark?.similarity?.is_custom
                      ? `Custom Focus Text: ${eachMark?.similarity?.focus_word}`
                      : `Focus Text: ${eachMark?.similarity?.focus_word}`
                  }
                >
                  {textLabel(
                    "Similarity | Image Text",
                    `${eachMark?.similarity?.score}% | ${eachMark?.similarity?.image_text}`,
                  )}
                </Tooltip>
              )}
              {(urlKey === "tm_applied" || urlKey === "tm_protect") &&
                textLabel(
                  "Similarity | Focus Word",
                  `${eachMark?.similarity?.score}% | ${eachMark?.similarity?.focus_word}`,
                )}
            </div>
          </div>
        }
        description={
          <div>
            <hr />
            <p onClick={() => handleEmail()}>
              <Tooltip title={"Send email"}>
                <i
                  id="threat-card-sendmail"
                  className="fa fa-paper-plane"
                  style={{
                    padding: "0px 5px",
                    color: "#1890ff",
                    fontSize: 20,
                    cursor: "pointer",
                  }}
                  aria-hidden="true"
                ></i>
              </Tooltip>
            </p>
          </div>
        }
      />
    </Card>
  );

  if (
    mark?.associated_class?.filter((element) =>
      threat?.associated_class.includes(element),
    ).length > 0
  ) {
    return (
      <div
        className="ThreatCard"
        style={{
          boxShadow: "0px 0px 5px #c9c9c9",
          margin: "8px",
          border: "1px solid red",
          borderRadius: "8px",
        }}
      >
        <Badge.Ribbon color="red" text="Same Class">
          {content}
        </Badge.Ribbon>
      </div>
    );
  }

  return (
    <div
      style={{
        margin: "8px",
        borderRadius: "8px",
        boxShadow: "0px 0px 5px #c9c9c9",
      }}
      className="ThreatCard"
    >
      {content}
    </div>
  );
}

export default ThreatCard;
